.action-dropdown-content {
    position: absolute;
    background-color: white;
    width: 5em;
    z-index: 3;
    border-radius: 0.5em;
    border: 1px solid lightgray;
    margin-top: -30px;
    margin-left:-65px;
  }
  .action-dropdown-content .action-option{
    color: #0055A4;
    cursor: pointer;
    padding: 0.5em;
    font-family: Barlows,'Noto Sans';
  }

  .no-data-container {
    padding-top: 5%;
}
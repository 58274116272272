.other-tire-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 150%;
    position: absolute;
    width: 100%;
}

.other-tire-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url("../../../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* TIRES IN SERVICE HEADER (BREADCRUMBS & TITLE) */

.other-tire-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.other-tire-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */

.other-tire-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: auto;
}

.other-tire-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.other-tire-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-other-tire-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.other-tire-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-other-tire-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .other-tire-main-container{
        height: 110%;
    }
    .other-tire-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: auto;
    }
    .other-tire-nav-card-text{
        font-size:1.2em;
    }
    .active-other-tire-nav-card-text{
        font-size:1.2em;
    }
    .other-tire-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .other-tire-main-container{
        height: 150%;
    }
    .other-tire-nav-container{
        overflow-x: scroll;
    }
    .other-tire-nav-card-container{
        margin-right: 1em;
    }
   
    .other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
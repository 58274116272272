.delete-multiple-selected{
    width: 45% !important;
}
.center-modal-account{
    outline: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.delete-account-title-container{
    display: flex;
    margin-left: 1.8em;
}

.delete-account-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em; 
}


.delete-modal-description-account{
    color:#080808;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1em 2.5em 3em 2.5em; 
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
    .delete-account-modal-container{
        width: 85%;
    }
}


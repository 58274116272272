.center-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dropdown-content {
  width: 75% !important;
}
.header-change-modal-container {
  border-radius: 0.5em;
  width: 50%;
  background: white;
}
.header-change-modal-title-container {
  display: flex;
  margin-left: 2em;
}
.header-change-modal-title {
  font-size: 1.1em;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 0.75em;
}
.header-change-modal-title-single {
  border-radius: 0.5em;
  width: 30%;
  background: white;
}

.header-change-modal-title-double {
  border-radius: 0.5em;
  width: 50%;
  background: white;
}
.pin-icon {
  margin-top: 0.75em;
  margin-right: 0.5em;
}
.close-icon-container {
  text-align: end;
}
.modal-close-icon {
  height: 0.75em;
  width: 0.75em;
  margin-top: 0.5em;
  margin-right: 0.75em;
  cursor: pointer;
}
#header-change-modal-multi-select .dropdown-content {
  top: -100px !important;
}
.header-change-modal-description {
  color: #545151;
  font-family: Barlows,'Noto Sans';
  font-size: 0.75em;
  margin-top: 1em;
  margin-left: 2.5em;
}

/* SELECT BOXES STYLING  */
.change-modal-select-container {
  margin: 2em;
}
.select-input {
  width: 100% !important;
  padding: 1em;
}
.change-modal-select-label {
  margin-bottom: 1em !important;
  font-size: 0.8em !important;
  color: #222222 !important;
}
.select-style {
  border: 1px solid lightgray !important;
  border-radius: 0.5em;
  padding: 0.2em;
  margin-top: 1.5em !important;
}

.custom-options-styling:hover {
  background: #77acff !important;
}

/* CUSTOM STYLE FOR REACT-DROPDOWN */

.Dropdown-option:hover {
  background: #77acff !important;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

#header-change-modal-multi-select .multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 3.5em !important; /* Height */
}

/* BUTTON GROUP IN THE END */

.modal-button-group {
  display: flex;
  justify-content: flex-end;
  margin: 1em;
}
.header-change-modal-cancel-btn {
  padding: 0.75em;
  cursor: pointer;
  color: #0055a4;
  background: white;
  margin-right: 0.5em;
  border: 0;
  opacity: 0.8;
  font: Barlows,'Noto Sans';
  font-weight: 600;
  margin-top: 8px;
}
.header-change-modal-cancel-btn:hover {
  background-color: #eaf3fb;
  border-radius: 18px;
  opacity: 1;
  box-shadow: "none";
  color: #0055a4;
}
.header-change-modal-confirm-btn {
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #0055a4 0%, #0079e6 100%) 0% 0% no-repeat padding-box;
  border: 0;
  opacity: 0.8;
  font: Barlows,'Noto Sans';
  box-shadow: "none";
}
.header-change-modal-confirm-btn:hover {
  opacity: 1;
}
.header-change-modal-confirm-btn-disabled {
  background: #bebfc046;
}

.header-edit-latest-record-change-modal-disabled {
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #bebfc046 0%, #bebfc046 100%) 0% 0% no-repeat padding-box;
  border: 0;
  opacity: 0.8;
  font: Barlows,'Noto Sans';
  box-shadow: "none";
  margin-bottom: 12px;
}

@media only screen and (max-width: 768px) {
  .header-change-modal-container {
    width: 90%;
    background: white;
  }
  .header-change-modal-title {
    font-size: 1em;
  }
  .header-change-modal-title-container {
    margin-left: 0.75em;
  }
  .header-change-modal-description {
    margin-left: 0.75em;
  }
  .change-modal-select-container {
    margin: 1em;
  }
  .MuiPopover-paper {
    margin-top: 3.05em !important;
  }
}
/* IPAD SCREENS */
@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .center-modal {
    top: 35%;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .header-change-modal-title {
    font-size: 1.5em;
  }
  .header-change-modal-description {
    font-size: 1.25em;
  }
  .change-modal-select-label {
    font-size: 1.25em !important;
  }
}

.sub-heading1-UHM {
  font-size: 0.87em;
  color: #333333;
  font-family: Barlows,'Noto Sans';
  font-weight: 400; /* medium */
  margin-bottom: 0.6em;
}

.sub-heading2-UHM {
  font-size: 1em;
  color: #333333;
  font-weight: 200; /* regular */
  font-family: Barlows,'Noto Sans';
  margin-bottom: 0.6em;
}
.full-container {
  height: 100%;
}
.datepicker-popup {
  border: 1px solid #aaa !important;
  border-radius: 4px !important;
  height: inherit;
  padding: 0;
  font-weight: 400;
  margin: 0;
  font-family: Barlows,'Noto Sans' !important;
  font-size: 16px !important;
  color: black !important;
  width: 100%;
  align-self: center;
  opacity: 1;
}

#filter-tabs .MuiAppBar-root{
    width: 90% !important;
    margin: 1em !important;
    border-radius: 0.5em !important;
}
#filter-tabs .MuiTab-root {
    min-width: 6em !important;
    border-left: 1px solid #DEDFDF !important;
    text-transform: capitalize !important;
    padding: 0em !important;
}
#filter-tabs .MuiTabs-root {
    border-radius: 0.5em !important;
}

#filter-tabs .MuiAppBar-colorDefault{
    background-color: #FFFFFF !important;
}
#filter-tabs .MuiTab-textColorPrimary.Mui-selected {
    background-color: #0055A4 !important;
    color: #ffffff !important;
}

.tab-header{
    display: flex;
    padding:1em;
}
.tab-title, .checkbox-title{
    width: 100%;
    font-family: Barlows,'Noto Sans';
    font-size: 1.0em;
    color: #000000;
}

.date-tab-container{
    padding: 0 1em;
}
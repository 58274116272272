.return-inv-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.background-map-image-return-to-inv {
    position: absolute;
    background-image: url("../../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
}

/* .remove-tire-container{
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
} */

/* .remove-tire-inner-container {
    margin: 0px 0px 20px 0px;
    height: 100%;
    width: 100%;
} */




.required-mark {
    color: red;
}

.field-label{
    font-weight: 500;
    text-align: left;
    color: #333333;
    font-family: Barlow;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 10px;
    opacity: 1;
}

.additional1{
    float: left;
}

.additional2{
    float: right;
}

.enter-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18) !important;
    border-radius: 4px !important;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans!important;
    font-size: 14px!important;
    color: black !important;
    height: inherit;
    width: 100%;
    align-self: center;
    opacity: 1;
}

.buttoned-input{
    text-align: center !important;
}


.step-costs-inner-container{
    margin-bottom: 1em !important;
}
.rim-container{
    height:130em;
    background-color: #f2f2f2;
}
.create-rim-main-page-container{
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}
.rim-upper-deck{
    height:100%;
    background-color: #f2f2f2;
}

.create-rim-upper-half {
    display: flex;
    position: absolute;
    height: 18em;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.background-image-rim-deck{
    height:20%;
    background: transparent linear-gradient(161deg, #0D54B5  0%, #003584 100%) 0% 0% no-repeat padding-box;
   }

.background-image-rim-dashboard{
    position: absolute;
  background-image: url("../../assets/map.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}


/* .rim-create-header{
    padding: 2em;
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
}

@media only screen and (min-width: 1500px) {
    
    .rim-create-header{
        margin-left: 55px;
    }
} */

.MuiStepper-root{
    cursor: pointer
}
.MuiStepConnector-alternativeLabel {
    width: max-content !important;
    left: calc(-50% + 190px) !important;
    right: calc(50% + 30px) !important;
}
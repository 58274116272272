.vehicle-container{
    padding-top: 0.8em;
    padding-bottom: 2em
}

.removed-tire-record-detail{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;
}

.parent-account-container{
    margin: 0 0.5em;
}

.account-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.account-header-text-by-tire{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em;    
}
.record-no{
    padding-left: 2px !important;
}
.grid-vehicle-container{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;

}
.grid-data-container{
    padding-left: 2em;
}
.padding-2em{
    padding-top: 2em
}
.padding-1em{
    padding-top: 1em
}
.heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 1em;
    padding-bottom: 0.4em;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding: 0.7em 0;
    font-weight: 500;
}
.font-wt-500{
    font-weight: 500;
}

.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: sticky !important;
    background-color: white;
}
.first-fleet-col {
    left: 0!important;
  }
.second-fleet-col{
    left: 6.5%!important;
}
.third-fleet-col{
   
    left: 16%!important;
}
.fourth-fleet-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 21%!important;
}

.removed-tire-detail-container{
    display: flex;
    margin-bottom: 2em;
    margin-top: 0.8em;
}

.tire-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.tire-header-text{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em; 
    color: #666666; 
}

.removed-tire-detail-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}

.removed-tire-detail-container .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 13px !important;
}

.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-text{
    margin-left: 9px !important;
}

.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.table-content-fleet-text {
    color: #404040 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
    /* position: unset !important; */
    /* white-space: nowrap !important; */
}

/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.admin-list-container {
  /* position: absolute; */
  margin-top: 14em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 2%;
}
.text-area1 {
  width: 30% !important;
  height: 40px;
}
.css-disable {
  background-color: #f5f5f5 !important;
}
.padding-css {
  padding-top: 15px !important;
}
.tab-request-container {
  height: 3em;
  margin: 1em;
  font-size: 1em;
  font-family: Barlows,'Noto Sans';
  border-bottom: 2px solid #dedede;
  width: auto !important;
  margin-left: 0em !important;
  margin-right: 0em !important;
}
.clicked-active-tab-request {
  /* border-bottom: 2px solid #27539b !important; */
  font-weight: 600;
  color: #0059ac !important;
}
.tab-request-label {
  text-align: center;
  border-right: 2px solid #dedede;
  padding-top: 0.75%;
  cursor: pointer;
}
.tire-search-table-container {
  width: 15em;
  margin-left: 10px;
}
.grid-add-delete-container {
  margin: 0.7em 2em !important;
}
.grid-add-delete-input-container {
  margin-top: 2em !important;
}
.mandatory-sign-add-new {
  color: red;
}
.add-new-delete-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  width: 30% !important;
  font-weight: 600 !important;
}
.search-admin {
  width: 100%;
}
.admin-updateAccount-details {
  color: #0059ac !important;
  border: 1px solid #77acff;
  /* opacity: 0.44; */
  /* width: 30%; */
  height: 3em;
  padding: 5px;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 500 !important;
}
.admin-action-dropdown {
  color: #0059ac !important;
  /* background: #C4DCFF2E 0% 0% no-repeat padding-box; */
  border: 1px solid #77acff !important;
  opacity: 0.44;
  width: 100%;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 500 !important;
}
.admin-list-card {
  border-radius: 14px !important;
}
.admin-list-card1 {
  border-radius: 14px !important;
  height: 700px;
}

/* to limit the size on image of quill editor */
.ql-editor img {
  height: 50%;
  width: 50%;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .admin-list-container {
    margin-top: 10em;
  }
  .active-other-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  .other-service-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  .button-other-nav-container {
    overflow-x: scroll;
  }
  .other-service-nav-container {
    overflow-x: scroll;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  /* .admin-list-container{
        padding: 0 7.5%;
        width: 85%;
    } */
  .button-other-nav-container {
    display: flex;
    overflow-x: hidden;
  }
  .other-service-nav-container {
    display: flex;
    /* margin: 0 7.5%; */
    overflow-x: hidden;
  }
  .button-navigation-others {
    width: 35px;
    height: 35px;
  }
}

.badge-style {
  background-color: #b52a2a !important;
}

.active-other-nav-card {
  vertical-align: super;
  /* background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important; */
  height: 2.5em;
  /* border-radius: 0.5em !important; */
  color: white !important;
  margin-right: 1em;
  /* border-bottom: 2px solid #27539b; */
  cursor: pointer !important;
  white-space: nowrap !important;
  /* border-right: 2px solid #dedede; */
  font-weight: 600;
}

/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
  .active-other-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  .other-service-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .other-service-nav-container {
    overflow-x: auto !important;
  }
}
.button-navigation-others {
  border-radius: 50%;
  opacity: 0.9;
  border: 1px solid #77acff;
  margin-top: 1.1em;
  width: 30px;
  height: 30px;
}
.button-navigation-others:active {
  border-color: none;
}
.button-navigation-others:focus {
  border-color: none;
  outline: none;
}

.other-service-nav-card {
  height: 2.5em;
  color: white !important;
  margin-right: 1em;
  cursor: pointer !important;
  white-space: nowrap !important;
  /* border-right: 2px solid #dedede;
    border-bottom: 2px solid #dedede; */
}

.others-card-text {
  vertical-align: super;
  font-size: 1em;
  color: #24242480;
  font-family: Barlows,'Noto Sans';
}

.button-other-nav-container {
  display: flex;
  overflow-x: hidden;
}
.other-service-nav-container {
  display: flex;
  /* margin: 0 2.5%; */
  overflow-x: hidden;
  width: 100%;
}
.other-service-nav-container::-webkit-scrollbar {
  display: none;
}

.action-tab {
  width: 100%;
}

.text-area {
  width: 40% !important;
}

.inside-fields {
  margin-top: 1.5em !important;
}
.enter-detail-input-box textarea {
  font-weight: 600 !important;
  color: black !important;
}
.table-content-rich-text-editor {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

@media only screen and (min-width: 1501px) and (max-width: 2648px) {
  .admin-container-height {
    height: 62rem;
  }
}

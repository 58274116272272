.vehicle-container{
    background-color: #e2ecf841;
    margin-bottom: 25px;
    padding-left: 0;
    margin-top: 0;
    margin-left: 0;
}
.desktop-view-fleet-data{
    display: block;
    padding-left: 12px;
    padding-right: 12px;
}
.mobile-view-fleet-data{
    display: none;
}
.grid-vehicle-container{
    background: #c1ddfd98;
    border: #c8dffb 2px dotted;
    padding: 1em;
    font-family: Barlows,'Noto Sans';
    margin: 0;
}
.fleet-data-analysis-template{
    border-bottom: 1px solid #d3d3d338;
    padding: 2em 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 300;
    font-size: small;
}
.fleet-data-vehicle-template{
    border-bottom: 1px solid lightgrey;

    font-family: Barlows,'Noto Sans';
    
}
.left-spacing{
    margin-left: 2em;
}
.fleet-data-survey-template{
    margin-top: 0.5em;
}

.heading-column-data{
    color: #c8dffb;
    opacity: 1;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.heading-column-data-header {
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.heading-column-data-info {
    color: #333333;
    opacity: 1;
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.grid-vehicle-container-heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.xl-heading-column-data{
    color: #9dc7fa;
    opacity: 1;
    font-weight: 500;
    font-size: 1.5em;
}
.small-heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 0.9em;
}
.end-align{
    text-align: end;
}
.underline-style{
    opacity: 1;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding-top: 15px;
    font-weight: 500;
}

.outer-spacing{
    padding: 1.75em
}
.inner-spacing{
    padding: 0 0 0 1.75em;
}
.fleet-data-text{
    color : #202020;
    font-weight: 300;
    font-size: 13px;
    margin-left: 5px;
}

@media only screen and (max-width: 600px) {
    .desktop-view-fleet-data{
        display: none;
    }
    .mobile-view-fleet-data{
        display: block;
    }
    .left-spacing{
        margin-left: 0em;
    }
    .fleet-data-text{
        font-size: 0.9em;
        margin-left: 0em;
    }
  }

  @media only screen and (min-width: 1800px) {
    .vehicle-header-info{
        font-size: 1.5em;
    }
    .heading-column-data{
        font-size: 1.5em;
    }
    .xl-heading-column-data{
        color: #9dc7fa;
        font-size: 2em;
    }
    .small-heading-column-data{
        font-size: 1.25em;
    }
    .fleet-data-text{
        font-size: 1.1em;
    }
  }

.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.margin-top-2em{
    margin-top: 2em;
}
.vehicle-container{
    padding-top: 0.8em;
    padding-bottom: 2em
}
.forecast-vehicle-container{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;

}
.grid-data-container{
    padding-left: 2em;
}
.padding-2em{
    padding-top: 2em
}
.padding-1em{
    padding-top: 1em
}
.heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 1em;
    padding-bottom: 0.4em;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding: 0.7em 0;
    font-weight: 500;
}
.font-wt-500{
    font-weight: 500;
}

.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: sticky !important;
    background-color: white;
}
.first-tire-col {
    left: 0!important;
  }
.second-tire-col{
    left: 6%!important;
}
.third-tire-col{
   
    left: 12%!important;
}
.fourth-tire-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 19%!important;
}
.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
.table-content-fleet-text {
    color: #404040 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
}
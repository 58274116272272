/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-tire-installed-tires {
  margin-top: 14em;
  padding: 0 2.5%;
  width: 95%;
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.view-tire-history-table {
  margin-top: -1em;
  padding: 0 2.5%;
  width: 95%;
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.table-function-title-view-tire {
  width: 100%;
  text-align: end;
  margin-bottom: 10px;
}
.quick-inspect-list-card-tire {
  border-radius: 14px !important;
}

.tire {
  width: 60px;
}

.location-details-grid {
  margin: 0 2em 2em 2em;
}

.uploaded-images-view {
  text-align: center;
  margin-bottom: 1em !important;
}

.view-tire-history-label {
  font-size: 20px !important;
}

.view-tire-graph-container {
  height: max-content;
  width: 100%;
  align-items: center;
  flex-direction: column;
  display: inline-flex;
}

.label-tire-details {
  font-weight: 600 !important;
}

/* MID SCREENS */

@media only screen and (max-width: 950px) {
  .quick-inspect-list-container {
    margin-top: 0em;
  }
}

/* LARGE SCREENS */

/* @media only screen and (min-width: 1500px) {
  .quick-inspect-list-container {
    padding: 0 7.5%;
    width: 85%;
  }
  .view-tire-installed-tires {
    padding: 0 7.5%;
    width: 85%;
  }
  .view-tire-history-table {
    padding: 0 7.5%;
    width: 85%;
  }
} */

.axel-container-create-vehicle{
    display: inline-block !important;
    text-align: center !important;
    margin-right: auto !important;
    margin-left: auto !important;
    flex-wrap: nowrap !important;
    margin-top: 30px !important;
}

.axel-container-create-vehicle-align-0-1{
    margin-left: 160px !important;
    margin-right: 10px !important;
}
.axel-container-create-vehicle-align-1-1{
    margin-left: 0px !important;
}

.axel-container-create-vehicle-align-0-2{
    margin-left: 100px !important;
    margin-right: 0px !important;
}

.axel-container-create-vehicle-align-1-2{
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.axel-container-create-vehicle-align-0-3{
    margin-left: 50px !important;
    margin-right: 0px !important;
}
.axel-container-create-vehicle-align-1-3{
    margin-left: 10px !important;
    margin-right: 0px !important;
}
.axel-container-create-vehicle-align-0-4{
    margin-left: -5px !important;
    margin-right: 0px !important;
}
.axel-container-create-vehicle-align-1-4{
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.double-tire{
    margin-left: 20px;
}

.axle-alignment{
    margin-left: 10px;
}

.create-vehicle-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
}

.create-vehicle-upper-half {
    display: flex;
    position: absolute;
    height: 30em;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.background-map-image-create-vehicle {
    position: absolute !important;
    /* background-image: url("../../../assets/map.svg"); */
    background-image: url("../../assets/map.svg") !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.create-vehicle-breadcrumbs {
    text-align: left;
    margin: 2em;
}

.create-vehicle-inner-container {
    display: flex;
    margin-bottom: 5em;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.step-indicator {
    border-radius: 4px;
    display: flex;
    background: #E1EAF5 0% 0% no-repeat padding-box !important;
    border: 1px solid #D0D0D0 !important;
    opacity: 1;
}

.step-forms-container {
    display: block !important;
    margin-right: 30%;
    margin-left: 30%;
    margin-bottom: 3em;
    /* text-align: center; */
}


#abc .MuiAutocomplete-inputFocused {
    color: rgba(0, 0, 0, 0.18) !important;
}


.vehicle-upload-box-description {
    display: inline-flex;
    border: 1px solid rgb(35, 85, 175, 0.22);
    border-radius: 5px;
    width: 100% !important;
    height: 5.5em;
    margin-top: 2.5em !important;
    margin-bottom: 8px !important;
    align-items: center;
}

.uploaded-images {
    display: flex;
    text-align: center;
    width: 110% !important;
    margin-bottom: 1em !important;
}


.field-label-vehicle {
    text-align: left;
    color: #333333;
    font-weight: 500;
    font-family: Barlow;
    font-size: 0.875em;
    margin-bottom: 10px;
    opacity: 1;
}

.field-label-pressure {
    text-align: left;
    color: #333333;
    font-weight: 500;
    font-family: Barlow;
    font-size: 0.875em;
    margin-bottom: 10px;
    opacity: 1;
}

.pressure-label {
    width: 100px;
    margin-top: -2%;
    margin-right: -10%;
    /* text-align: left; */
}

.axle {
    position: relative;
}

.axle .axleNumber-create {
    position: absolute;
    top: 23%;
    color: white;
    right: 44%;
}
.axleNumber-create-first {
    right: 41% !important;
}
.tire-create {
    width: 91px;
    /* flex-wrap:nowrap; */
}

/* .disabled-input {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: none;
} */

/* .tire-position{
    flex-wrap:nowrap;
} */
/* .axle-position{
    display: inline-flex;
    margin-top: 30px;
    margin-right: -30%;
    margin-left: -30%;
    flex-wrap: nowrap;
    text-align: center;
} */


.manage-vehicle-create-container{
    margin-top: 6.5em;
    padding: 0 2.5%;
    width: auto;
    position: relative;
    /* display: inline-block; */
    margin-bottom: 4em;
    margin-right: auto;
    margin-left: auto;
}

.vehicle-create-header{
    padding: 2em;
    display: flex;
    justify-content: space-between;
    /* margin-left: 25px; */
}

.MuiInputBase-input{
    padding-left: 10px !important;
}
@media only screen and (min-width: 1500px) {
    
    .vehicle-create-header{
        margin-left: 55px;
    }
    .vehicle-images{
        margin-left: 111px !important;
    }
    .create-vehicle-form{
        padding: 2em 7.5%;
    }
    .axel-container-create-vehicle-align-0-1{
        margin-left: 220px !important;
    }
    .axel-container-create-vehicle-align-0-2{
        margin-left: 183px!important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-1-2{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .axel-container-create-vehicle-align-0-3{
        margin-left: 125px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-4{
        margin-left: 80px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 950px) {
    .create-vehicle-form{
        padding: 1em;
    }
    .axel-container-create-vehicle-align-0-1{
        margin-left: 130px !important;
    }
    .axel-container-create-vehicle-align-0-2{
        margin-left: 30px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-3{
        margin-left: 30px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-4{
        margin-left: -39px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 750px) {
    .create-vehicle-form{
        padding: 0.5em;
        display: block;
    }
    .axel-container-create-vehicle-align-0-3{
        margin-left: -8px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-4{
        margin-left: 3px !important;
        margin-right: 0px !important;
    }
}

.left-10{
    margin-left: 10px;
}
.MuiStepConnector-alternativeLabel {
    width: max-content !important;
    left: calc(-50% + 190px) !important;
    right: calc(50% + 30px) !important;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-rim-main {
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.view-vehicle-vehicle-history {
    margin-top: -1.0em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.view-rim-list-card {
    border-radius: 14px!important;
}
.axle{
    position:relative;
}
.axle .axleNumber{
    position: absolute;
    top: 20%;
    color: white;
    right: 41%;
}
.tire{
    width:60px;
}

.location-details-grid-view-rim{
    margin: 0 2em 2em 1.5em !important;        
    margin-bottom: 2em !important;
}

/* MID SCREENS */

@media only screen and (max-width: 950px) {
    .view-rim-main {
        margin-top: 10em;
        width: 95%;
    }

    .view-vehicle-form{
        padding: 2em;
    }
}

/* LARGE SCREENS */

@media only screen and (min-width: 1500px) {
    .view-vehicle-form{
        padding: 2em 7.5%;
    }
    .view-rim-main {
        width: 85%;
    }
    .view-vehicle-vehicle-history{
        width: 85%;
    }
}


@media only screen and (max-width: 750px) {
    .view-vehicle-form{
        padding: 0.5em;
        display: block;
    }
}
.home-container {
  height: 125em;
  background-color: #f2f2f2;
  overflow-x: hidden;
  overflow-y: hidden;
}

.whats-new-container {
  background-color: #f2f2f2;
  width: 90%;
  border-radius: 0.5em;
  height: 95%;
}

@media only screen and (max-width: 1366px) {
  .home-container {
    height: 108em;
    background-color: #f2f2f2;
  }
}
.home-card-custom-style {
  background: rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  height: 4em;
  padding: 1em 0;
  border-radius: 1em !important;
  color: white !important;
}
.home-card-custom-style:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.4) !important;
}
.home-upper-deck {
  height: 100%;
  background-color: #f2f2f2;
}
.dashboard-header-home {
  padding-top: 2em;
  padding-bottom: 0em;
  background: #0d53b4;
  background-image: linear-gradient(to right, #0d53b4, #054196);
}
.print-icon-home {
  margin-top: -1em;
}

.no-data-container {
  padding-top: 5%;
}

.no-data-container-text {
  text-align: center;
  padding-bottom: 10%;
  font-size: 25px;
  color: #aaaaaa8c;
}
/* .MuiInput-root {
  

}
.add-new-delete-box {
    width: 11% !important;

} */
.broadcasting-card-padding {
  padding-top: 0em !important;
}
#height-css1 {
  height: auto !important;
  width: 100% !important;
}
.sizeDropCSS {
  position: absolute !important;
  margin-top: 7% !important;
  margin-left: 30% !important;
  width: 11% !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  font-weight: 600 !important;
}

.background-image-home-deck {
  height: 25%;
  background: transparent linear-gradient(161deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-image-home-dashboard {
  position: relative;
  background-image: url("../../../../assets/map.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}
.grid-graph-home-dashboard-container {
  margin-top: 0em;
}
.home-location-header {
  padding: 0 2em;
}
.view-link-floating-card {
  width: 100%;
  margin-top: -20px;
  margin-bottom: 10px;
  float: right;
  text-align: end;
  vertical-align: bottom;
  align-items: baseline;
}
.floating-card-padding {
  padding-top: 2em !important;
}
.floating-card {
  margin: 1em 0.5em !important;
  justify-content: space-between;
}
.header-change-modal-confirm-btn1 {
  opacity: 0.8;
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #0055a4 0%, #0079e6 100%) 0% 0% no-repeat padding-box;
  /* background: transparent linear-gradient(90deg, #0079e6 0%, #0079e6 100%) 0% 0% no-repeat padding-box; */

  border: 0;
}
.header-change-modal-confirm-btn1:hover {
  opacity: 1;
}

.header-change-modal-title-container1 {
  display: flex;
  margin-left: 1em;
}
.print-icon {
  width: 100%;
  text-align: right;
}
.fab-class {
  background-color: rgba(255, 166, 0, 0.644) !important;
  float: right;
  margin-right: 1rem !important;
  transform: rotate(90deg);
  top: 50%;
  left: 3%;
}
.fab-class:hover {
  background-color: orange !important;
}

.modal-button-group1 {
  display: flex;
  justify-content: center;
  /* margin-bottom: 0.5rem; */
}
.header-msg {
  font-size: 1.5em;
  font-family: Barlows,'Noto Sans';
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  /* background-image: linear-gradient(to right, #073475, #083475); */
  background-color: #083475;
  border-radius: 5px;
  color: #ffdc00;
  /* background: transparent linear-gradient(161deg, #073475 0%, #083475 100%) 0% 0% no-repeat padding-box; */
}
.broad-body {
  position: relative;
}
.footer-msg {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 1.5em;
  font-family: Barlows,'Noto Sans';
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 16.43px;
  position: relative;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.header-change-modal-title1 {
  font-size: 10px;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 0.3em;
}
.change-modal-select-label1 {
  margin-bottom: 0.3em !important;
  font-size: 8px !important;
  color: #222222 !important;
  margin-left: 1.6em !important;
  font-family: Barlows,'Noto Sans';
  /* max-height: 70%; */
  /* overflow-y: scroll; */
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .header-change-modal-title1 {
    font-size: 1.5em;
  }
  .change-modal-select-label1 {
    font-size: 1em !important;
  }
}
.print-dialog {
  background: transparent !important;
  color: #fff !important;
  border: none !important;
}
.print-dialog:hover {
  color:#ffdc00 !important;
  background-color: #0d53b400 !important;
}
/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
  .home-location-header {
    padding: 1em;
  }
}

/* MOBILE VIEW */
@media only screen and (max-width: 750px) {
  .home-location-header {
    padding: 0.5em;
  }
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
  .home-location-header {
    padding: 0.5em 7.5%;
  }
}

.export-btn-img {
  width: 0.8em;
}

.message {
  color: rgb(27, 27, 27);
  font-size: 8pt;
  /* font-weight:bold; */
  text-align: left;
}

.chatBubble {
  margin-top: 2%;
  margin-right: 5%;
  border: 1px solid #3180ca69;
  border-radius: 10px;
  background-color: rgb(250, 250, 250);
  padding: 10px;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #3180ca69;
  transform: rotate(90deg);
  position: relative;
  float: left;
  left: -2.9%;
  bottom: -20%;
}

.desktop-view-reports-nav{
    display:block;
}
.mobile-view-reports-nav{
    display:none;
}
.nav-container{
    background: #0d53b4;
    background-image: linear-gradient(to right, #0d53b4 , #054196);
}
.nav-elements-container{    
    display: flex;
    margin-left: 3em; 
    height: 3em;
    /* border-bottom : 1px solid #FFFFFF2B */
}
.tabs{
    color: rgba(255, 255, 255, 0.411);
    cursor: pointer;
    font-size: 1em;
    margin: 0.75em 1.5em;
    font-family: Barlows,'Noto Sans';
}
.tabs:hover{
    color: #FFDC00;
}
.selected-tab{
    color: #FFDC00;
    opacity: 1;
}

.selected-tab-border{
    /* height: 3.5px; */
    width:80%;    
    /* border-bottom: 4px #FFDC00 solid;  */
    font-weight: 600;
    margin: auto;
}
.viewed-tab-border{
    margin: auto;
    width: 0; 
    height: 0; 
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-bottom: 7.5px solid white;
}

.reports-nav-header-title{
    padding: 1em;
    font-size: 1em;
    font-family: Barlows,'Noto Sans';
}
.reports-nav-header-title:hover{
    cursor: pointer;
    color: #FFDC00;
}
.reports-nav-active-header-title{
    color: #FFDC00;
    background-color: #0A4593;  
    height: 1em;    
}

/* BREAKPOINT FOR HANDLING MOBILE VIEW REPORTS NAV  */

@media only screen and (max-width: 950px) {
    .nav-elements-container{    
       overflow-x: scroll;
       margin-left: 0; 
    }
    .tabs{
        white-space: nowrap;
    }
    /* .reports-nav-header-title{
        white-space: nowrap;
    }
    .reports-nav-active-header-title{
        white-space: nowrap;
    } */
    .desktop-view-reports-nav{
        display:none;
    }
    .mobile-view-reports-nav{
        display:block;
    }
    .drawer-opener-container{
        display: flex;
        /* justify-content: space-between; */
        background:#1250B0;
    }
    .menu-drawer-header-text{
        font-family:Barlows,'Noto Sans';
        padding:1em;
        color:white;
    }
    .menu-icon-container{
        width: 40%;
    }
    .MuiDrawer-paper{
        width: 100% !important;
    }
    .reports-back-header-container{
        margin: 1em;
        display: flex;
    }
    .back-arrow{
        height: 1.55em;
    }   
    .reports-title{
        margin-left: 0.25em; 
        font-size: 1.25em;
        font-family: Barlows,'Noto Sans';
        font-weight: bold;
    }
    
}

@media only screen and (max-width: 450px) {
    .menu-icon-container{
        width: 35%;
    }
}
@media only screen and (min-width: 1500px) {
    .nav-elements-container{    
        margin-left: 6%; 
        height: 3.75em;
    }
    .tabs{
        font-size: 1.25em;
    }
    .reports-title{
        font-size: 1.4em;
    }
}

.locations-container{
    display: flex;
  }
  .locations-text{
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #0055a4 !important;
    font-size: 0.75em;
    cursor: pointer;
    background-color: transparent;
    /* border: 1px solid #cfdfff;
    border-radius: 5px;
    padding: 5px; */
  }
  .locations-text-strike{
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #0055a4 !important;
    font-size: 0.75em;
    cursor: pointer;
    text-decoration: line-through;
    background-color: #cfdfff;
    opacity: 50%;
    border: #0055a4;
    border-radius: 5px;
    padding: 5px;
  }
  
  .locations-text:hover {
    color: #0055a4 !important;
    background-color: #cfdfff;
  }

  .locations-text-strike:hover {
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #01284d !important;
    font-size: 0.75em;
    cursor: pointer;
    background-color: #7aaefccb;
    border: #0055a4;
    border-radius: 5px;
    padding: 5px;
  }
.inspect-tire-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.background-map-image-inspect-tire {
  position: absolute !important;
  background-image: url("../../assets/map.svg") !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}

.inspect-tire-container {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1;
}

.inspect-tire-inner-container {
  margin: 0px 0px 20px 0px;
  height: 100%;
  width: 100%;
}

.vehicle-detail-container {
  border-radius: 14px;
  height: 20%;
  width: 100%;
  background-color: #faf9f2;
  overflow: auto;
}

.vehicle-details-grid {
  margin: 30px;
}

.item-detail-label {
  font: normal normal bold 0.9em/2.5em Barlows,'Noto Sans';
  margin-bottom: -0.3em;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.item-detail-content {
  font: normal normal normal 0.9em/2.5em Barlows,'Noto Sans';
  margin-top: -0.3em;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.inspect-tire-form-container {
  display: block !important;
  margin-right: 30%;
  margin-left: 30%;
  margin-bottom: 3em;
  text-align: center;
}

.form-inner-container {
  width: 70% !important;
  margin-right: 15% !important;
  margin-left: 15% !important;
}

.enter-detail-input-box input {
  font-weight: 500 !important;
  color: black !important;
}

.enter-detail-input-box select {
  font-weight: 500 !important;
  color: black;
}

.enter-detail-input-box select:not(:valid) {
  color: rgba(0, 0, 0, 0.5);
}
.enter-detail-input-box select .list-color-change {
  color: black;
}

.buttoned-input {
  text-align: center !important;
}

.upload-box-description-inspect-tire {
  display: flex;
  border: 1px solid rgb(35, 85, 175, 0.22);
  border-radius: 5px;
  width: 100% !important;
  height: 5.5em;
  margin-top: 1.5em !important;
  margin-bottom: 0px !important;
  align-items: center;
}

.remove-box-description {
  display: flex;
  border: 1px solid rgb(35, 85, 175, 0.22);
  border-radius: 5px;
  width: 100% !important;
  height: 5.5em;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  align-items: center;
}

.remove-tire-label-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #393939;
}

.tooltip-icon-remove-tire {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
}

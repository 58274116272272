.vehicle-reports-upper-deck{
    position: absolute;
    width: 100%;
    height: 40%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url("../../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* VEHICLE TIRE HEADER (BREADCRUMBS & TITLE) */

.vehicle-reports-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.vehicle-reports-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* VEHICLE TIRE NAVIGATION  */

.vehicle-time-nav-container{
    display: flex;
    padding: 0 2.5%;
}

.vehicle-reports-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}

.vehicle-reports-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-vehicle-reports-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}
.vehicle-reports-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
    white-space: nowrap;
}
.active-vehicle-reports-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
    white-space: nowrap;
}
.scrap-time-nav-container{
    display: flex;
    padding: 0 2.5%;
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .vehicle-time-nav-container{
        display: flex;
        padding: 0 7.5%;
    }
    .vehicle-reports-nav-card-text{
        font-size:1.2em;
    }
    .active-vehicle-reports-nav-card-text{
        font-size:1.2em;
    }
    .vehicle-reports-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .vehicle-time-nav-container{
        overflow-x: scroll
    }
    .vehicle-reports-nav-card-container{
    margin-right: 1em;
    }
    .vehicle-reports-upper-deck{
        height: 60%;
    }
    .vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
   
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .vehicle-reports-upper-deck{
        height: 60%;
    }
    .vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }

}

/* @media only screen and (min-width: 1500px)
{
    .scrap-time-nav-container {
        display: flex;
        margin: 0 2.5%;
        overflow-x: auto;
    }
} */
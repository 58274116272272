.survey-template-toolbar-multi{
    display : flex;
    margin-bottom: 1em;
}
.survey-template-toolbar-single{
    display : flex;
    margin-bottom: 1em;
    justify-content: flex-end;
}
.survey-template-location-selector-container{
    width: 60%;
}
.survey-template-location-selector{
    width: 40%;
}
.survey-template-export-items{
    width: 40%;
    text-align: end;
}

.survey-template-location-label-container{
    margin-bottom: 0.25em;
}
.survey-template-location-label{
    font-size: 1em;
    color : #0055A4;
    font-weight: 400;
    font-family : Barlows,'Noto Sans';
    margin-right: 0.25em;
}
.survey-template-location-label-text{
    font-size: 1em;
    font-weight: 500;
    color : #0055A4;
    font-family : Barlows,'Noto Sans';
}


.survey-template-function-item{
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    font-size: 0.8em; 
    margin: 0 0.5em;
    cursor: pointer;
    background: white;
    border-radius: 0.5em;
    border: 1px solid white;
    height: 2.5em;
}
.survey-template-function-item:hover{
    background: #C4DCFFAF !important;
}
.download-icon-img{
    height: 0.9em;
    margin-right:0.3em;
}
.print-icon-img{
    height: 0.8em;
    margin-right:0.2em;
}
.mail-icon-img{
    height: 0.7em;
    margin-right:0.2em;
}
.survey-template-toolbar-text{
    font-size: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
}
.axle-config-template{
    text-align: center;
}
#iframeContainer{
    width: 100% ;
    height: 60em ;
    /* zoom: 2 ; */
}
.survey-template-desktop{
    display:block
}
.survey-template-mobile{
    display:none
}

/* MOBILE VIEW  */
@media only screen and (max-width: 768px) {
    .survey-template-toolbar-multi{
        display: block;
        margin-bottom: 0.5em;
    }
    .survey-template-toolbar-single{
        display: block;
        margin-bottom: 0.5em;
    }
    .survey-template-function-item{
        margin : 0;
        padding-right : 1.5em;
    }
    .survey-template-location-selector-container{
        width: 100%;
    }
    .survey-template-location-selector{
        width: 100%;
    }
    .survey-template-export-items{
        width: 100%;
    }
   
} 

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    .survey-template-toolbar-text{
        font-size: 1.3em;
    }
    .print-icon-img{
        height: 1.1em;
        margin-right:0.5em;
    }
    .mail-icon-img{
        height: 1em;
        margin-right:0.5em;
    }
    .download-icon-img{
        height: 1.1em;
        margin-right:0.5em;
    }
}

/* Portrait and Landscape tablet*/
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .survey-template-desktop{
        display:none
    }
    .survey-template-mobile{
        display:block
    }

}
.template-box{
    border: 1px solid #000000de;
    height: 2.1em;
}
.margin-top-1em{
    margin-top: 1em;
}
.axle-analysis-template{
    border-bottom: 1px solid lightgrey;
    padding: 2em 1em;
}
.axle-vehicle-template{
    border-bottom: 1px solid lightgrey;
}
.left-spaceing{
    margin-left: 2em;
}
.axle-survey-template{
    margin-top: 0.5em;
}
.edit-location-list-container {
    margin-top: 8em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.edit-location-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    margin-bottom: 120px;
}

.edit-location-upper-half {
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 100%;
}

.edit-location-breadcrumbs {
    text-align: left;
    margin: 1.5em;
    margin-left: 80px;
}

.standalone-new-header-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
}

.edit-location-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}


.edit-location-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.edit-location-inner-container {
    margin-left: 28px;
    margin-top: 30px;
    margin-bottom: 15px;
    height: 100%;
    width: 100%;
}

.background-map-image-edit-location {
    position: absolute !important;
    background-image: url("../../../assets/map.svg") !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}


.sub-header {
    text-align: left;
    color: #194cab;
    opacity: 1;
    font-family: Barlow;
    font-size: 22px;
    margin-bottom: 10px;
}

.location-details-grid-edit {
    margin-bottom: 1em;
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .edit-location-list-container{
      margin-top: 0em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .edit-location-list-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */

.edit-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    width: 90%;
    height: 45px;
    align-self: center;
    opacity: 1;
}
.scrap-tires-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 150%; */
    position: absolute;
    width: 100%;
    /* Footer fix */
    height: 100%
}

.scrap-tires-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url("../../../../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .scrap-button-navigation-style{
        display: none;
    }
    .scrap-tires-nav-container{
        overflow-x: auto!important;
    }
}
.scrap-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6.3em;
    margin-top:7px;
    height: 45px;
}
.scrap-button-navigation-style:active{
    border-color: none;
}
.scrap-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.scrap-tires-title{
    color: white;
    font-family: Barlow;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.scrap-button-scrap-tires-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.scrap-tires-nav-container{
    display: flex;
    overflow-x: hidden;
}
.scrap-tires-nav-card-container{
    margin-right: 1em;
}
.scrap-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.scrap-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}
.scrap-tires-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-scrap-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.scrap-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-scrap-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .scrap-tires-main-container{
        height: 110%;
    }
    .scrap-tires-nav-container{
        display: flex;
        overflow-x: hidden;
        margin: 0 0.5%;
    }
    .scrap-button-scrap-tires-nav-container{
        display: flex;
        margin: 0 2.5%;
        overflow-x: hidden;
    }
    .scrap-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-scrap-tires-nav-card-text{
        font-size:1.2em;
    }
    .scrap-tires-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .scrap-tires-main-container{
        height: 100%;
    }
    .scrap-tires-nav-container{
        overflow-x: scroll;
    }
    .scrap-button-scrap-tires-nav-container{
        overflow-x: scroll;
    }
    .scrap-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
.image-modal{
    width: 100%;
    height: 100%;
}

.image-modal-close{
    margin-top: 0.2em;
    margin-right: 0em;
}

.image-modal-body-container {
    display: flex;
    margin: 0.2em;
}

.image-modal-container {
    border-radius: 0.5em;
    width: 33%;
    height: 77%;
    background: white;
    overflow: auto;
    max-height: 100%;
}

.modal-previous-icon{
    height: 20px;
    width: 20px;
}

.modal-next-icon{
    height: 20px;
    width: 20px;
}

.image-previous-icon-container{
    width:20%;
    margin-left: 18%;
}

.image-modal-title{
    width: 30%;
    color: #000;
    font-family: Barlows,'Noto Sans';
    font-size: 1em;
}

.image-next-icon-container{
    margin-right: 20%;
}

.graph-close {
    margin-top: -12px!important;
}

.graph-title{
    margin: 1em;
}
.daily-job-reporting-periods{
    font-size: 0.75em;
    display: contents;
}

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
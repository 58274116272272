.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}
.MuiFormHelperText-root {
  color: tomato !important;
}
.warn-text {
  color: #ffe500 !important;
  text-shadow: 0 0 0.6px #000000;
}

.label-color {
  color: #333333;
}

.input {
  width: 100%;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6 !important; /** inherit; */
  /* border-color: #d4d5d6!important; */
  color: #565656;
  -webkit-appearance: none;
}
.MuiChip-root {
  background-color: #66a2d9 !important;
  border-radius: 30px !important;
  height: 30px !important;
  padding: 0 4px 0 1rem !important;
  display: inline-flex !important;
  align-items: center !important;
  /* margin: 0.5rem 0 0.5rem 0.5rem!important; */
  color: white !important;
  font-family: Barlows,'Noto Sans' !important;
  font-size: 1em !important;
}
.MuiChip-deleteIcon {
  color: #fff !important;
}
.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #66a2d9;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.5rem 0.5rem 0;
  color: white;
  font-family: Barlows,'Noto Sans';
  font-size: 1em;
}

.tag-item > .button {
  background-color: #66a2d9;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.div-bottom-margin {
  margin: 0 0 2rem 0;
}
.subject-div-bottom-margin {
  margin: 0 0 0.5rem 0;
}

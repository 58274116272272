
.overlay {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 5 !important;
    opacity: 1;
 }
 .responsive-tab-grid-container{
     margin: 0 2em !important;
 }
 .title-text{
     font-size : 1.2em;
     font-family: Barlows,'Noto Sans';
     font-weight: bold;
     color: #00346E;
     margin: 1em 0;
     height: 1em;
 }
 .link-text{
    color: #2B2B2B;
    margin: 1em 0;
    font-size: 1em;    
    font-family: Barlows,'Noto Sans';    
 }
 .link-text:hover{
     color: #1250B0;
     font-weight: bold;
     cursor: pointer;
 }
 .sub-header-title-text{
    font-size : 1.2em;
    font-family: Barlows,'Noto Sans';
    font-weight: bold;
    color: #00346E;
    margin-top: 0;
    height: 1em;
 }
 .current-link-text{
    color: #1250B0;
    margin: 1em 0;
    font-size: 1.1em;    
    font-family: Barlows,'Noto Sans';  
    font-weight: 500;
 }
 .survey-template-title-text{
    font-size : 1.2em;
    font-family: Barlows,'Noto Sans';
    font-weight: bold;
    color: #00346E;
    margin-top:1em;
    margin-bottom:1em;
    margin-left:1.5em;
 }
 .template-container{
     margin: 0 2em;
     flex-grow: 0;     /* do not grow   - initial value: 0 */
     flex-shrink: 0;   /* do not shrink - initial value: 1 */
 }
.template-width{
    width: 20%;
}


/* BREAKPOINT FOR HANDLING MOBILE VIEW TAB-VIEWS  */

@media only screen and (max-width: 950px) {
    .responsive-tab-grid-container{
        margin: 0 !important;
    }
    .survey-template-title-text{
        margin:1em 0;
     }
}

/* HANDLING FONT FOR XL SCREENS */
@media only screen and (min-width: 1500px) {
    .title-text{
        font-size : 1.2em;
    }
    .link-text{
       font-size: 1em;    
    }
    .survey-template-title-text{
       font-size : 1.2em;
    }
    .sub-header-title-text {
        font-size: 1em; 
    }
    .responsive-tab-grid-container{
        margin: 0 3em !important;
    }
}

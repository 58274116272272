.dashboard-graph-table-container{
    display:flex;
    padding: 0 1em;
}

.graph-container{
    margin-right: 1em; 
    width: 30%;
}
.table-container{
    width: 70%;
}

@media only screen and (max-width: 1100px) {
    .display-columns-container{
            width: 100%;
            max-width: 10em;
            margin-left: 0.5em;
        }
}

/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
    .dashboard-graph-table-container{
        display:block;
        padding: 0 1em;
    }
    
    .graph-container{
        margin:1em 0; 
        width: 100%;
    }
    .table-container{
        margin: 1em 0; 
        width: 100%;
    }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    
    .dashboard-graph-table-container{
        padding: 0 2.5%;
    }
  
}


.axle-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 150%; */
    position: absolute;
    width: 100%;
     /* Footer fix */
     height: 100%
}

.axle-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url("../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* SCRAP TIRE HEADER (BREADCRUMBS & TITLE) */

.axle-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.axle-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* SCRAP TIRE NAVIGATION  */

.axle-nav-container{
    display: flex;
    padding: 0 2.5%;
}

.axle-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}

.axle-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-axle-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}
/* .axle-nav-bottom-arrow {
    position:relative;
    background-color:#FFBE00;
}

.axle-nav-bottom-arrow::after {
    content:'' !important;
    position: absolute !important;
    top: 100% !important;
    left: 50% !important;
    margin-left: -50px !important;
    width: 0 !important;
    height: 0 !important;
    border-top: solid 50px #FFBE00 !important;
    border-left: solid 50px transparent !important;
    border-right: solid 50px transparent !important;
} */

.axle-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
    white-space: nowrap;
}
.active-axle-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
    white-space: nowrap;
}


/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .axle-main-container{
        height: 110%;
    }
    .axle-nav-container{
        display: flex;
        /* padding: 0 7.5%; */
    }
    .axle-nav-card-text{
        font-size:1.2em;
    }
    .active-axle-nav-card-text{
        font-size:1.2em;
    }
    .axle-title{
        font-size: 2em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .axle-main-container{
        /* height: 150%; */
         /* Footer fix */
        height: 100%
    }
    .axle-nav-container{
        overflow-x: scroll
    }
    .axle-nav-card-container{
    margin-right: 1em;
    }
    .axle-upper-deck{
        height: 60%;
    }
    .axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
   
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    .axle-upper-deck{
        height: 60%;
    }
    .axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }

}

/* Footer fix */
.app-footer-container {
    z-index: 10;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-top: 1px solid #dedede;
    width: 100%;
  }
#root .footerClass{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* position: relative;
    bottom: 0;
    width: 100%;
    overflow: hidden; */
}
 
.footerClass .footerCopyright {
    font: Bold 0.7em Barlows,'Noto Sans';
    letter-spacing: 0px;
    color: #074BA7;
    opacity: 1;
    padding-right: 0.5rem;
}
 
.footerClass .footerLink{
    letter-spacing: 0px;
    color: #074BA7;
    opacity: 1;
    font: 0.7em Barlows,'Noto Sans';
    flex-grow: 1;
}
/* For extra large screens */
@media screen and (min-width: 1900px) {
    .footer-container{
        max-width: 1800px !important;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .footer-container{
        bottom: 0;
        position: fixed;
        background: #ffffff;
    }
}

@media screen and (max-width: 767px) {
    .footer-container{
        bottom: 0;
        position: fixed;
        background: #ffffff;
    }
}
.tireImg {
  width: 100%;
  height: 100%;
}
.rimOnlyImg {
  width: 90%;
  height: 100%;
}
.tire-container {
  position: relative;
  width: 7em;
  text-align: center;
  margin-right: 1px;
  border-radius: 12px;
  border: 2px solid rgb(214, 214, 214);
  height: 15.625em;
  color: white;
  opacity: 1;
}
/* .tire-container:hover {
  opacity: 1;
} */

.textArea {
  width: 100%;
  height: 100px;
  font-family: Barlow;
  font-size: 1em;
}
.tire-container-yellow {
  position: relative;
  background-color: #f0de00;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-red {
  position: relative;
  background-color: #ff0100;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-green {
  position: relative;
  background-color: #16d513;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-orange {
  position: relative;
  background-color: #ffa502;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-empty {
  position: relative;
  width: 7em;
  text-align: center;
  margin-right: 1px;
  border-radius: 12px;
  height: 15.625em;
  color: white;
  opacity: 0.9;
}

.tire-container-temporary {
  position: relative;
  width: 7em;
  text-align: center;
  margin-right: 1px;
  border-radius: 12px;
  border: 2px solid rgb(214, 214, 214);
  height: 15.625em;
  color: rgb(239, 239, 239);
  opacity: 0.9;
}

.switch-container {
  /* background-color: #77acff87; */
  width: 6em !important;
  border-radius: 15px;
  padding: 0 !important;
}
.switch-container-blue {
  background-color: #58cce8;
  opacity: 0.8;
}
.switch-container-blue:hover {
  opacity: 1;
}
.switch-container-red {
  background-color: #d14449;
  opacity: 0.8;
}
.switch-container-red:hover {
  opacity: 1;
}
.axleConfigBar {
  border: none;
}
.axle-inspect {
  position: relative;
  top: 15%;
}
.axle-inspect .axleNumber {
  position: absolute;
  top: 11%;
  color: white;
  right: 46%;
}

.img-background-tire {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.centered {
  position: absolute;
  top: 49%;
  width: 5.5em;
  text-align: center;
  left: 52%;
  transform: translate(-50%, -50%);
}

.axleImg {
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.axle-position {
  align-items: center;
  display: inline-grid;
  margin-bottom: 50px;
  margin-top: 50px;
}

.width-images-quick-inspect-tire {
  width: 900px;
}
@media screen and (min-width: 1366px) {
  .width-images-quick-inspect-tire {
    width: 980px;
  }
}
@media screen and (min-width: 1920px) {
  .width-images-quick-inspect-tire {
    width: 1200px;
  }
}
@media screen and (min-width: 2000px) {
  .width-images-quick-inspect-tire {
    width: 1250px;
  }
}
.field-tire {
  text-align: center;
  font-family: Barlow;
  font-size: 0.75em;
  /* font-size: 0.79em; */
  display: block;
  font-weight: bold;
  margin: 3px;
  opacity: 1;
  /* width: 100%; */
}

.update-hour-inner-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tooltipicon {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
  align-self: center;
}

.tooltip-word {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
  align-self: center;
  color: #cfbe23bb;
}
.tooltip-word:hover {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
  align-self: center;
  color: #ebd512d5;
}

.info-msg {
  display: block;
  position: absolute;
  background: rgb(195 221 255);
  top: 20%;
  right: 15%;
  padding-bottom: 15px;
  height: 17%;
  border-radius: 15px;
  width: 40%;
  box-shadow: 3px 10px 18px #88888865;
}
.info-icon:hover ~ .info-msg {
  display: block;
}

.tireimage {
  height: 50%;
  width: 20%;
  padding-top: 10px;
}

.tire-link-label {
  font-weight: 600;
}

.edit-tire-btn {
  background: transparent;
  opacity: 0.6;
  font: Barlows,'Noto Sans';
  font-weight: 600;
  z-index: 0;
  box-shadow: none;

  margin-top: 5px;
  margin-bottom: 5px;
  color: #0d54b5;
  border: 1px solid #0d54b5;
  border-radius: 20px;
  word-wrap: break-word;
  padding: 2px;
  width: 6.6em;
}
.edit-tire-btn:hover {
  background-color: transparent;
  opacity: 1;
}

.view-tire-btn {
  background: transparent;
  opacity: 0.4;
  font: Barlows,'Noto Sans';
  font-weight: 600;
  z-index: 0;
  box-shadow: none;
  margin-bottom: 5px;
  color: #0d54b5;
  border: 1px solid #0d54b5;
  border-radius: 20px;
  word-wrap: break-word;
  padding: 2px;
  width: 6.6em;
}
.view-tire-btn:hover {
  background-color: transparent;
  opacity: 1;
}

.inspect_vehicle-hr-divider {
  margin-top: 10px;
  border: 1px solid #eee;
}

.onClick-link-tire-serial-number {
  margin-top: 3px;
  margin-bottom: 2px;
  /* color: #0d54b5; */
  word-wrap: break-word;
  padding: 1px;
  width: 11em;
  font-size: 10px;
  text-align: center;
}
/* .onClick-link-tire-serial-number:hover {
  font-weight: 500;
} */

.onClick-link-rim-serial-number {
  margin-bottom: 2px;
  color: #0d54b5;
  word-wrap: break-word;
  padding: 2px;
  width: 9em;
  font-size: 12px;
}
.onClick-link-rim-serial-number:hover {
  font-weight: 500;
}

.unClickable-link-rim-serial-number {
  margin-bottom: 2px;
  color: #000000;
  word-wrap: break-word;
  padding: 2px;
  width: 9em;
  font-size: 12px;
}
.onClick-link {
  margin-top: 3px;
  margin-bottom: 5px;
  margin-left: 5px;
  color: #0d54b5;
  word-wrap: break-word;
  padding: 2px;
  width: 6.6em;
}
.onClick-link:hover {
  font-weight: 500;
  /* border-Radius: 10px;
    background-color: #EAF3FB; */
  opacity: 1;
}

.removed-tire-image-container{
    display: flex;
    margin: 2em 0;
}
.image-container{
    width: 252px;
    height: 252px;
    border: 0.5px solid #707070;
    border-radius: 7px;
    opacity: 1;
    cursor: pointer;
}
.image-style{
    width: 100%;
    height:100%;
    border-radius: 7px;
}
.update-hour-parent-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.update-hour-parent-upper-half {
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.update-hour-parent-breadcrumbs {
    text-align: left;
    margin: 2em;
}


.update-hour-parent-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.update-hour-parent-inner-container {
    height: 100%;
    width: 100%;
}

.header-inner-container {
    margin: 0px;
    height: 103px;
    border-radius: 14px 14px 0px 0px;
    width: 100%;
    background: #FAF9F2 0% 0% no-repeat padding-box;
    opacity: 1;
}

.inner-container {
    width: 80% !important;
    margin-right: 10% !important;
    height: 103px;
    margin-left: 10% !important;
    align-items: center;
    justify-content: space-between;
}

.background-map-image-update-hm {
    position: absolute !important;
    background-image: url("../../../assets/map.svg") !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.update-hour-details-message {
    text-align: left;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    font-family: Barlow, Noto Sans;
    font-size: 15px;
    letter-spacing: 0.14px;
    margin-bottom: 20px;
}

.update-hour-parent-account-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.disable-field-label{
    text-align: left;
    color: #000;
    font-weight: 500;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
    opacity: 1;
}
.checkbox-field-label{
    text-align: left;
    color: #333;
    font-weight: 500;
    font-family: Barlow;
    font-size: 1em;
    margin-bottom: 10px;
    opacity: 1;
}

.update-hour-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px !important;
    color: black !important;
    width: 90%;
    height: 45px;
    align-self: center;
    opacity: 1;
}

.field-label-p {
    
    text-align: left;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}
.opcity-zero{
    opacity: 0;
}
.opcity-one{
    opacity: 1;
}
.removed-tire-container {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

.removed-tire-table-toolbar {
  display: flex;
  margin-bottom: 1em;
}

.selection-limit-text {
  font-size: 11px;
  color: gray;
  /* margin-bottom: -0.8em; */
  margin-left: 0.5em;
}

.table-filters {
  display: flex;
  width: 60%;
  margin-bottom: 10px;
}
.search-table-container {
  width: 15em;
}
.tires-select-container {
  max-width: 15em;
  min-width: 15em;
  margin-left: 0.5em;
}

.filter-table-label {
  color: #000000 !important;
  font-family: Barlow, Noto sans !important;
  font-size: 1em !important;
  margin-bottom: 0.5em;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

.display-columns-style {
  width: 100% !important;
}

.multi-select-display-col {
  height: 0.9em !important;
  z-index: 4 !important;
}

#dashboard-display-column .multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 2.2em !important; /* Height */
}

.table-function-title {
  width: 40%;
  text-align: end;
}
.table-function-item {
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  font-size: 0.7em;
  cursor: pointer;
  background: white;
  border-radius: 0.5em;
  border: 1px solid white;
  height: 2.5em;
}
.table-function-item:hover {
  background: #cfdfff !important;
}
.filter-icon-img {
  height: 0.9em;
  margin-right: 0.2em;
}
.print-icon-img {
  height: 0.9em;
  margin-right: 0.2em;
}
.mail-icon-img {
  height: 0.8em;
  margin-right: 0.2em;
}
.keyword-search-icon-img {
  height: 1.25em;
}

/* EXPORT DROPDOWN */

.export-button {
  color: #0055a4 !important;
  font-family: Barlow, Noto sans !important;
  font-size: 0.7em !important;
  cursor: pointer !important;
  background: white !important;
  /* border: 1px solid #77acff !important; */
  /* border-radius: 0.5em !important; */
  height: 2.5em !important;
}
.export-dropdown {
  position: relative;
  display: flex;
}
.export-dropdown-content {
  position: absolute;
  background-color: white;
  width: 5em;
  z-index: 3;
  border-radius: 0.5em;
  border: 1px solid lightgray;
  margin-top: 1.8em;
}

.export-dropdown-content .export-option {
  color: #0055a4;
  padding: 0.5em;
  font-family: Barlows,'Noto Sans';
}

.export-option:hover {
  background: #b1d2ff;
  cursor: pointer;
}
.dropdown-icon-container {
  cursor: pointer;
  display: flex;
}
.export-down-arrow {
  padding-left: 0.25em;
}

/* MOBILE VIEW  */
@media only screen and (max-width: 600px) {
  .removed-tire-container {
    margin-top: 25em;
  }
  .removed-tire-table-toolbar {
    display: block;
  }
  .table-header {
    display: block;
  }
  .table-filters {
    display: block;
    width: 100%;
  }
  .search-table-container {
    width: 100%;
  }
  .display-columns-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .search-table-bar {
    width: 100% !important;
  }

  .table-title {
    margin-bottom: 1em;
    width: 100%;
  }
  .table-function-title {
    width: 100%;
    text-align: start;
  }
  .table-function-item {
    margin: 0;
    padding-right: 1.5em;
  }
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .removed-tire-container {
    margin-top: 10em;
  }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
  .removed-tire-container {
    padding: 0 7.5%;
    width: 85%;
  }
} */

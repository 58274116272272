.MuiDrawer-paperAnchorRight {
    width: 40%;
}
.filter-header{
    display: flex;
    padding:1em;
}
.filter-title{
    width: 50%;
    font-family: Barlows,'Noto Sans';
    font-size: 1.25em;
    color: #0055A4;
}
.filter-header-options{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.filter-reset{
    margin-right:1em;
    cursor: pointer;
    font-family: Barlows,'Noto Sans';
    padding: 0.5em;
    color: #0055A4;
}
.filter-apply{
    margin-right:1em;
    cursor: pointer;
    background: transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    font-family: Barlows,'Noto Sans';
    color: white;
    width: auto;
    padding: 0.5em;
    text-align: center;
    
}

.filter-applied-disable {
    margin-right: 1em;
    cursor: pointer;
    background: transparent linear-gradient(90deg, #bebfc046 0%, #bebfc046 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 0.8;
    font-family: Barlows,'Noto Sans';
    color: white;
    width: auto;
    padding: 0.5em;
    text-align: center;
    pointer-events: none;
}

.filter-reset-message {
    margin-left: 15px;
    font-weight: bold;
}
.filter-cancel{
    cursor: pointer;
    padding: 0.25em;
}

@media only screen and (max-width: 1024px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
    #root .MuiTab-root {
        min-width: 4em;
    }
}

@media only screen and (max-width: 950px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
   
}
.MuiPaper-elevation4 {
    box-shadow: none !important;
}
.date-tab-container .MuiTabs-root{
    border: 1px solid #DEDFDF;
}
.MuiTabs-flexContainer .MuiButtonBase-root{
    background: #fff !important;
}
.MuiTab-textColorPrimary{
    color: #000000DE !important;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-rim-list-container{
    margin-top: 7.5em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.manage-rim-action-dropdown{
    color: #0059AC!important;
    border: 1px solid #77ACFF;
    opacity: 0.44;
    width: 70%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500!important;
}
.manage-rim-list-card{
    border-radius: 14px!important;
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .manage-rim-list-container{
      margin-top: 6em
    }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    
    /* .manage-rim-list-container{
        padding: 2em 7.5%;
        width: 85%;
    } */
    .rim-list-header{
        margin-left: 60px !important;
    }
}

.manage-rim-main-page-container{
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.manage-rim-upper-half {
    display: flex;
    position: absolute;
    height: 18em;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.rim-list-header{
    padding: 2em;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
}

.enable-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
  }
  .disabled-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
    color: #dfe0e0;
  }
  .enable-manage-vehicle-action-dropdown {
    color: #0059ac !important;
    border: 1px solid #77acff;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
  }
  
  .disabled-manage-vehicle-action-dropdown {
    color: #dfe0e0 !important;
    border: 1px solid #dfe0e0;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 400 !important;
  }
  
.table-function-title {
    width: 40%;
    text-align: end;
  }

  
.table-function-item {
    color: #0055a4;
    font-family: Barlows,'Noto Sans';
    font-size: 0.7em;
    cursor: pointer;
    background: white;
    border-radius: 0.5em;
    border: 1px solid white;
    height: 2.5em;
  }
  .table-function-item:hover {
    background: #cfdfff !important;
  }

  .table-toolbar-text {
    font-size: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
  }

  .disabled-text {
    color: #c9c9c9;
  }

  .disabled-export-button {
    color: #0055a4 !important;
    font-family: Barlows,'Noto Sans' !important;
    font-size: 0.8em !important;
    cursor: pointer !important;
    background: white !important;
    height: 2.5em !important;
  }

  .export-button {
    color: #0055a4 !important;
    font-family: Barlows,'Noto Sans' !important;
    font-size: 0.7em !important;
    cursor: pointer !important;
    background: white !important;
    /* border-radius: 0.5em !important; */
    height: 2.5em !important;
    /* border: 1px solid #77acff !important; */
  }

  .dropdown-icon-container {
    cursor: pointer;
    display: flex;
  }

  .export-down-arrow {
    padding-left: 0.25em;
  }

  .export-dropdown-content {
    position: absolute;
    background-color: white;
    width: 5em;
    z-index: 3;
    border-radius: 0.5em;
    border: 1px solid lightgray;
    margin-top: 1.8em;
    margin-right: 2em;
  }

  .export-dropdown-content .export-option {
    color: #0055a4;
    padding: 0.5em;
    font-family: Barlows,'Noto Sans';
  }
  
  .export-option:hover {
    background: #b1d2ff;
    cursor: pointer;
  }

  /* LARGE SCREENS */
@media only screen and (max-width: 1100px) and (min-width: 768px) {
    .table-function-item {
      margin: 0 !important;
    }
  }
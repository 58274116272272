/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.scrap-analysis-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.value-lost-dropdown
{
    /* max-width: 12em;
    float: right; */
    margin-left: 1.5em;
}

.table-filters-scrap-analysis{
    display: flex;
    width: 75%;
    margin-bottom: 10px;
}


/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .scrap-analysis-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .scrap-analysis-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */


.scrap-analysis-filter-text {
font-size: 1.3em;
}

.scrap-analysis-filter-icon-img {
    height: 1.1em;
}
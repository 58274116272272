/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-tire-list-container{
    margin-top: 14em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.manage-tire-search-table-container{
    width: 15em;
}
.manage-tire-action-dropdown{
    color: #0059AC!important;
    border: 1px solid #77ACFF;
    opacity: 1;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500!important;
}
.manage-tire-list-card{
    border-radius: 14px!important;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .manage-tire-list-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .manage-tire-list-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
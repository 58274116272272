.removed-tire-detail-container{
    display: flex;
    margin-bottom: 2em;
    margin-top: 0.8em;
}

.parent-account-container{
    margin: 0 0.5em;
}

.tire-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.tire-header-text{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em; 
    color: #666666; 
}
.wrap-container{
    overflow-wrap: break-word;
    width: 50%;

}
.removed-tire-detail-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}

.removed-tire-detail-container .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 13px !important;
}

.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-text{
    margin-left: 9px !important;
}
.standalone-header-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    /* position:absolute; */
}

.create-location-list-container {
    margin-top: 8em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.create-location-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.create-location-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
    /* margin-top: -30px; */
}

.create-location-inner-container {
    margin-left: 28px;
    margin-top: 30px;
    margin-bottom: 15px;
    height: 100%;
    width: 100%;
}

.background-map-image-create-location {
    position: absolute !important;
    background-image: url("../../../assets/map.svg") !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.create-sub-header {
    text-align: left;
    color: #194cab;
    opacity: 1;
    font-family: Barlow;
    font-size: 22px;
    margin-bottom: 10px;
    /* margin-top: 25px; */
}
.edit-details-message{
    font-weight: 400 !important;
    font-style: italic;
    color: #b9b8b8 !important;
}

.create-location-details-grid {
    margin-bottom: 1em;
}

.parent-details-grid {
    margin-bottom: 1em;
}

.field-label {
    margin-block-end: 1px;
}


.edit-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    width: 90%;
    height: 45px;
    align-self: center;
    opacity: 1;
}

.comment-input-box {
    border-radius: 4px;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    height: 45px;
    align-self: center;
    opacity: 1;
    width: 95%;
}

.action-buttons-create-location {
    text-align: right !important;
}

.pressure-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    width: 95%;
    height: 45px;
    /* align-self: center; */
    opacity: 1;
}

/* .create-list-container {
    position: absolute;
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: -43em;
} */


.disabled-input-location-form .MuiInputBase-root.Mui-disabled {
    background: #F5F5F5;
    color: #000000 !important;
    border: none !important;
}

.hr-divider {
    border: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 2%;
}

@media only screen and (max-width: 950px) {
    .create-location-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */

/* @media only screen and (min-width: 1500px) {
    .create-location-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.location-mb{
    margin-bottom: -37px;
}

.MuiInput-underline:before{
    position: unset !important;
}

.parent-name-create-location{
    height: 38px !important;
    
}
#parent-name-create-location{
    font-size: 1rem;
}

.disable-input-location{
    background: #eaeaea 0% 0% no-repeat padding-box;
    border: none !important;
}
#height-css {
  height: 600px;
}
#header-change-modal-multi-selectV {
  width: 30%;
}
.multi-report-inner-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: -moz-available;
}
#select-report {
  color: black !important;
}

.header-change-modal-confirm-btn {
  margin: 7px;
}
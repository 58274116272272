.edit-parent-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.edit-parent-upper-half {
  position: absolute;
  height: 30%;
  width: 100%;
  background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.edit-parent-breadcrumbs {
  text-align: left;
  margin: 2em;
}

.edit-parent-container {
  /* display: flex; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1;
}

.edit-parent-inner-container {
  margin: 20px;
  height: 100%;
  width: 100%;
  padding-top: 15px;
}

.background-map-image-edit-parent {
  position: absolute !important;
  background-image: url("../../../assets/map.svg") !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}

.edit-parent-account-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.account-details-grid {
  margin-bottom: 5em;
}

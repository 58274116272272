.cancel-tire-modal-container {
  border-radius: 0.5em;
  width: 28%;
  background: white;
}

.cancel-modal-title-container {
  display: flex;
  margin-left: 2em;
}

.cancel-modal-title {
  font-size: 1.1em;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 1.5em;
}

.cancel-modal-close-icon-container {
  text-align: end;
}

.modal-action-buttons {
  text-align: right;
  margin-right: 2em;
  margin-bottom: 2em;
}


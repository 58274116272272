.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-body {
    position: relative;
    padding: 15px;
}

.modal-content {
    position: fixed;
    top: 0;
    background-color: #fff ;
    width: 60%;
    left: 20%;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    float: right;
}


/* @import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500&display=swap'); */

.center-popup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom: 1px solid #707070;
}
.multi-select{
  font-size: 1em !important;
    font-family: Barlows,'Noto Sans' !important;
}
.change-modal-container {
  border-radius: 0.5em;
  width: 45%;
  background: white;
  overflow: auto;
  max-height: 100%;
}
.change-modal-title-container {
  display: flex;
  margin-left: 2em;
  margin-top: 0.3em;
}
.change-modal-title {
  font-size: 1.1em;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 0.75em;
}
.pin-icon {
  margin-top: 0.75em;
  margin-right: 0.5em;
}

.modal-close-icon {
  height: 1em;
    width: 1em;
    margin-top: 1em;
    margin-right: 2em;
    cursor: pointer;
}
.change-modal-description {
  color: #545151;
  font: 12px/17px Barlows,'Noto Sans';
  margin: .75em 1em 2em 2em;
}

.modal-button-group {
  display: flex;
  justify-content: flex-end;
  margin: 2em 1em 1em 1em;
}
.change-modal-cancel-btn {
  padding: 0.75em;
  cursor: pointer;
  color: #0055a4;
  background: white;
  margin-right: 0.5em;
  border: 0;
}
.change-modal-cancel-btn:hover{
  color: #0E7CE2;
  background-color:#EAF3FB;
        border-radius: 18px;
        border: none;
        opacity: 1;
        box-shadow: none;
}
.change-modal-cancel-btn:focus{
  color: #0E7CE2
}
.change-modal-cancel-btn:active{
  outline: none
}
.change-modal-proceed-btn {
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #0055a4 0%, #0079e6 100%) 0% 0%
    no-repeat padding-box;
  border: 0;
  opacity: .8;
}
.change-modal-proceed-btn:hover{
  outline: none;
  opacity: 1;
}
.change-modal-proceed-btn:focus{
  background: #1250B0;
  outline: none
}
.change-modal-proceed-btn:active{
  outline: none
}
.change-modal-body-container .modal-subtitle {
  text-align: left;
  font: 1em Barlows,'Noto Sans';
  letter-spacing: 0.12px;
  opacity: 1;
  color: #222222;
  padding-right: 2em;
}

.change-modal-body-container .modal-subtitle span {
  font: 1em Barlows,'Noto Sans';
  letter-spacing: 0.13px;
}

.border-bottom {
  border-bottom: 1px solid #707070;
  opacity: 0.1;
}

.change-modal-body-container {
  display: flex;
  margin: 1.4em 0 0 2em;
}

/* Email text field css */

.form-container {
  margin: 2em 2em 0 2em;
}
.form-container .text-label {
  margin-bottom: 1.4em;
  width: 100%;
}
/* Input text field css */
.MuiInputBase-input {
  margin: 2px !important;
  padding: 8px 0 6px !important;
}
.MuiFormLabel-root.Mui-focused {
  top: 0em !important;
}
.MuiInputLabel-formControl {
  top: -0.6em !important;
  font-size: 0.9rem !important;
}

/* Mobile screen css */

@media only screen and (max-width: 768px) {
  .change-modal-container {
    width: 90%;
    background: white;
  }
  .form-container {
    margin: 1em 2em 0 2em;
  }
}

.button-class {
  left: 10%;
}
.table-show {
  height: 350px;
  overflow: auto;
  margin: 1.4em 1em 0 0;
}
.export-xlsx-button {
  text-decoration: none;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  border: none;
  background: none;
}
.export-csv-link {
  text-decoration: none;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  border: none;
  background: none;
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .change-modal-title{
      font-size: 1.5em;
  }
  .change-modal-description{
    font-weight: 400 !important;
    font-style: italic;
    color: #b9b8b8 !important;
    font-size: 1em;
  }
  
}
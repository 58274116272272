
.active{
    background: #d9d9e2 !important;
}
.search-install-action-buttons{
    text-align: right;
}

.no-data-container {
    padding-top: 5%;
  }
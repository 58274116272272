/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-vehicle-installed-tires-veiw {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.view-vehicle-vehicle-history {
  /* position: absolute; */
  margin-top: -1em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

.quick-inspect-list-card-vehicle {
  border-radius: 14px !important;
}
.axle {
  position: relative;
}
.axle .axleNumber {
  position: absolute;
  top: 20%;
  color: white;
  right: 41%;
}
.tire {
  width: 60px;
  /* flex-wrap:nowrap; */
}

.location-details-grid-view-vehicle {
  margin: 0 2em 2em 1.5em;
  margin-bottom: 2em;
}

/* MID SCREENS */

@media only screen and (max-width: 950px) {
  .view-vehicle-installed-tires-veiw {
    margin-top: 10em;
    width: 95%;
  }

  .view-vehicle-form {
    padding: 2em;
  }
}

/* LARGE SCREENS */

@media only screen and (min-width: 1500px) {
  .view-vehicle-form {
    padding: 2em 7.5%;
  }
  .view-vehicle-installed-tires-veiw {
    width: 85%;
  }
  .view-vehicle-vehicle-history {
    width: 85%;
  }
}

.table-toolbar-label-text-view-vehicle {
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-family: Barlow, Noto Sans !important;
  text-align: left;
  margin: 10px;
  margin-left: 20px;
  font-size: 18px !important;
}

@media only screen and (max-width: 750px) {
  .view-vehicle-form {
    padding: 0.5em;
    display: block;
  }
}
.axleNumber-create-first-view {
  right: 45% !important;
}

.vehicle-img {
  border: 1px solid #707070;
  border-radius: 7px;
  padding: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mar-left {
  margin-left: 20px;
}

.axcel-top-mar {
  margin-top: -20px !important;
}
.field-label-view-vehicle {
  font-weight: 400;
  text-align: left;
  color: #333333;
  font-family: Barlow;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 10px;
  opacity: 1;
}

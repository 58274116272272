
.search-table-container {
    padding-bottom: 10px;
}

.input-label-span {
    font-family: Barlow, Noto Sans !important;
    text-align: center;
    text-decoration: underline;
    font-size: 16px!important;
    letter-spacing: 0px;
    color: #005EB4 !important;
    
}


.table-toolbar-label-text-view-tire{
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-family: Barlow, Noto Sans !important;
    text-align: left;
}

.no-history-found{
    padding-left: 25%;
}

.table-content-text {
    color: #404040 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
}

.no-data-container {
    padding-top: 5%;
  }
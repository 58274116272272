/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-parent-account-list-container {
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.manage-parent-search-table-container {
    width: 10em;
}

.manage-parent-action-dropdown {
    color: #0059AC !important;
    border: 1px solid #77ACFF !important;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}

.manage-parent-account-action-dropdown {
    color: #0059AC !important;
    border: 1px solid #77ACFF !important;
    background: #C4DCFF2E 0% 0% no-repeat padding-box !important;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}

.manage-parent-account-list-card {
    border-radius: 14px !important;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .manage-parent-account-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {

    .manage-parent-account-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */


.enable-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
  }
  .disabled-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
    color: #dfe0e0;
  }
  .enable-manage-vehicle-action-dropdown {
    color: #0059ac !important;
    border: 1px solid #77acff;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
  }
  
  .disabled-manage-vehicle-action-dropdown {
    color: #dfe0e0 !important;
    border: 1px solid #dfe0e0;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 400 !important;
  }
  
  .manage-parent-acct-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 90%;
    position: absolute;
    width: 100%;
}
.delete-vehicle-modal-container {
    border-radius: 0.5em;
    width: 35%;
    background: white;
    overflow: auto;
    max-height: 100%;
}

.center-modal-vehicle{
    outline: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.delete-vehicle-title-container{
    display: flex;
    margin-left: 2em;
}

.delete-vehicle-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em; 
}

.delete-modal-description-vehicle{
    color:#080808;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1em 2.5em 3em 2.5em; 
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
    .delete-vehicle-modal-container{
        width: 85%;
    }
}

@media only screen and (min-width: 1500px) {
    .delete-vehicle-modal-container{
        top: 50%;
    }
}

.vehicle-ids{
    word-break: break-all;
}


.options-alignment{
    display: flex;
}
.padding-one  {
    padding: 0 1em 1em 0;
}
.checkbox-title{
    padding-bottom: 0.5em
}


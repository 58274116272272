
.background-img-container-view-rim{
    margin-right: 1em;
}
.background-map-image-view-rim{
    background-image: url("../../../assets/map.svg") !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important; 
    height: 20em !important;
    width: 100% !important;
}/* LARGE SCREENS */
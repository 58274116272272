  .background-map-image {
    position: absolute;
    background-image: url("../../../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
  }

  .view-parent-list-container{
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom: 4em;
  }
  .success-message-pref {
    background-image: url(/static/media/profile_success.9ddfda34.svg);
    background-color: rgba(157, 255, 161, 0.24);
    background-repeat: no-repeat;
    background-size: 20px 45px;
    border-radius: 28px;
    width: 18%;
    height: 45px;
    color: #048809;
    font-family: Barlow,light, Noto Sans;
    font-size: 14px;
    line-height: 45px;
    text-align: center;
    background-origin: padding-box;
  }

  .sub-edit-textbox {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    height: inherit;
    padding: 0;
    margin: 0;
    font-family:Barlow,light, Sans-serif !important;
    font-size: 14px!important;
  }

  .inputClass{
    display: none;
  }

  .selectedImg{
    margin-right: 10em;
  }

  .view-parent-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.view-parent-upper-half {
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.view-parent-breadcrumbs {
    text-align: left;
    margin: 2em;
}


.view-parent-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.view-parent-inner-container {
    margin: 20px;
    height: 100%;
    width: 100%;
}


.view-parent-account-main-page-container{
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.view-parent-account-account-details-grid{
    margin-bottom: 3em;
}

.view-parent-account-action-buttons {
    text-align: left;
    margin-right: 2em;
}

  
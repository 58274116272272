.removed-tire-record-container{
    display: flex;
    margin: 2em 0;
}

.removed-tire-record-detail{
    background: #C1DDFD !important;
    border: #c8dffb 2px dotted !;
}

.parent-account-container{
    margin: 0 0.5em;
}

.account-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.account-text-record-details{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em;    
    color: rgba(0, 0, 0, 0.589) !important;
}
.record-no{
    padding-left: 2px !important;
}

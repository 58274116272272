
.background-map-image-user-preferences {
  position: absolute !important;
  background-image: url("../../../../src/assets/map.svg") !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}


.button-top-margin {
  margin-top: 2%;
  float: right;
}

.upload-box {
  border: 1px solid rgb(35, 85, 175, 0.22);
  border-radius: 5px;
  width: 70% !important;
  margin-top: 2.5em !important;
  margin-bottom: 8px !important;
  align-items: center;
}

.my-profile-footer-container {
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 1px solid #dedede;
  width: 100%;
}
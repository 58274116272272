
.search-table-container {
    padding-bottom: 10px;
    /* padding-left: 9px; */
}


/* .field-label-inspect {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/40px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal medium 14px/40pxBarlow;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}
*/

.input-label-span {
    font-family: Barlow, Noto Sans !important;
    text-align: center;
    text-decoration: underline;
    font-size: 16px!important;
    letter-spacing: 0px;
    color: #005EB4 !important;
    
}

.no-data-container {
    padding-top: 5%;
  }
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.search-install-tire-list-container {
    /* position: absolute; */
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.field-label-bottem{
    text-align: left;
    color: #000;
    font-weight: 500;
    font-family: Barlow;
    font-size: 1.1em;
    margin-bottom: 10px;
    opacity: 1;
}

.search-install-tire-action-dropdown {
    color: #0059AC !important;
    /* background: #C4DCFF2E 0% 0% no-repeat padding-box; */
    border: 1px solid #77ACFF;
    opacity: 0.44;
    width: 70%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}

.search-install-tire-list-heading {
    font-size: 1.2em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
}

.search-table-container {
    width: 20em;
}

.bottem-inner-container {
    display: flex;
    justify-content: space-around;
    width: 24%;
    align-items: baseline;
}

.search-install-tire-list-card {
    border-radius: 14px !important;
}

.bottem-container {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px solid #E2E2E2;
    justify-content: center;
    height: 7em;
    align-items: center;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .search-install-tire-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {

    .search-install-tire-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.search-install-tires-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 90%;
    position: absolute;
    width: 100%;
}
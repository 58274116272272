
.tire-transfer-modal-container{
    border-radius: 0.5em;
    width: 35%;
    background: white;
}


.modal-close-icon{
    height: 0.75em;
    width: 0.75em;
    margin-top : 0.5em;
    margin-right : 0.75em; 
    cursor: pointer;
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
    .tire-transfer-modal-container{
        width: 85%;
    }
}
.help-sub-header > a{
    color:#1250B0;
    font-weight: bold;
}
.help-sub-header {
    font-size: 16px;
    margin-bottom: 14px;   
}

.help-details-message {
    font-weight: 500 !important;
    color: #1250B0;
    font-size: 20px;
}

.general_help{
    color:#464646
}
.general_help_secondary{
    color:#6b6b6b;
    font-size: 15px;
    margin-right: 5px;
}
.note_help_information{
   color: #393939;
   font-style: italic;
}
.help_videos_title{
    color:#686868;
    font-size: 18px;
    font-weight: bold;
}

.grid_margin_help{
    margin: 50px 0 10px 0;
}
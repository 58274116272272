.message {
    color: rgb(27, 27, 27);
    font-size:12pt;
    /* font-weight:bold; */
    text-align:left;
  }
  
  .errorBubble {
    margin-top: 5%;
    margin-right: 20%;
    margin-left: 20%;
    border: 3px solid #3180ca69;
    border-radius:30px;
    background-color:rgb(250, 250, 250);
    padding:15px;
  }
  .background-image-login-grad {
    background: #00000085 linear-gradient(100deg, #164d97 0%, #062149 100%) 0% 0% no-repeat padding-box;
  }
  .background-image-login {
    position: relative;
    background-image: url("../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
    background-size: cover;
  }
  .header-change-modal-title-error {
    font-size: 1.5em;
    color: #0055a4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.change-modal-select-label-error {
    margin-bottom: 0.5em !important;
    font-size: 1.2em !important;
    color: #222222 !important;
    margin-left: 1.6em !important;
    font-family: Barlows,'Noto Sans';

  }

  .em-track-home-page {
    color: #0055a4;
    font-weight: 600;
    padding: 5px;
  }

  .em-track-home-page:hover {
    /* color: red; */
    background-color: #e9f0f7b6;
    padding: 5px;
  }
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.exclude-used-tires-tire-rotation
{
    margin-bottom:1em;
    margin-top:1em;
    margin-left:7em
}

.display-columns-container-tire-rotation{
    width: 100%;
    max-width: 15em;
    margin-bottom: 1em;
    margin-left: 4em
}
.table-function-title-tire-rotation{
    width : 25%;
    text-align: end;
}

.table-filters-tire-rotation{
    display: flex !important;
    width : 75% !important;
}

.rotation-value{
    margin-left:3em
}

.tire-rotation-update{
    margin-left: 1em;
    margin-top: 1em;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-tire-size-container {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.table-filters-monthly-tire-life {
  display: flex;
  width: 80%;
}
.MuiInput-underline:before {
  width: 0;
  height: 0;
  border-bottom: 0px solid rgba(0, 0, 0, 0.42);
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .by-tire-size-container {
    margin-top: 10em;
  }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
  .by-tire-size-container {
    padding: 0 7.5%;
    width: 85%;
  }
} */

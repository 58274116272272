.parent-no-data-found-div{
    text-align:center
}
.no-data-text-display{
    color: #99bddf;
    font-weight: 400;
}
.try-to-find-display{
    color: #cacaca;
    font-weight: 300;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.quick-inspect-list-container-inspection {
    margin-top: 7.0em;
    padding: 0 3%!important;
    width: 25%!important;
    /* position: fixed; */
    max-height: 100vh;
    display: table-cell;
    margin-bottom: 4em;
    overflow-y: scroll;
    overflow-x: hidden;
    
}
/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .quick-inspect-list-container-inspection{
        padding: 0 2% 0 2.5%!important;
        width: 24%!important;
    }
}
.quick-inspect-list-container-vehicle-inspection {
  
    padding: 7.0em 2.5%;
    width: 76%;
    position: relative;
    display: table-cell;
    margin-bottom: 4em;
}

.quick-inspect-list-container-vehicle {
    /* position: absolute; */
    margin-top: -2.0em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.quick-inspect-list-card {
    border-radius: 14px !important;
}

.quick-inspect-upload-box-description {
    display: inline-flex;
    border: 1px solid rgb(35, 85, 175, 0.22);
    border-radius: 5px;
    width: 50% !important;
    height: 5.5em;
    margin-top: 2.5em !important;
    margin-bottom: 8px !important;
    align-items: center;
}

.upload-label {
    font: normal medium 18px/22px Barlows,'Noto Sans';
    font-weight: bold !important;
    text-align: center;
    letter-spacing: -0.43px;
    color: #333333;
    opacity: 1;
}


/* MID SCREENS */

@media only screen and (max-width: 950px) {
    .quick-inspect-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */

/* @media only screen and (min-width: 1500px) {
    .quick-inspect-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.quick-inspect-main-container {
        background: #f2f2f2 0% 0% no-repeat padding-box;
        opacity: 1;
        height: 90%;
        position: absolute;
        width: 100%;
}
.desktop-view-header {
  display: block;
}
.mobile-view-header {
  display: none;
}
.header-container {
  width: 100%;
  height: 3em;
  background-color: #083475;
  /* border-bottom: 1px solid #ffffff2b; */
}
.header-elements-container {
  display: flex;
  flex-direction: row;
}
.header-title {
  padding: 10px;
  font-size: 20px;
  font-family: Barlows,'Noto Sans';
}
.header-title:hover {
  cursor: pointer;
  color: #ffdc00;
}
.active-header-title {
  color: #ffdc00;
  font-weight: 500;
  background-color: #083475;
  height: 1em;
}
.emtrack-logo {
  padding: 10%;
  /* padding-bottom: 20%; */
  /* margin-left: 1em; */
  /* height: 3em; */
  height: auto;
  /* width: 45%; */
}
.header-logo-container {
  margin-right: 1em;
}
.header-logo {
  height: auto;
  width: 100%;
  padding: 10%;
}

/* ACCOUNT DETAILS TEMPLATE STYLING */

.account-container {
  border-left: 1px solid #ffffff2b;
  /* border-right: 1px solid #ffffff2b; */
  padding: 0.25em;
  display: flex;
  flex-direction: row;
  height: 2.6em;
  overflow-y: hidden;
  overflow-wrap: break-word;
}

.location-container {
  margin: 0 1em;
}

.change-button {
  margin-top: 2px;
  margin-right: .2em;
  color: #ffffff;
  border: 1px solid #083475;
  border-radius: 0.5em;
  background-color: #083475;
  font-size: 0.75em;
  padding: 0.5em;
  cursor: pointer;
  text-align: left;
  height: fit-content
}
.change-button:hover {
  color: #ffdc00 !important;
}

.AssignmentInd-button {
  margin-top: 2px;
  margin-right: .2em;
  color: #ffffff;
  border: 1px solid #083475;
  border-radius: 0.5em;
  background-color: #083475;
  font-size: 0.75em;
  padding: 0.5em;
  cursor: pointer;
  text-align: left;
  height: fit-content
}

.AssignmentInd:hover {
  cursor: pointer;
  color:#ffdc00;
}
.account-header-text {
  padding: 0.1em;
  font-family: Barlows,'Noto Sans';
  font-size: 0.6em;
  color: #b1d1ff9f;
}
.header-account-info-text {
  padding: 0.1em;
  font-family: Barlows,'Noto Sans';
  font-size: 12px;
}
/* .header-account-info-text:hover {
  cursor: pointer;
  color: #ffdc00;
} */

@media only screen and (max-width: 1499px) and (min-width: 951px) {
  .account-header-text {
    font-size: 0.5em !important;
  }
  .header-account-info-text {
    font-size: 10px !important;
  }

  .header-title {
    /* padding: 18%; */
    font-size: 13px;
    font-family: Barlows,'Noto Sans';
  }
}
/*  MANAGE TIRES DROPDOWN */

.manage-dropdown {
  position: relative;
  display: flex;
}

.create-dropdown {
  position: relative;
  display: flex;
}

.manage-dropdown-content {
  position: absolute;
  background-color: white;
  width: 13em;
  border: 1px solid lightgray;
  margin-top: 3em;
  z-index: 9;
}

.manage-dropdown-content .manage-option {
  color: black;
  padding: 1em;
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}

.manage-option:hover {
  color: #1250b0;
  cursor: pointer;
  font-weight: bold;
  font-family: Barlows,'Noto Sans';
}
.dropdown-icon-container {
  cursor: pointer;
  display: flex;
}
.header-title-manage {
  /* padding: 1em 0.5em;
    font-size: 1em; */
  padding: 1em 0.5em;
  font-size: 0.7em;
  font-family: Barlows,'Noto Sans';
}
.header-title-manage:hover {
  cursor: pointer;
  color: #ffdc00;
}

header-title-manage-disabled {
  margin-top: 15%;
  padding: 1em 0.5em;
  font-size: 0.7em;
  font-family: Barlows,'Noto Sans';
}
.active-down-arrow {
  color: #ffdc00;
}

/* USER PROFILE DROPDOWN  */
/* .user-background-clicked {
  background-color: white;
} */
.user-background {
  background-color: transparent;
  /* border-right: 1px solid #ffffff2b;
  border-left: 1px solid #ffffff2b */
}
.user-dropdown {
  position: relative;
  display: flex;
}

.user-dropdown-content {
  position: absolute;
  background-color: white;
  width: 12.58%;
  z-index: 5;
  border: 1px solid lightgray;
  margin-top: 3em;
  top: 0px;
  right: 0px;
  font-size: initial;
}

.user-dropdown-content .user-option {
  color: black;
  padding: 1em;
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}

.user-option:hover {
  color: #1250b0;
  cursor: pointer;
  font-weight: bold;
  font-family: Barlows,'Noto Sans';
}

.user-desktop-view {
  cursor: pointer;
  display: flex;
}

.user-down-arrow {
  height: 33px;
  width: 14px;
}
.user-title {
  font-size: 0.9em;
  /* font-size: 1em; */
  font-family: Barlows,'Noto Sans';
}
.user-role {
  font-size: 0.8em;
  font-family: Barlows,'Noto Sans';
  color: #666666;
}

/* BREAKPOINT FOR HANDLING MOBILE VIEW HEADERS  */

@media only screen and (max-width: 950px) {
  .desktop-view-header {
    display: none;
  }
  .mobile-view-header {
    display: block;
  }
  .mobile-header {
    height: 3em;
    display: flex;
    background-color: #083475;
  }
  .emtrack-logo {
    padding-bottom: 5%;
    padding-top: 10%;

  }
  .header-title-manage {
    margin-right: 0;
    margin-top: 0;
    /* padding: 1em;
        font-size: 1em; */
    padding: 1em 0.5em;
    font-size: 18px;

    font-family: Barlows,'Noto Sans';
  }
  .header-title-manage:hover {
    color: #0a4593;
  }

  .create-dropdown {
    position: relative;
    display: block;
  }

  .create-dropdown-content {
    position: relative;
    width: 100%;
    margin: 0;
    height: 12em;
    padding-left: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
    font-size: 15px;
  }
  .create-option {
    margin: 1em;
    font-size: 15px;
    font-weight: 500;
  }

  .manage-dropdown {
    position: relative;
    display: block;
  }

  .manage-dropdown-content {
    position: relative;
    width: 100%;
    margin: 0;
    height: 16em;
    padding-left: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 400;
    font-size: 14px;
  }
  .manage-option {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  .menu-icon {
    height: 2em;
    width: 2em;
    padding: 0.5em;
  }
  .close-drawer-btn-container {
    height: 3em;
    display: flex;
    justify-content: space-between;
    background-color: #083475;
  }
  .header-close-icon {
    height: 2em;
    width: 2em;
    padding: 0.5em;
  }
  .mobile-header-logo-container {
    margin: auto;
  }
  .header-logo-container {
    background-color: #083475;
    text-align: center;
    margin-right: 0;
  }
  .parent-account-container {
    margin: 0 0.5em;
  }
  .location-container {
    margin: 0 0.5em;
  }
  .account-container {
    display: block;
    padding: 0.5em;
    height: auto;
  }
  .account-header-text {
    padding: 0.2em;
    font-size: 0.6em;
  }
  .header-account-info-text {
    padding: 0.2em;
    font-size: 12px;
  }
  .custom-down-arrow {
    margin-top: 1.4em;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  .active-dropdown-icon-container {
    background: #0a4593;
    color: #ffdc00;
  }
  .user-profile-container {
    padding: 1em;
    margin: auto;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .header-title {
    padding: 0.8em;
    font-size: 18px;
  }
  .active-header-title {
    height: 0.9em;
    color: #ffdc00;
  }
  .emtrack-logo {
    /* margin-left: 2em; */
    padding: 10%;
  }
  .header-logo-container {
    margin-right: 2em;
  }
  .change-button {
    font-size: 0.8em;
  }
  .account-header-text {
    font-size: 0.8em;
  }
  .header-account-info-text {
    font-size: 0.8em;
  }
  .header-title-manage {
    padding: 0.8em 0.5em;
    font-size: 18px;
  }
  header-title-manage-disabled {
    padding: 0.8em 0.5em;
    font-size: 18px;
  }

  .user-title {
    font-size: 0.9em;
  }
  .user-role {
    font-size: 1em;
  }
}


.itemDisabled {
  color:#666666
}
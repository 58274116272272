.admin-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 150%; */
    position: absolute;
    width: 100%;
    /* Footer fix */
    height: 100%
}

.admin-upper-deck{
    position: absolute;
    width: 100%;
    height: 64%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container-admin-view{
    margin-right: 1em;
}
.background-map-image-admin-view{
    background-image: url("../../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .manage-button-navigation-style{
        display: none;
    }
    .admin-nav-container{
        overflow-x: auto!important;
    }
}
.manage-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6em
}
.manage-button-navigation-style:active{
    border-color: none;
}
.manage-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.admin-title{
    color: white;
    font-family: Barlow;
    margin-top: 0.25em;
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.manage-button-admin-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.admin-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.admin-nav-card-container{
    margin-right: 1em;
}
.admin-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.admin-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}
.admin-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-admin-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.admin-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-admin-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .admin-main-container{
        height: 110%;
    }
    .admin-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-admin-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .admin-nav-card-text{
        font-size:1.2em;
    }
    .active-admin-nav-card-text{
        font-size:1.2em;
    }
    .admin-title{
        font-size: 1.4em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .admin-main-container{
        height: 100%;
    }
    .admin-nav-container{
        overflow-x: scroll;
    }
    .manage-button-admin-nav-container{
        overflow-x: scroll;
    }
    .admin-nav-card-container{
        margin-right: 1em;
    }
   
    .admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}



/* SUB CARD */
.manage-button-admin-nav-sub-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.admin-nav-sub-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.admin-nav-card-sub-container{
    margin-right: 1em;
}
.admin-nav-sub-card{
    /* background: rgba(0, 0, 0, 0.2) !important;  */
    /* box-shadow: 0px 3px 6px #00000029 !important; */
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.admin-nav-sub-card-text:hover{
    /* background: rgba(0, 0, 0, 0.4) !important;  */
    color:#ffdc00;
}
.admin-nav-sub-container::-webkit-scrollbar {
    display: none;
  }
.active-admin-nav-sub-card{
    vertical-align: super;
    /* background: rgba(48, 148, 206, 0.459) !important;  */
    /* box-shadow: 0px 3px 6px #00000029 !important; */
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.admin-nav-sub-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:rgba(255, 255, 255, 0.411);
}
.active-admin-nav-sub-card-text{
    vertical-align: super;
    font-size:1em;
    color:#ffdc00;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .admin-main-container{
        height: 110%;
    }
    .admin-nav-sub-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-admin-nav-sub-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .admin-nav-sub-card-text{
        font-size:1.2em;
    }
    .active-admin-nav-sub-card-text{
        font-size:1.2em;
    }
    .admin-title{
        font-size: 1.4em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .admin-main-container{
        height: 100%;
    }
    .admin-nav-sub-container{
        overflow-x: scroll;
    }
    .manage-button-admin-nav-sub-container{
        overflow-x: scroll;
    }
    .admin-nav-sub-card-sub-container{
        margin-right: 1em;
    }
   
    .admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}


.admin-list-card {
    min-height: 350px;
}

.action-buttons {
    padding: 10px;
    text-align: right;
    margin-right: 2em;
}

.hr-divider-action {
    border: 1px solid #e3e3e391;
    margin-top: 20px;
}
.spinner-main-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #0000006b;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0847a1;
}

.circle {
  width: 5px;
  height: 40px;
  background: #0847a1;
  position: absolute;
  border-radius: 1px;
  left: 6px;
  top: 31px;
  animation: pulse 1s infinite ease-out;
}

@keyframes pulse {
  0% {
    left: 6;
    /*     top: -6px; */
  }

  100% {
    left: 90px;
    /*     top: -7px; */
  }
}

/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-parent-account-list-container {
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.view-parent-account-action-dropdown {
    color: #0059AC !important;
    border: 1px solid #77ACFF;
    opacity: 0.44;
    width: 70%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}


    .view-parent-account-location-list-heading {
        font-size: 1.2em;
        font-family: Barlows,'Noto Sans';
        font-weight: 500;
    }



.view-parent-account-list-card {
    border-radius: 14px !important;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .view-parent-account-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {

    .view-parent-account-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.view-parent-acct-list-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 90%;
    position: absolute;
    width: 100%;
  }
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.exclude-used-tires-tireflipping
{
    margin-bottom:1em;
    margin-top:1em;
    margin-left:7em
}

.display-columns-container-tireflipping{
    width: 100%;
    max-width: 15em;
    margin-bottom: 1em;
    margin-left: 4em
}
.table-function-title-tireflipping{
    width : 25%;
    text-align: end;
}

.table-filters-tireflipping{
    display: flex !important;
    width : 75% !important;
}

.flipping-input{
    margin-right:4% !important;
}

.tire-flipping-update{
    margin-left: 1em;
    margin-top: 1em;
}

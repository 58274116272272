.dashboard-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  /* height: 150%; */
  position: absolute;
  width: 100%;
  /* Footer fix */
  height: 100%;
}
.dashboard-upper-deck {
  height: 25em;
  background: transparent linear-gradient(161deg, #0d54b5 0%, #003584 100%) 0%
    0% no-repeat padding-box;
}
.background-img-container {
  margin-right: 1em;
}
.background-map-image {
  background-image: url("../../../../assets/map.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 20em;
  width: 100%;
}
/* Footer fix */
.report-dashboard-container {
  height: 65em;
}
.report-dashboard-container-allok {
  height: 45em;
}
/* DASHBOARD HEADER (SEARCH_BAR & BREADCRUMBS) */

.dashboard-header {
  padding: 2em;
  display: flex;
  justify-content: space-between;
}
.dashboard-title {
  color: white;
  font-family: Barlow;
  font-size: 2em;
}

/* CARD VIEWS  */

.card-views-container {
  padding: 0.5em 2em;
  display: flex;
}
.floating-card {
  margin-right: 1em;
  width: 20%;
}
.card-custom-style {
  background: rgba(0, 0, 0, 0.33) !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  height: 4.5em;
  padding: 1em 0;
  border-radius: 1em !important;
  color: white !important;
}
.inner-card-container {
  display: flex;
}
.floating-card-image {
  margin: 0.25em 0.75em;
}
.floating-card-header {
  font-family: Barlows,'Noto Sans';
  font-size: 0.8em;
  white-space: nowrap;
}
.floating-card-value {
  font-family: Barlows,'Noto Sans';
  font-size: 1.25em;
}

/* DASHBOARD NAV  */

.dashboard-nav-container {
  padding: 1.5em 2em;
  display: flex;
}
.nav-card-custom-style {
  background: rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  height: 3em;
  border-radius: 0.5em !important;
  color: white !important;
  margin-right: 1em;
  /* border: 1px solid #ffbe00 !important; */
  cursor: pointer !important;
}
.nav-card-custom-style:hover {
  background: rgba(0, 0, 0, 0.4) !important; 
}
/* LARGE SCREENS */
@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .nav-card-custom-style:hover {
    background: transparent linear-gradient(288deg, #ffa700 0%, #ffdc00 100%) 0%
      0% no-repeat padding-box !important;
  }
}
.active-nav-card-custom-style {
  background: transparent linear-gradient(288deg, #ffa700 0%, #ffdc00 100%) 0%
    0% no-repeat padding-box !important;
}
.active-nav-card-custom-style:hover {
  background: transparent linear-gradient(288deg, #ffa700 0%, #ffdc00 100%) 0%
    0% no-repeat padding-box !important;
}

.nav-inner-card-container {
  display: flex;
}
.dashboard-nav-text-style {
  font-family: Barlows,'Noto Sans';
  color: #ffffff;
}
.active-dashboard-nav-text-style {
  color: #242424;
  font-family: Barlows,'Noto Sans';
}
.dashboard-nav-title {
  font-size: 1em;
  white-space: nowrap;
}
.dashboard-nav-delimiter {
  font-size: 1em;
  margin-right: 0.5em;
  margin-left: 0.25em;
}
.dashboard-nav-count {
  font-size: 1em;
  font-weight: 500;
}

/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
  .dashboard-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .dashboard-upper-deck {
    height: 35em;
  }
  .dashboard-header {
    padding: 1em;
  }
  .dashboard-title {
    font-size: 0.85em;
  }
  /* SEARCH-BAR */

  .search-bar-input {
    width: 100%;
    /* height: 1.75em; */
  }
  #root .MuiAutocomplete-inputFocused {
    width: 63%;
  }
  .search-btn {
    font-size: 0.75em;
    width: 27%;
  }

  /* CARD VIEW */

  .card-views-container {
    overflow-x: scroll;
    padding: 1em;
  }
  .floating-card {
    margin: auto;
    padding: 0 0.5em;
    width: 75%;
  }
}

/* MOBILE VIEW */
@media only screen and (max-width: 750px) {
  .dashboard-header {
    padding: 0.5em;
    display: block;
  }
  .search-bar-input {
    margin: 1em 0;
  }
  #root .MuiAutocomplete-inputFocused {
    width: 65%;
  }
  .card-views-container {
    padding: 0.5em;
  }

  /* DASHBOARD NAV */
  .dashboard-nav-container {
    overflow-x: scroll;
    padding: 0;
  }
  .nav-card-custom-style {
    margin: 1em;
  }
  #root .MuiCard-root {
    overflow: unset;
  }
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
  .dashboard-main-container {
    /* height: 110%; */
    /* Footer fix */
    height: 60%;
  }
  .card-views-container {
    padding: 0 2.5%;
  }

  .dashboard-nav-container {
    padding: 1.5em 2.5%;
  }
  .dashboard-header {
    padding: 2em 2.5%;
  }
  .floating-card-header {
    font-size: 1.2em;
  }
  .floating-card-value {
    font-size: 1.4em;
  }
  .dashboard-nav-title {
    font-size: 1.25em;
  }
  .dashboard-nav-delimiter {
    font-size: 1.25em;
  }
  .dashboard-nav-count {
    font-size: 1.25em;
  }
  .floating-card-image {
    margin: 0.25em 1em;
  }
  .all-ok-container {
    width: 81% !important;
    margin: 2em 9em !important;
  }
}
/* All OK container */
.all-ok-container {
  background: #f1f2df 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: auto;
  text-align: center;
  margin: 2em;
  border-radius: 1rem 1rem 0 0;
  position: relative;
  padding: 1.5em 2em;
  display: flex;
  flex-direction: row;
  height: 14.5em;
}
.all-ok-title-text {
  font-size: 1.9em;
  font-weight: 700;
  padding: 0.3em;
}
.all-ok-sub-text {
  font-size: 1.3em;
}
.image-all-ok {
  width: 42%;
  margin-top: -4em;
}


.card-arrow {
  display: inline-block !important;
  color: #FFF !important;
  font-size: 0.75em;
  cursor: pointer;
}

.card-custom-style:hover{
  cursor:pointer;
  background: rgba(0, 0, 0, 0.4) !important; 
}
  #vehicle-number{
    color: #0055A4 !important;
    font-weight: 500 !important;
    cursor: pointer;
  }

  #vehicle-number:hover {
    font-weight: 700 !important;
  }

  .action-option:hover {
    background-color: #b1d2ff;
  }

  .no-data-container {
    padding-top: 5%;
}
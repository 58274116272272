.delete-tire-modal-container {
    border-radius: 0.5em;
    width: 35%;
    background: white;
}

@media only screen and (max-width: 640px) {
    .delete-tire-modal-container{
        width: 85%;
    }
}


.my-profile-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 90%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.my-profile-upper-deck {
  position: absolute;
  height: 50%;
  width: 100%;
  background: transparent linear-gradient(161deg, #0d54b5 0%, #003584 100%) 0%
    0% no-repeat padding-box;
}
.background-map-image-profile-details {
  position: absolute !important;
  background-image: url("../../../../assets/map.svg") !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}
.breadcrumb-container{
  margin: 2em;
}
.breadcrumb-title{
  color: white;
  font-family: Barlow,light, Noto Sans;
  font-size: 1.25em;
  margin-top: 10px;
  font-weight: 100;
}
.profile-container {

  /* display: flex;
  margin: 0 2em;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1; */

  margin: 0 7.5%;
  padding: 0 1.5%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
}
.profile-inner-container {
  margin: 20px;
  height: 100%;
  width: 100%;
}
.success-message {
  background-image: url(/static/media/profile_success.9ddfda34.svg);
  background-color: rgba(157, 255, 161, 0.24);
  background-repeat: no-repeat;
  background-size: 20px 45px;
  border-radius: 28px;
  width: 15%;
  height: 45px;
  color: #048809;
  font-family: Barlow,light, Noto Sans;
  font-size: 14px;
  line-height: 45px;
  text-align: center;
  background-origin: padding-box;
}
.bottom-margin {
  margin-bottom: 20px;
}
.top-margin{
    margin-top: 5%;
}
.success-span-message {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  padding: 5px;
}
.edit-view-message {
  text-align: left;
  color: #b9b8b8;
  opacity: 1;
  font-weight: 400;
  font-family: Barlow,light, Noto Sans;
  font-size: 15px;
  letter-spacing: 0.14px;
  font-style: italic;
}

.sub-edit-textbox-myprofile {
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  width: 70%;
  height: inherit;
  padding: 0;
  margin: 0;
  font-family:Barlow,light, Sans-serif !important;
  font-size: 14px !important;
}
.user-profile-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.table-sub-header {
  text-align: left;
  color: #333333;
  font-weight: 500;
  font-family: Barlow,light, Noto Sans;
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 1;
}
.save-table-details {
  font-family: Barlow,light, Noto Sans;
  font-size: 14px;
  color: #666;
  word-wrap: break-word;
}
.edit-save-button{
    background: transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: .8;
    width: 106px;
    height: 36px;
    color: #FFFFFF;
    font-family: Barlow,light, Noto Sans;
    font-size: 14px;
    appearance: none;
    border-color: transparent;
    margin-right: 20px;
}
.edit-save-button:hover{
  opacity: 1;
}
.edit-save-button:active{
    border: none;
}
.edit-save-button:focus{
    border: none;
    outline: none;
}
.cancel-button{
    border: none;
    background: transparent;
    text-align: left;
    letter-spacing: 0px;
    color: #0055A4;
    opacity: .8;
    font-weight: 600;
}
.cancel-button:hover {
  opacity: 1;
  box-shadow: none;
}
.cancel-button:focus{
    border: none;
    outline: none;
}
.hr-divider{
    border: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 10px;
}

.isd-code-dropdown{
  width: 100%;
}

/* MOBILE VIEW */
 @media only screen and (max-width: 700px) {
  .sub-edit-textbox {
    width: -webkit-fill-available
  }
}
.my-profile-footer-container {
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 1px solid #dedede;
  width: 100%;
}

.disabled-field-label{
  text-align: left;
  color: #000;
  font-weight: 500;
  font-family: Barlow;
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 1;
}

.disabled-input {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: none !important;
}
.remove-tire-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.background-map-image {
  position: absolute;
  background-image: url("../../../assets/map.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}

.remove-tire-container {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1;
}

.remove-tire-inner-container {
  margin: 0px 0px 20px 0px;
  height: 100%;
  width: 100%;
}

.all-tire-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-line {
  margin: 0 23px;
}
/* .required-mark {
    color: red;
} */

/* .buttoned-input{
    text-align: center !important;
} */

/* .disabled-input {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: none !important;
} */

.image-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .vehicle-diagram-div {
  transform: rotate(90deg);
} */

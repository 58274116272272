.plus{
    width: 30%;
    min-width: 30%;
    background-color: green ;
    color:aliceblue;
}
.minus{
    width: 30%;
    min-width: 30%;
    color:gray;
}
.digit{
    width: 30%;
    color:black;
    min-width: 30%;
}
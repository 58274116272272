/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-vehicle-list-container {
  margin-top: 8em;
  padding: 0 2.5%;
  width: -webkit-fill-available;
  width: -moz-available;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

.create-vehicle-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.enable-table-toolbar-label-text {
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}
.disabled-table-toolbar-label-text {
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
  color: #dfe0e0;
}
.enable-manage-vehicle-action-dropdown {
  color: #0059ac !important;
  border: 1px solid #77acff;
  width: 60%;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 500 !important;
}

.disabled-manage-vehicle-action-dropdown {
  color: #dfe0e0 !important;
  border: 1px solid #dfe0e0;
  width: 60%;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 400 !important;
}

.manage-vehicle-list-card {
  border-radius: 14px !important;
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .manage-vehicle-list-container {
    margin-top: 0em;
  }
  .list-vehicle-form {
    padding: 1em;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  /* .manage-vehicle-list-container {
    width: 85%;
    padding: 0 7.5%;
  } */
  .vehicle-list-header {
    margin-left: 60px !important;
  }
  /* .list-vehicle-form {
    padding: 2em 7.5%;
  } */
}

.vehicle-list-header {
  padding: 2em;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}
@media only screen and (max-width: 750px) {
  .list-vehicle-form {
    padding: 0.5em;
    display: block;
  }
}

.search-table-container-vehicle {
  width: 15em;
}

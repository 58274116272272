.breadcrumbs-container{
    display: flex;
    margin-bottom : 0.5em;
}
.breadcrumb-path-item{
    color: #ffffff69;
    font-family: Barlows,'Noto Sans'; 
    font-style: italic;
    font-size: 0.75em;
    cursor: pointer;
    /* border-bottom: 1px solid #AAD2FD; */
}
.breadcrumb-path-item:hover{
    color: #FFDC00;
}

.breadcrumb-current-item{
    color: #ffffff69;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    font-style: italic;
}
.breadcrumb-indicator{
    color: #ffffff8a;
    margin: 0.5em;
    font-size: 0.75em;
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .breadcrumb-path-item{
        font-size: 1em;
    }
    .breadcrumb-current-item{
        font-size: 1em;
    }
    .breadcrumb-indicator{
        font-size: 1em;
    }

  }
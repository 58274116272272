@import url("https://fonts.googleapis.com/css?family=Barlow:300,400,500&display=swap");

html,
body {
  min-height: calc(100vh - 0.3em);
  overflow: auto;
  padding: 0 !important;
}
body {
  margin: 0;
  font-family: Barlows,'Noto Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Barlows,'Noto Sans';
}

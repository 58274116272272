.update-button{
    width: 89px;
    height: 36px;
    background: transparent linear-gradient(
90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: .8;
    font-size: 14px;
    font-family: Barlows,'Noto Sans';
    color: #ffffff;
    border: none;
    margin-top: 24px;
}
.update-button:hover {
    opacity: 1;
}
.multi-select-width{
    width: 14em;
}
.search-table-container {
    width: 15em !important;
}
.display-columns-container {
    width: 100%;
    max-width: 15em;
    margin-left: 0.5em;
    margin-right: 1em;
}
.create-tire-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.create-tire-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
}

.create-tire-upper-half {
    display: flex;
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.background-map-image-create-tire {
    position: absolute !important;
    background-image: url("../../../assets/map.svg") !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.create-tire-breadcrumbs {
    text-align: left;
    margin: 2em;
}

.create-tire-inner-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.step-indicator-container {
    /* margin: 0 20px 20px 20px; */
    border-radius: 14px;
    height: 20%;
    width: 100%;
    overflow: auto;
}

.step-indicator {
    display: flex;
    background: #E1EAF5 0% 0% no-repeat padding-box !important;
    border: 1px solid #D0D0D0 !important;
    opacity: 1;
}

.step-form-container {
    display: block !important;
    margin-right: 30%;
    margin-left: 30%;
    margin-bottom: 3em;
    /* text-align: center; */
}

.step-form-inner-container {
    width: 70% !important;
    margin-right: 15% !important;
    margin-left: 15% !important;
}

#abc .MuiAutocomplete-inputFocused {
    color: rgba(0, 0, 0, 0.18) !important;
}

.enter-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18) !important;
    border-radius: 4px !important;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px !important;
    color: black !important;
    height: inherit;
    width: 100%;
    align-self: center;
    opacity: 1;
}

/* .MuiNativeSelect-iconOutlined{
    src: url(../../../assets/pin-icon.svg);
} */

.upload-box-description {
    display: flex;
    border: 1px solid rgb(35, 85, 175, 0.22);
    border-radius: 5px;
    width: 100% !important;
    height: 5.5em;
    margin-top: 2em !important;
    margin-bottom: 8px !important;
    align-items: center;
}

.upload-Button {
    color: #2355AF !important;
    border: 1px solid #2355AF !important;
    border-radius: 25px !important;
    opacity: '1' !important;
    margin: 30px !important;
    margin-left: 13% !important;
}

.uploaded-image {
    padding-left: 10px;
    width: 80%;
    height: 80%;
}

.disabled-input {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: none !important;
}

.hr-divider-action {
    border: 1px solid #e3e3e3;
    margin-top: 20px;
}

.action-buttons {
    padding: 10px;
    text-align: right;
    margin-right: 2em;
}


.field-label-upload {
    font-family: Barlows,'Noto Sans';
    font-style: italic;
    text-align: left;
}

.helper-message {
    margin-right: 15px;
    margin-top: 20px;
    text-align: right;
    font: normal normal normal 12px/20px Barlows,'Noto Sans';
    letter-spacing: -0.29px;
    color: #333333;
}

.tirecosts-popper-desc{
    font: italic normal normal 11px/11px Barlows,'Noto Sans';
    color: #333333;
}

.tirecosts-popper-title{
    font: normal normal medium 10px/11px Barlows,'Noto Sans';
    color: #333333;
}

.MuiStepConnector-alternativeLabel {
    width: max-content !important;
    left: calc(-50% + 190px) !important;
    right: calc(50% + 30px) !important;
}
.central-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
}

.tire-graph-modal-container{
    border-radius: 1em;
    height: max-content;
    width: 50%;
    background: white;
    box-shadow: 0px 3px 6px #00000029;
}

.graph-modal-header{
    display: flex;
}

.graph-modal-title-container{
    display: flex;
    margin-left: 2em;
    width: 100%;
    margin-top : 1.5em;
    flex-direction: column;
    align-items: center;
}

.graph-modal-title{
    width: max-content;
    font-size: 1.1em;
    font-weight: 600;
    color:#292929;
    font-family: Barlows,'Noto Sans';
    margin: 5px 15px 0px 15px;
}

.graph-modal-action-buttons{
    display: inline-flex;
    justify-content: center;
}

.graph-modal-graph-container{
    font-family: Barlows,'Noto Sans';
    height: 350px;
    margin: 2.5em;
    padding: 1em;
}

.modal-close-icon{
    height: 0.75em;
    width: 0.75em;
    margin-top : 0.75em;
    margin-right : 0.75em;
    cursor: pointer;
}
@import url(https://fonts.googleapis.com/css?family=Barlow:300,400,500&display=swap);
html,
body {
  min-height: calc(100vh - 0.3em);
  overflow: auto;
  padding: 0 !important;
}
body {
  margin: 0;
  font-family: Barlows,'Noto Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Barlows,'Noto Sans';
}

.desktop-view-header {
  display: block;
}
.mobile-view-header {
  display: none;
}
.header-container {
  width: 100%;
  height: 3em;
  background-color: #083475;
  /* border-bottom: 1px solid #ffffff2b; */
}
.header-elements-container {
  display: flex;
  flex-direction: row;
}
.header-title {
  padding: 10px;
  font-size: 20px;
  font-family: Barlows,'Noto Sans';
}
.header-title:hover {
  cursor: pointer;
  color: #ffdc00;
}
.active-header-title {
  color: #ffdc00;
  font-weight: 500;
  background-color: #083475;
  height: 1em;
}
.emtrack-logo {
  padding: 10%;
  /* padding-bottom: 20%; */
  /* margin-left: 1em; */
  /* height: 3em; */
  height: auto;
  /* width: 45%; */
}
.header-logo-container {
  margin-right: 1em;
}
.header-logo {
  height: auto;
  width: 100%;
  padding: 10%;
}

/* ACCOUNT DETAILS TEMPLATE STYLING */

.account-container {
  border-left: 1px solid #ffffff2b;
  /* border-right: 1px solid #ffffff2b; */
  padding: 0.25em;
  display: flex;
  flex-direction: row;
  height: 2.6em;
  overflow-y: hidden;
  overflow-wrap: break-word;
}

.location-container {
  margin: 0 1em;
}

.change-button {
  margin-top: 2px;
  margin-right: .2em;
  color: #ffffff;
  border: 1px solid #083475;
  border-radius: 0.5em;
  background-color: #083475;
  font-size: 0.75em;
  padding: 0.5em;
  cursor: pointer;
  text-align: left;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content
}
.change-button:hover {
  color: #ffdc00 !important;
}

.AssignmentInd-button {
  margin-top: 2px;
  margin-right: .2em;
  color: #ffffff;
  border: 1px solid #083475;
  border-radius: 0.5em;
  background-color: #083475;
  font-size: 0.75em;
  padding: 0.5em;
  cursor: pointer;
  text-align: left;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content
}

.AssignmentInd:hover {
  cursor: pointer;
  color:#ffdc00;
}
.account-header-text {
  padding: 0.1em;
  font-family: Barlows,'Noto Sans';
  font-size: 0.6em;
  color: #b1d1ff9f;
}
.header-account-info-text {
  padding: 0.1em;
  font-family: Barlows,'Noto Sans';
  font-size: 12px;
}
/* .header-account-info-text:hover {
  cursor: pointer;
  color: #ffdc00;
} */

@media only screen and (max-width: 1499px) and (min-width: 951px) {
  .account-header-text {
    font-size: 0.5em !important;
  }
  .header-account-info-text {
    font-size: 10px !important;
  }

  .header-title {
    /* padding: 18%; */
    font-size: 13px;
    font-family: Barlows,'Noto Sans';
  }
}
/*  MANAGE TIRES DROPDOWN */

.manage-dropdown {
  position: relative;
  display: flex;
}

.create-dropdown {
  position: relative;
  display: flex;
}

.manage-dropdown-content {
  position: absolute;
  background-color: white;
  width: 13em;
  border: 1px solid lightgray;
  margin-top: 3em;
  z-index: 9;
}

.manage-dropdown-content .manage-option {
  color: black;
  padding: 1em;
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}

.manage-option:hover {
  color: #1250b0;
  cursor: pointer;
  font-weight: bold;
  font-family: Barlows,'Noto Sans';
}
.dropdown-icon-container {
  cursor: pointer;
  display: flex;
}
.header-title-manage {
  /* padding: 1em 0.5em;
    font-size: 1em; */
  padding: 1em 0.5em;
  font-size: 0.7em;
  font-family: Barlows,'Noto Sans';
}
.header-title-manage:hover {
  cursor: pointer;
  color: #ffdc00;
}

header-title-manage-disabled {
  margin-top: 15%;
  padding: 1em 0.5em;
  font-size: 0.7em;
  font-family: Barlows,'Noto Sans';
}
.active-down-arrow {
  color: #ffdc00;
}

/* USER PROFILE DROPDOWN  */
/* .user-background-clicked {
  background-color: white;
} */
.user-background {
  background-color: transparent;
  /* border-right: 1px solid #ffffff2b;
  border-left: 1px solid #ffffff2b */
}
.user-dropdown {
  position: relative;
  display: flex;
}

.user-dropdown-content {
  position: absolute;
  background-color: white;
  width: 12.58%;
  z-index: 5;
  border: 1px solid lightgray;
  margin-top: 3em;
  top: 0px;
  right: 0px;
  font-size: initial;
}

.user-dropdown-content .user-option {
  color: black;
  padding: 1em;
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}

.user-option:hover {
  color: #1250b0;
  cursor: pointer;
  font-weight: bold;
  font-family: Barlows,'Noto Sans';
}

.user-desktop-view {
  cursor: pointer;
  display: flex;
}

.user-down-arrow {
  height: 33px;
  width: 14px;
}
.user-title {
  font-size: 0.9em;
  /* font-size: 1em; */
  font-family: Barlows,'Noto Sans';
}
.user-role {
  font-size: 0.8em;
  font-family: Barlows,'Noto Sans';
  color: #666666;
}

/* BREAKPOINT FOR HANDLING MOBILE VIEW HEADERS  */

@media only screen and (max-width: 950px) {
  .desktop-view-header {
    display: none;
  }
  .mobile-view-header {
    display: block;
  }
  .mobile-header {
    height: 3em;
    display: flex;
    background-color: #083475;
  }
  .emtrack-logo {
    padding-bottom: 5%;
    padding-top: 10%;

  }
  .header-title-manage {
    margin-right: 0;
    margin-top: 0;
    /* padding: 1em;
        font-size: 1em; */
    padding: 1em 0.5em;
    font-size: 18px;

    font-family: Barlows,'Noto Sans';
  }
  .header-title-manage:hover {
    color: #0a4593;
  }

  .create-dropdown {
    position: relative;
    display: block;
  }

  .create-dropdown-content {
    position: relative;
    width: 100%;
    margin: 0;
    height: 12em;
    padding-left: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
    font-size: 15px;
  }
  .create-option {
    margin: 1em;
    font-size: 15px;
    font-weight: 500;
  }

  .manage-dropdown {
    position: relative;
    display: block;
  }

  .manage-dropdown-content {
    position: relative;
    width: 100%;
    margin: 0;
    height: 16em;
    padding-left: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 400;
    font-size: 14px;
  }
  .manage-option {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  .menu-icon {
    height: 2em;
    width: 2em;
    padding: 0.5em;
  }
  .close-drawer-btn-container {
    height: 3em;
    display: flex;
    justify-content: space-between;
    background-color: #083475;
  }
  .header-close-icon {
    height: 2em;
    width: 2em;
    padding: 0.5em;
  }
  .mobile-header-logo-container {
    margin: auto;
  }
  .header-logo-container {
    background-color: #083475;
    text-align: center;
    margin-right: 0;
  }
  .parent-account-container {
    margin: 0 0.5em;
  }
  .location-container {
    margin: 0 0.5em;
  }
  .account-container {
    display: block;
    padding: 0.5em;
    height: auto;
  }
  .account-header-text {
    padding: 0.2em;
    font-size: 0.6em;
  }
  .header-account-info-text {
    padding: 0.2em;
    font-size: 12px;
  }
  .custom-down-arrow {
    margin-top: 1.4em;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  .active-dropdown-icon-container {
    background: #0a4593;
    color: #ffdc00;
  }
  .user-profile-container {
    padding: 1em;
    margin: auto;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .header-title {
    padding: 0.8em;
    font-size: 18px;
  }
  .active-header-title {
    height: 0.9em;
    color: #ffdc00;
  }
  .emtrack-logo {
    /* margin-left: 2em; */
    padding: 10%;
  }
  .header-logo-container {
    margin-right: 2em;
  }
  .change-button {
    font-size: 0.8em;
  }
  .account-header-text {
    font-size: 0.8em;
  }
  .header-account-info-text {
    font-size: 0.8em;
  }
  .header-title-manage {
    padding: 0.8em 0.5em;
    font-size: 18px;
  }
  header-title-manage-disabled {
    padding: 0.8em 0.5em;
    font-size: 18px;
  }

  .user-title {
    font-size: 0.9em;
  }
  .user-role {
    font-size: 1em;
  }
}


.itemDisabled {
  color:#666666
}
.center-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.dropdown-content {
  width: 75% !important;
}
.header-change-modal-container {
  border-radius: 0.5em;
  width: 50%;
  background: white;
}
.header-change-modal-title-container {
  display: flex;
  margin-left: 2em;
}
.header-change-modal-title {
  font-size: 1.1em;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 0.75em;
}
.header-change-modal-title-single {
  border-radius: 0.5em;
  width: 30%;
  background: white;
}

.header-change-modal-title-double {
  border-radius: 0.5em;
  width: 50%;
  background: white;
}
.pin-icon {
  margin-top: 0.75em;
  margin-right: 0.5em;
}
.close-icon-container {
  text-align: end;
}
.modal-close-icon {
  height: 0.75em;
  width: 0.75em;
  margin-top: 0.5em;
  margin-right: 0.75em;
  cursor: pointer;
}
#header-change-modal-multi-select .dropdown-content {
  top: -100px !important;
}
.header-change-modal-description {
  color: #545151;
  font-family: Barlows,'Noto Sans';
  font-size: 0.75em;
  margin-top: 1em;
  margin-left: 2.5em;
}

/* SELECT BOXES STYLING  */
.change-modal-select-container {
  margin: 2em;
}
.select-input {
  width: 100% !important;
  padding: 1em;
}
.change-modal-select-label {
  margin-bottom: 1em !important;
  font-size: 0.8em !important;
  color: #222222 !important;
}
.select-style {
  border: 1px solid lightgray !important;
  border-radius: 0.5em;
  padding: 0.2em;
  margin-top: 1.5em !important;
}

.custom-options-styling:hover {
  background: #77acff !important;
}

/* CUSTOM STYLE FOR REACT-DROPDOWN */

.Dropdown-option:hover {
  background: #77acff !important;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

#header-change-modal-multi-select .multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 3.5em !important; /* Height */
}

/* BUTTON GROUP IN THE END */

.modal-button-group {
  display: flex;
  justify-content: flex-end;
  margin: 1em;
}
.header-change-modal-cancel-btn {
  padding: 0.75em;
  cursor: pointer;
  color: #0055a4;
  background: white;
  margin-right: 0.5em;
  border: 0;
  opacity: 0.8;
  font: Barlows,'Noto Sans';
  font-weight: 600;
  margin-top: 8px;
}
.header-change-modal-cancel-btn:hover {
  background-color: #eaf3fb;
  border-radius: 18px;
  opacity: 1;
  box-shadow: "none";
  color: #0055a4;
}
.header-change-modal-confirm-btn {
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #0055a4 0%, #0079e6 100%) 0% 0% no-repeat padding-box;
  border: 0;
  opacity: 0.8;
  font: Barlows,'Noto Sans';
  box-shadow: "none";
}
.header-change-modal-confirm-btn:hover {
  opacity: 1;
}
.header-change-modal-confirm-btn-disabled {
  background: #bebfc046;
}

.header-edit-latest-record-change-modal-disabled {
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #bebfc046 0%, #bebfc046 100%) 0% 0% no-repeat padding-box;
  border: 0;
  opacity: 0.8;
  font: Barlows,'Noto Sans';
  box-shadow: "none";
  margin-bottom: 12px;
}

@media only screen and (max-width: 768px) {
  .header-change-modal-container {
    width: 90%;
    background: white;
  }
  .header-change-modal-title {
    font-size: 1em;
  }
  .header-change-modal-title-container {
    margin-left: 0.75em;
  }
  .header-change-modal-description {
    margin-left: 0.75em;
  }
  .change-modal-select-container {
    margin: 1em;
  }
  .MuiPopover-paper {
    margin-top: 3.05em !important;
  }
}
/* IPAD SCREENS */
@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .center-modal {
    top: 35%;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .header-change-modal-title {
    font-size: 1.5em;
  }
  .header-change-modal-description {
    font-size: 1.25em;
  }
  .change-modal-select-label {
    font-size: 1.25em !important;
  }
}

.sub-heading1-UHM {
  font-size: 0.87em;
  color: #333333;
  font-family: Barlows,'Noto Sans';
  font-weight: 400; /* medium */
  margin-bottom: 0.6em;
}

.sub-heading2-UHM {
  font-size: 1em;
  color: #333333;
  font-weight: 200; /* regular */
  font-family: Barlows,'Noto Sans';
  margin-bottom: 0.6em;
}
.full-container {
  height: 100%;
}
.datepicker-popup {
  border: 1px solid #aaa !important;
  border-radius: 4px !important;
  height: inherit;
  padding: 0;
  font-weight: 400;
  margin: 0;
  font-family: Barlows,'Noto Sans' !important;
  font-size: 16px !important;
  color: black !important;
  width: 100%;
  align-self: center;
  opacity: 1;
}

.my-profile-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 90%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.my-profile-upper-deck {
  position: absolute;
  height: 50%;
  width: 100%;
  background: transparent linear-gradient(161deg, #0d54b5 0%, #003584 100%) 0%
    0% no-repeat padding-box;
}
.background-map-image-profile-details {
  position: absolute !important;
  background-image: url(/static/media/map.337e1012.svg) !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}
.breadcrumb-container{
  margin: 2em;
}
.breadcrumb-title{
  color: white;
  font-family: Barlow,light, Noto Sans;
  font-size: 1.25em;
  margin-top: 10px;
  font-weight: 100;
}
.profile-container {

  /* display: flex;
  margin: 0 2em;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1; */

  margin: 0 7.5%;
  padding: 0 1.5%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
}
.profile-inner-container {
  margin: 20px;
  height: 100%;
  width: 100%;
}
.success-message {
  background-image: url(/static/media/profile_success.9ddfda34.svg);
  background-color: rgba(157, 255, 161, 0.24);
  background-repeat: no-repeat;
  background-size: 20px 45px;
  border-radius: 28px;
  width: 15%;
  height: 45px;
  color: #048809;
  font-family: Barlow,light, Noto Sans;
  font-size: 14px;
  line-height: 45px;
  text-align: center;
  background-origin: padding-box;
}
.bottom-margin {
  margin-bottom: 20px;
}
.top-margin{
    margin-top: 5%;
}
.success-span-message {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  padding: 5px;
}
.edit-view-message {
  text-align: left;
  color: #b9b8b8;
  opacity: 1;
  font-weight: 400;
  font-family: Barlow,light, Noto Sans;
  font-size: 15px;
  letter-spacing: 0.14px;
  font-style: italic;
}

.sub-edit-textbox-myprofile {
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  width: 70%;
  height: inherit;
  padding: 0;
  margin: 0;
  font-family:Barlow,light, Sans-serif !important;
  font-size: 14px !important;
}
.user-profile-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.table-sub-header {
  text-align: left;
  color: #333333;
  font-weight: 500;
  font-family: Barlow,light, Noto Sans;
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 1;
}
.save-table-details {
  font-family: Barlow,light, Noto Sans;
  font-size: 14px;
  color: #666;
  word-wrap: break-word;
}
.edit-save-button{
    background: transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: .8;
    width: 106px;
    height: 36px;
    color: #FFFFFF;
    font-family: Barlow,light, Noto Sans;
    font-size: 14px;
    -webkit-appearance: none;
            appearance: none;
    border-color: transparent;
    margin-right: 20px;
}
.edit-save-button:hover{
  opacity: 1;
}
.edit-save-button:active{
    border: none;
}
.edit-save-button:focus{
    border: none;
    outline: none;
}
.cancel-button{
    border: none;
    background: transparent;
    text-align: left;
    letter-spacing: 0px;
    color: #0055A4;
    opacity: .8;
    font-weight: 600;
}
.cancel-button:hover {
  opacity: 1;
  box-shadow: none;
}
.cancel-button:focus{
    border: none;
    outline: none;
}
.hr-divider{
    border: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 10px;
}

.isd-code-dropdown{
  width: 100%;
}

/* MOBILE VIEW */
 @media only screen and (max-width: 700px) {
  .sub-edit-textbox {
    width: -webkit-fill-available
  }
}
.my-profile-footer-container {
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 1px solid #dedede;
  width: 100%;
}

.disabled-field-label{
  text-align: left;
  color: #000;
  font-weight: 500;
  font-family: Barlow;
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 1;
}

.disabled-input {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: none !important;
}
.breadcrumbs-container{
    display: flex;
    margin-bottom : 0.5em;
}
.breadcrumb-path-item{
    color: #ffffff69;
    font-family: Barlows,'Noto Sans'; 
    font-style: italic;
    font-size: 0.75em;
    cursor: pointer;
    /* border-bottom: 1px solid #AAD2FD; */
}
.breadcrumb-path-item:hover{
    color: #FFDC00;
}

.breadcrumb-current-item{
    color: #ffffff69;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    font-style: italic;
}
.breadcrumb-indicator{
    color: #ffffff8a;
    margin: 0.5em;
    font-size: 0.75em;
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .breadcrumb-path-item{
        font-size: 1em;
    }
    .breadcrumb-current-item{
        font-size: 1em;
    }
    .breadcrumb-indicator{
        font-size: 1em;
    }

  }
/* Footer fix */
.app-footer-container {
    z-index: 10;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-top: 1px solid #dedede;
    width: 100%;
  }
#root .footerClass{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* position: relative;
    bottom: 0;
    width: 100%;
    overflow: hidden; */
}
 
.footerClass .footerCopyright {
    font: Bold 0.7em Barlows,'Noto Sans';
    letter-spacing: 0px;
    color: #074BA7;
    opacity: 1;
    padding-right: 0.5rem;
}
 
.footerClass .footerLink{
    letter-spacing: 0px;
    color: #074BA7;
    opacity: 1;
    font: 0.7em Barlows,'Noto Sans';
    flex-grow: 1;
}
/* For extra large screens */
@media screen and (min-width: 1900px) {
    .footer-container{
        max-width: 1800px !important;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .footer-container{
        bottom: 0;
        position: fixed;
        background: #ffffff;
    }
}

@media screen and (max-width: 767px) {
    .footer-container{
        bottom: 0;
        position: fixed;
        background: #ffffff;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-vehicle-list-container {
  margin-top: 8em;
  padding: 0 2.5%;
  width: -webkit-fill-available;
  width: -moz-available;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

.create-vehicle-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.enable-table-toolbar-label-text {
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}
.disabled-table-toolbar-label-text {
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
  color: #dfe0e0;
}
.enable-manage-vehicle-action-dropdown {
  color: #0059ac !important;
  border: 1px solid #77acff;
  width: 60%;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 500 !important;
}

.disabled-manage-vehicle-action-dropdown {
  color: #dfe0e0 !important;
  border: 1px solid #dfe0e0;
  width: 60%;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 400 !important;
}

.manage-vehicle-list-card {
  border-radius: 14px !important;
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .manage-vehicle-list-container {
    margin-top: 0em;
  }
  .list-vehicle-form {
    padding: 1em;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  /* .manage-vehicle-list-container {
    width: 85%;
    padding: 0 7.5%;
  } */
  .vehicle-list-header {
    margin-left: 60px !important;
  }
  /* .list-vehicle-form {
    padding: 2em 7.5%;
  } */
}

.vehicle-list-header {
  padding: 2em;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}
@media only screen and (max-width: 750px) {
  .list-vehicle-form {
    padding: 0.5em;
    display: block;
  }
}

.search-table-container-vehicle {
  width: 15em;
}

.table-toolbar {
  display: flex;
  margin-bottom: 1em;
}
.table-filters {
  display: flex;
  width: 60%;
  margin-bottom: 10px;
}
.search-table-container {
  width: 15em;
}
.display-columns-container {
  width: 100%;
  max-width: 15em;
  margin-left: 0.5em;
}
.select-drop-down-container {
  width: 100%;
  max-width: 15em;
  margin-left: 1.25em;
}

.tires-select-container {
  width: 100%;
  max-width: 15em;
  margin-left: 2em;
}

.select-drop-down {
  width: -webkit-fill-available;
}

.filter-table-label {
  color: #000000 !important;
  font-family: Barlow, Noto sans !important;
  font-size: 1em !important;
  margin-bottom: 0.5em;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

.display-columns-style {
  width: 100% !important;
}

.multi-select-display-col {
  height: 0.9em !important;
  z-index: 4 !important;
}

.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 2.2em !important; /* Height */
}


.filter-icon-img {
  height: 0.8em;
  margin-right: 0.2em;
}
.filter-applied-icon-img {
  height: 1em;
  margin-right: 0.2em;
}
.print-icon-img {
  height: 0.8em;
  margin-right: 0.2em;
}
.mail-icon-img {
  height: 0.7em;
  margin-right: 0.2em;
}
.keyword-search-icon-img {
  height: 1.1em;
}

.table-toolbar-label-text {
  font-size: 0.85em;
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}

.disabled-text {
  color: #c9c9c9;
}


.tire-scrap-type {
  max-width: 14.5em;
}

.exclude-used-tires {
  margin-top: 1.2em;
  margin-left: 1em;
}

@media only screen and (max-width: 1100px) {
  .display-columns-container {
    width: 100%;
    max-width: 10em;
    margin-left: 0.5em;
  }
  .select-drop-down-container {
    width: 100%;
    max-width: 100%;
    margin-left: 1.25em;
  }
  .tires-select-container {
    width: 100%;
    max-width: 10em;
    margin-left: 2em;
  }
}



/* MOBILE VIEW  */
@media only screen and (max-width: 600px) {
  .table-toolbar {
    display: block;
  }
  .table-filters {
    display: block;
    width: 100%;
  }
  .search-table-container {
    width: 100%;
  }
  .display-columns-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .select-drop-down-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .tires-select-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .search-table-bar {
    width: 100% !important;
  }
  .table-title {
    margin-bottom: 1em;
    width: 100%;
  }
  .table-function-title {
    width: 100%;
    text-align: start;
    margin-top: 1em;
  }
  .table-function-item {
    margin: 0;
    padding-right: 1.5em;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .table-toolbar-label-text {
    font-size: 1em;
  }
  .table-toolbar-text {
    font-size: 1.3em;
  }
  .print-icon-img {
    height: 1.1em;
    margin-right: 0.5em;
  }
  .mail-icon-img {
    height: 1em;
    margin-right: 0.5em;
  }
  .filter-icon-img {
    height: 1.1em;
    margin-right: 0.5em;
  }
  .filter-applied-icon-img {
    height: 1.2em;
    margin-right: 0.5em;
  }
}

.spinner-main-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 100;
  background: #0000006b;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0847a1;
}

.circle {
  width: 5px;
  height: 40px;
  background: #0847a1;
  position: absolute;
  border-radius: 1px;
  left: 6px;
  top: 31px;
  -webkit-animation: pulse 1s infinite ease-out;
          animation: pulse 1s infinite ease-out;
}

@-webkit-keyframes pulse {
  0% {
    left: 6;
    /*     top: -6px; */
  }

  100% {
    left: 90px;
    /*     top: -7px; */
  }
}

@keyframes pulse {
  0% {
    left: 6;
    /*     top: -6px; */
  }

  100% {
    left: 90px;
    /*     top: -7px; */
  }
}

  #vehicle-number{
    color: #0055A4 !important;
    font-weight: 500 !important;
    cursor: pointer;
  }

  #vehicle-number:hover {
    font-weight: 700 !important;
  }

  .action-option:hover {
    background-color: #b1d2ff;
  }

  .no-data-container {
    padding-top: 5%;
}
.table-head-row {
    color: #2E2E2E !important;
    font-size: 13px !important;
    font-family: Barlow, Noto sans !important;
    font-weight: 500 !important;
    /* white-space: nowrap !important; */
    margin-top: 20px !important;
}

.table-content-text {
    color: #404040 !important;
    font-size: 12px !important;
    font-family: Barlow, Noto sans !important;
    position:unset;
    /* white-space: nowrap !important; */
}
.selected-table-content-text{
    color: white !important;
    font-family: Barlow, Noto sans !important;
}
.table-row-alternate {
    background: #FAFAFB !important
}

.table-row-alternate:hover {
    background: #E4ECF7 !important;
}

.table-bottom-line {
    width: -webkit-max-content;
    width: max-content;
    height: 3px;
    background: #0055A4 !important;
}

.table-row:hover {
    background: #E4ECF7 !important;
}

.MuiSelect-select.MuiSelect-select {
    padding-right: 24px !important;
}

.no-match-found {
    text-align: center !important;
    font-family: Barlow, Noto sans !important;
    font-weight: 500 !important;
}

.MuiTableCell-stickyHeader {
    z-index: 0 !important;
}

.hide-column {
    display: none !important;
}

.total-avg-row {
    border-top: 1px solid #0055A4 !important;
    background-color: #E4ECF7 !important;
}

.total-avg-text {
    color: #222222 !important;
    font-family: Barlow, Noto sans !important;
    /* font-weight: bold !important; */
}

/* LARGE SCREENS */
@media only screen and (min-width: 1600px) {
    .table-head-row {
        font-size: 15px !important;
    }

    /* .table-content-text {
        font-size: 1.1em !important;
    } */
}

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}

.pressure-range-safe{
    background: #ccf2db !important; 
}
.pressure-range-light-warning{
    background: #ffffaf !important;
}
.pressure-range-mild-warning{
    background: #ffe0a9 !important;
}
.pressure-range-warning{
    background: #f3b04a4d !important;
}
.pressure-range-heavy-warning{
    background:  #ffc7c7 !important;
}
.pressure-range-danger{
    background: #ff9488 !important; 
}

.data-summary-table {
    margin-top: 2%;
}
.parent-no-data-found-div{
    text-align:center
}
.no-data-text-display{
    color: #99bddf;
    font-weight: 400;
}
.try-to-find-display{
    color: #cacaca;
    font-weight: 300;
}
.center-modal-vehicle-transfer{
    outline: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); 
}

.vehicle-transfer-modal-container{
    border-radius: 0.5em;
    width: 35%;
    background: white;
    overflow: auto;
    max-height: 100%;
}

.transfer-multiple-selected{
    width: 45% !important;
}
.vehicle-transfer-note{
    font: italic normal normal 12px Barlows,'Noto Sans';
    margin-left: 2.3em;
    color: #747474;
}

.location-cl{
    color: black !important;
}

.change-modal-select-label-alert{
    margin-top: 1 rem !important;
    color: #ff4444 !important;

}
.transfer-modal-title-container{
    display: flex;
    margin-left: 1.8em;
}

.transfer-modal-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em;  
}

.transfer-modal-description{
    color:#060606 !important;
    font-family: Barlow, Noto sans !important;
    font-size: 0.75em;
    margin: 1em 2.5em 1em 2.5em; 
    letter-spacing: 0.13px !important;
    opacity: 1;
}

.modal-select-container{
    margin: 2em;
}

.location-select-input{
    width: 100% !important;
    margin-top: .5em;
    margin-bottom: 1em;
    font-weight: 500 !important;
}


.modal-close-icon-transfer{
    height: 0.75em !important;
    width: 0.75em !important;
    margin-top : 0.5em !important;
    margin-right : 0.75em !important; 
    cursor: pointer;
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

.selected-vehicles{
    word-break: break-all;
}
.delete-vehicle-modal-container {
    border-radius: 0.5em;
    width: 35%;
    background: white;
    overflow: auto;
    max-height: 100%;
}

.center-modal-vehicle{
    outline: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); 
}

.delete-vehicle-title-container{
    display: flex;
    margin-left: 2em;
}

.delete-vehicle-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em; 
}

.delete-modal-description-vehicle{
    color:#080808;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1em 2.5em 3em 2.5em; 
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
    .delete-vehicle-modal-container{
        width: 85%;
    }
}

@media only screen and (min-width: 1500px) {
    .delete-vehicle-modal-container{
        top: 50%;
    }
}

.vehicle-ids{
    word-break: break-all;
}


/* @import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500&display=swap'); */

.center-popup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-bottom: 1px solid #707070;
}
.multi-select{
  font-size: 1em !important;
    font-family: Barlows,'Noto Sans' !important;
}
.change-modal-container {
  border-radius: 0.5em;
  width: 45%;
  background: white;
  overflow: auto;
  max-height: 100%;
}
.change-modal-title-container {
  display: flex;
  margin-left: 2em;
  margin-top: 0.3em;
}
.change-modal-title {
  font-size: 1.1em;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 0.75em;
}
.pin-icon {
  margin-top: 0.75em;
  margin-right: 0.5em;
}

.modal-close-icon {
  height: 1em;
    width: 1em;
    margin-top: 1em;
    margin-right: 2em;
    cursor: pointer;
}
.change-modal-description {
  color: #545151;
  font: 12px/17px Barlows,'Noto Sans';
  margin: .75em 1em 2em 2em;
}

.modal-button-group {
  display: flex;
  justify-content: flex-end;
  margin: 2em 1em 1em 1em;
}
.change-modal-cancel-btn {
  padding: 0.75em;
  cursor: pointer;
  color: #0055a4;
  background: white;
  margin-right: 0.5em;
  border: 0;
}
.change-modal-cancel-btn:hover{
  color: #0E7CE2;
  background-color:#EAF3FB;
        border-radius: 18px;
        border: none;
        opacity: 1;
        box-shadow: none;
}
.change-modal-cancel-btn:focus{
  color: #0E7CE2
}
.change-modal-cancel-btn:active{
  outline: none
}
.change-modal-proceed-btn {
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #0055a4 0%, #0079e6 100%) 0% 0%
    no-repeat padding-box;
  border: 0;
  opacity: .8;
}
.change-modal-proceed-btn:hover{
  outline: none;
  opacity: 1;
}
.change-modal-proceed-btn:focus{
  background: #1250B0;
  outline: none
}
.change-modal-proceed-btn:active{
  outline: none
}
.change-modal-body-container .modal-subtitle {
  text-align: left;
  font: 1em Barlows,'Noto Sans';
  letter-spacing: 0.12px;
  opacity: 1;
  color: #222222;
  padding-right: 2em;
}

.change-modal-body-container .modal-subtitle span {
  font: 1em Barlows,'Noto Sans';
  letter-spacing: 0.13px;
}

.border-bottom {
  border-bottom: 1px solid #707070;
  opacity: 0.1;
}

.change-modal-body-container {
  display: flex;
  margin: 1.4em 0 0 2em;
}

/* Email text field css */

.form-container {
  margin: 2em 2em 0 2em;
}
.form-container .text-label {
  margin-bottom: 1.4em;
  width: 100%;
}
/* Input text field css */
.MuiInputBase-input {
  margin: 2px !important;
  padding: 8px 0 6px !important;
}
.MuiFormLabel-root.Mui-focused {
  top: 0em !important;
}
.MuiInputLabel-formControl {
  top: -0.6em !important;
  font-size: 0.9rem !important;
}

/* Mobile screen css */

@media only screen and (max-width: 768px) {
  .change-modal-container {
    width: 90%;
    background: white;
  }
  .form-container {
    margin: 1em 2em 0 2em;
  }
}

.button-class {
  left: 10%;
}
.table-show {
  height: 350px;
  overflow: auto;
  margin: 1.4em 1em 0 0;
}
.export-xlsx-button {
  text-decoration: none;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  border: none;
  background: none;
}
.export-csv-link {
  text-decoration: none;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  border: none;
  background: none;
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .change-modal-title{
      font-size: 1.5em;
  }
  .change-modal-description{
    font-weight: 400 !important;
    font-style: italic;
    color: #b9b8b8 !important;
    font-size: 1em;
  }
  
}
.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}
.MuiFormHelperText-root {
  color: tomato !important;
}
.warn-text {
  color: #ffe500 !important;
  text-shadow: 0 0 0.6px #000000;
}

.label-color {
  color: #333333;
}

.input {
  width: 100%;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6 !important; /** inherit; */
  /* border-color: #d4d5d6!important; */
  color: #565656;
  -webkit-appearance: none;
}
.MuiChip-root {
  background-color: #66a2d9 !important;
  border-radius: 30px !important;
  height: 30px !important;
  padding: 0 4px 0 1rem !important;
  display: inline-flex !important;
  align-items: center !important;
  /* margin: 0.5rem 0 0.5rem 0.5rem!important; */
  color: white !important;
  font-family: Barlows,'Noto Sans' !important;
  font-size: 1em !important;
}
.MuiChip-deleteIcon {
  color: #fff !important;
}
.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #66a2d9;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.5rem 0.5rem 0;
  color: white;
  font-family: Barlows,'Noto Sans';
  font-size: 1em;
}

.tag-item > .button {
  background-color: #66a2d9;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.div-bottom-margin {
  margin: 0 0 2rem 0;
}
.subject-div-bottom-margin {
  margin: 0 0 0.5rem 0;
}

.table-function-title {
    width: 40%;
    text-align: end;
  }

  
.table-function-item {
    color: #0055a4;
    font-family: Barlows,'Noto Sans';
    font-size: 0.7em;
    cursor: pointer;
    background: white;
    border-radius: 0.5em;
    border: 1px solid white;
    height: 2.5em;
  }
  .table-function-item:hover {
    background: #cfdfff !important;
  }

  .table-toolbar-text {
    font-size: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
  }

  .disabled-text {
    color: #c9c9c9;
  }

  .disabled-export-button {
    color: #0055a4 !important;
    font-family: Barlows,'Noto Sans' !important;
    font-size: 0.8em !important;
    cursor: pointer !important;
    background: white !important;
    height: 2.5em !important;
  }

  .export-button {
    color: #0055a4 !important;
    font-family: Barlows,'Noto Sans' !important;
    font-size: 0.7em !important;
    cursor: pointer !important;
    background: white !important;
    /* border-radius: 0.5em !important; */
    height: 2.5em !important;
    /* border: 1px solid #77acff !important; */
  }

  .dropdown-icon-container {
    cursor: pointer;
    display: flex;
  }

  .export-down-arrow {
    padding-left: 0.25em;
  }

  .export-dropdown-content {
    position: absolute;
    background-color: white;
    width: 5em;
    z-index: 3;
    border-radius: 0.5em;
    border: 1px solid lightgray;
    margin-top: 1.8em;
    margin-right: 2em;
  }

  .export-dropdown-content .export-option {
    color: #0055a4;
    padding: 0.5em;
    font-family: Barlows,'Noto Sans';
  }
  
  .export-option:hover {
    background: #b1d2ff;
    cursor: pointer;
  }

  /* LARGE SCREENS */
@media only screen and (max-width: 1100px) and (min-width: 768px) {
    .table-function-item {
      margin: 0 !important;
    }
  }
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-parent-account-list-container {
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.manage-parent-search-table-container {
    width: 10em;
}

.manage-parent-action-dropdown {
    color: #0059AC !important;
    border: 1px solid #77ACFF !important;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}

.manage-parent-account-action-dropdown {
    color: #0059AC !important;
    border: 1px solid #77ACFF !important;
    background: #C4DCFF2E 0% 0% no-repeat padding-box !important;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}

.manage-parent-account-list-card {
    border-radius: 14px !important;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .manage-parent-account-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {

    .manage-parent-account-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */


.enable-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
  }
  .disabled-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
    color: #dfe0e0;
  }
  .enable-manage-vehicle-action-dropdown {
    color: #0059ac !important;
    border: 1px solid #77acff;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
  }
  
  .disabled-manage-vehicle-action-dropdown {
    color: #dfe0e0 !important;
    border: 1px solid #dfe0e0;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 400 !important;
  }
  
  .manage-parent-acct-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 90%;
    position: absolute;
    width: 100%;
}
.action-dropdown-content {
    position: absolute;
    background-color: white;
    width: 5em;
    z-index: 3;
    border-radius: 0.5em;
    border: 1px solid lightgray;
    margin-top: -30px;
    margin-left:-65px;
  }
  .action-dropdown-content .action-option{
    color: #0055A4;
    cursor: pointer;
    padding: 0.5em;
    font-family: Barlows,'Noto Sans';
  }

  .no-data-container {
    padding-top: 5%;
}
.delete-multiple-selected{
    width: 45% !important;
}
.center-modal-account{
    outline: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); 
}

.delete-account-title-container{
    display: flex;
    margin-left: 1.8em;
}

.delete-account-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em; 
}


.delete-modal-description-account{
    color:#080808;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1em 2.5em 3em 2.5em; 
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
    .delete-account-modal-container{
        width: 85%;
    }
}


/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-location-list-container{
    /* position: absolute; */
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.manage-location-action-dropdown{
    color: #0059AC!important;
    /* background: #C4DCFF2E 0% 0% no-repeat padding-box; */
    border: 1px solid #77ACFF;
    opacity: 0.44;
    width: 70%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500!important;
}
.manage-location-list-card{
    border-radius: 14px!important;
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .manage-location-list-container{
      margin-top: 0em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .manage-location-list-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */


.enable-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
  }
  .disabled-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
    color: #dfe0e0;
  }
  .enable-manage-vehicle-action-dropdown {
    color: #0059ac !important;
    /* background: #C4DCFF2E 0% 0% no-repeat padding-box; */
    border: 1px solid #77acff;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
  }
  
  .disabled-manage-vehicle-action-dropdown {
    color: #dfe0e0 !important;
    /* background: #C4DCFF2E 0% 0% no-repeat padding-box; */
    border: 1px solid #dfe0e0;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 400 !important;
  }

  .manage-location-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 90%;
    position: absolute;
    width: 100%;
}
.no-data-container {
    padding-top: 5%;
}
.desktop-view-reports-nav{
    display:block;
}
.mobile-view-reports-nav{
    display:none;
}
.nav-container{
    background: #0d53b4;
    background-image: linear-gradient(to right, #0d53b4 , #054196);
}
.nav-elements-container{    
    display: flex;
    margin-left: 3em; 
    height: 3em;
    /* border-bottom : 1px solid #FFFFFF2B */
}
.tabs{
    color: rgba(255, 255, 255, 0.411);
    cursor: pointer;
    font-size: 1em;
    margin: 0.75em 1.5em;
    font-family: Barlows,'Noto Sans';
}
.tabs:hover{
    color: #FFDC00;
}
.selected-tab{
    color: #FFDC00;
    opacity: 1;
}

.selected-tab-border{
    /* height: 3.5px; */
    width:80%;    
    /* border-bottom: 4px #FFDC00 solid;  */
    font-weight: 600;
    margin: auto;
}
.viewed-tab-border{
    margin: auto;
    width: 0; 
    height: 0; 
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-bottom: 7.5px solid white;
}

.reports-nav-header-title{
    padding: 1em;
    font-size: 1em;
    font-family: Barlows,'Noto Sans';
}
.reports-nav-header-title:hover{
    cursor: pointer;
    color: #FFDC00;
}
.reports-nav-active-header-title{
    color: #FFDC00;
    background-color: #0A4593;  
    height: 1em;    
}

/* BREAKPOINT FOR HANDLING MOBILE VIEW REPORTS NAV  */

@media only screen and (max-width: 950px) {
    .nav-elements-container{    
       overflow-x: scroll;
       margin-left: 0; 
    }
    .tabs{
        white-space: nowrap;
    }
    /* .reports-nav-header-title{
        white-space: nowrap;
    }
    .reports-nav-active-header-title{
        white-space: nowrap;
    } */
    .desktop-view-reports-nav{
        display:none;
    }
    .mobile-view-reports-nav{
        display:block;
    }
    .drawer-opener-container{
        display: flex;
        /* justify-content: space-between; */
        background:#1250B0;
    }
    .menu-drawer-header-text{
        font-family:Barlows,'Noto Sans';
        padding:1em;
        color:white;
    }
    .menu-icon-container{
        width: 40%;
    }
    .MuiDrawer-paper{
        width: 100% !important;
    }
    .reports-back-header-container{
        margin: 1em;
        display: flex;
    }
    .back-arrow{
        height: 1.55em;
    }   
    .reports-title{
        margin-left: 0.25em; 
        font-size: 1.25em;
        font-family: Barlows,'Noto Sans';
        font-weight: bold;
    }
    
}

@media only screen and (max-width: 450px) {
    .menu-icon-container{
        width: 35%;
    }
}
@media only screen and (min-width: 1500px) {
    .nav-elements-container{    
        margin-left: 6%; 
        height: 3.75em;
    }
    .tabs{
        font-size: 1.25em;
    }
    .reports-title{
        font-size: 1.4em;
    }
}


.overlay {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 5 !important;
    opacity: 1;
 }
 .responsive-tab-grid-container{
     margin: 0 2em !important;
 }
 .title-text{
     font-size : 1.2em;
     font-family: Barlows,'Noto Sans';
     font-weight: bold;
     color: #00346E;
     margin: 1em 0;
     height: 1em;
 }
 .link-text{
    color: #2B2B2B;
    margin: 1em 0;
    font-size: 1em;    
    font-family: Barlows,'Noto Sans';    
 }
 .link-text:hover{
     color: #1250B0;
     font-weight: bold;
     cursor: pointer;
 }
 .sub-header-title-text{
    font-size : 1.2em;
    font-family: Barlows,'Noto Sans';
    font-weight: bold;
    color: #00346E;
    margin-top: 0;
    height: 1em;
 }
 .current-link-text{
    color: #1250B0;
    margin: 1em 0;
    font-size: 1.1em;    
    font-family: Barlows,'Noto Sans';  
    font-weight: 500;
 }
 .survey-template-title-text{
    font-size : 1.2em;
    font-family: Barlows,'Noto Sans';
    font-weight: bold;
    color: #00346E;
    margin-top:1em;
    margin-bottom:1em;
    margin-left:1.5em;
 }
 .template-container{
     margin: 0 2em;
     flex-grow: 0;     /* do not grow   - initial value: 0 */
     flex-shrink: 0;   /* do not shrink - initial value: 1 */
 }
.template-width{
    width: 20%;
}


/* BREAKPOINT FOR HANDLING MOBILE VIEW TAB-VIEWS  */

@media only screen and (max-width: 950px) {
    .responsive-tab-grid-container{
        margin: 0 !important;
    }
    .survey-template-title-text{
        margin:1em 0;
     }
}

/* HANDLING FONT FOR XL SCREENS */
@media only screen and (min-width: 1500px) {
    .title-text{
        font-size : 1.2em;
    }
    .link-text{
       font-size: 1em;    
    }
    .survey-template-title-text{
       font-size : 1.2em;
    }
    .sub-header-title-text {
        font-size: 1em; 
    }
    .responsive-tab-grid-container{
        margin: 0 3em !important;
    }
}

.dashboard-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  /* height: 150%; */
  position: absolute;
  width: 100%;
  /* Footer fix */
  height: 100%;
}
.dashboard-upper-deck {
  height: 25em;
  background: transparent linear-gradient(161deg, #0d54b5 0%, #003584 100%) 0%
    0% no-repeat padding-box;
}
.background-img-container {
  margin-right: 1em;
}
.background-map-image {
  background-image: url(/static/media/map.337e1012.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 20em;
  width: 100%;
}
/* Footer fix */
.report-dashboard-container {
  height: 65em;
}
.report-dashboard-container-allok {
  height: 45em;
}
/* DASHBOARD HEADER (SEARCH_BAR & BREADCRUMBS) */

.dashboard-header {
  padding: 2em;
  display: flex;
  justify-content: space-between;
}
.dashboard-title {
  color: white;
  font-family: Barlow;
  font-size: 2em;
}

/* CARD VIEWS  */

.card-views-container {
  padding: 0.5em 2em;
  display: flex;
}
.floating-card {
  margin-right: 1em;
  width: 20%;
}
.card-custom-style {
  background: rgba(0, 0, 0, 0.33) !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  height: 4.5em;
  padding: 1em 0;
  border-radius: 1em !important;
  color: white !important;
}
.inner-card-container {
  display: flex;
}
.floating-card-image {
  margin: 0.25em 0.75em;
}
.floating-card-header {
  font-family: Barlows,'Noto Sans';
  font-size: 0.8em;
  white-space: nowrap;
}
.floating-card-value {
  font-family: Barlows,'Noto Sans';
  font-size: 1.25em;
}

/* DASHBOARD NAV  */

.dashboard-nav-container {
  padding: 1.5em 2em;
  display: flex;
}
.nav-card-custom-style {
  background: rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  height: 3em;
  border-radius: 0.5em !important;
  color: white !important;
  margin-right: 1em;
  /* border: 1px solid #ffbe00 !important; */
  cursor: pointer !important;
}
.nav-card-custom-style:hover {
  background: rgba(0, 0, 0, 0.4) !important; 
}
/* LARGE SCREENS */
@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .nav-card-custom-style:hover {
    background: transparent linear-gradient(288deg, #ffa700 0%, #ffdc00 100%) 0%
      0% no-repeat padding-box !important;
  }
}
.active-nav-card-custom-style {
  background: transparent linear-gradient(288deg, #ffa700 0%, #ffdc00 100%) 0%
    0% no-repeat padding-box !important;
}
.active-nav-card-custom-style:hover {
  background: transparent linear-gradient(288deg, #ffa700 0%, #ffdc00 100%) 0%
    0% no-repeat padding-box !important;
}

.nav-inner-card-container {
  display: flex;
}
.dashboard-nav-text-style {
  font-family: Barlows,'Noto Sans';
  color: #ffffff;
}
.active-dashboard-nav-text-style {
  color: #242424;
  font-family: Barlows,'Noto Sans';
}
.dashboard-nav-title {
  font-size: 1em;
  white-space: nowrap;
}
.dashboard-nav-delimiter {
  font-size: 1em;
  margin-right: 0.5em;
  margin-left: 0.25em;
}
.dashboard-nav-count {
  font-size: 1em;
  font-weight: 500;
}

/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
  .dashboard-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .dashboard-upper-deck {
    height: 35em;
  }
  .dashboard-header {
    padding: 1em;
  }
  .dashboard-title {
    font-size: 0.85em;
  }
  /* SEARCH-BAR */

  .search-bar-input {
    width: 100%;
    /* height: 1.75em; */
  }
  #root .MuiAutocomplete-inputFocused {
    width: 63%;
  }
  .search-btn {
    font-size: 0.75em;
    width: 27%;
  }

  /* CARD VIEW */

  .card-views-container {
    overflow-x: scroll;
    padding: 1em;
  }
  .floating-card {
    margin: auto;
    padding: 0 0.5em;
    width: 75%;
  }
}

/* MOBILE VIEW */
@media only screen and (max-width: 750px) {
  .dashboard-header {
    padding: 0.5em;
    display: block;
  }
  .search-bar-input {
    margin: 1em 0;
  }
  #root .MuiAutocomplete-inputFocused {
    width: 65%;
  }
  .card-views-container {
    padding: 0.5em;
  }

  /* DASHBOARD NAV */
  .dashboard-nav-container {
    overflow-x: scroll;
    padding: 0;
  }
  .nav-card-custom-style {
    margin: 1em;
  }
  #root .MuiCard-root {
    overflow: unset;
  }
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
  .dashboard-main-container {
    /* height: 110%; */
    /* Footer fix */
    height: 60%;
  }
  .card-views-container {
    padding: 0 2.5%;
  }

  .dashboard-nav-container {
    padding: 1.5em 2.5%;
  }
  .dashboard-header {
    padding: 2em 2.5%;
  }
  .floating-card-header {
    font-size: 1.2em;
  }
  .floating-card-value {
    font-size: 1.4em;
  }
  .dashboard-nav-title {
    font-size: 1.25em;
  }
  .dashboard-nav-delimiter {
    font-size: 1.25em;
  }
  .dashboard-nav-count {
    font-size: 1.25em;
  }
  .floating-card-image {
    margin: 0.25em 1em;
  }
  .all-ok-container {
    width: 81% !important;
    margin: 2em 9em !important;
  }
}
/* All OK container */
.all-ok-container {
  background: #f1f2df 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: auto;
  text-align: center;
  margin: 2em;
  border-radius: 1rem 1rem 0 0;
  position: relative;
  padding: 1.5em 2em;
  display: flex;
  flex-direction: row;
  height: 14.5em;
}
.all-ok-title-text {
  font-size: 1.9em;
  font-weight: 700;
  padding: 0.3em;
}
.all-ok-sub-text {
  font-size: 1.3em;
}
.image-all-ok {
  width: 42%;
  margin-top: -4em;
}


.card-arrow {
  display: inline-block !important;
  color: #FFF !important;
  font-size: 0.75em;
  cursor: pointer;
}

.card-custom-style:hover{
  cursor:pointer;
  background: rgba(0, 0, 0, 0.4) !important; 
}
.dashboard-graph-table-container{
    display:flex;
    padding: 0 1em;
}

.graph-container{
    margin-right: 1em; 
    width: 30%;
}
.table-container{
    width: 70%;
}

@media only screen and (max-width: 1100px) {
    .display-columns-container{
            width: 100%;
            max-width: 10em;
            margin-left: 0.5em;
        }
}

/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
    .dashboard-graph-table-container{
        display:block;
        padding: 0 1em;
    }
    
    .graph-container{
        margin:1em 0; 
        width: 100%;
    }
    .table-container{
        margin: 1em 0; 
        width: 100%;
    }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    
    .dashboard-graph-table-container{
        padding: 0 2.5%;
    }
  
}


.worn-range-highest{
    background-color: #FFC7C7 !important;
}

.no-data-container {
    padding-top: 5%;
  }

.locations-text{
  margin-right: 0.5em;
  font-family: Barlows,'Noto Sans';
  color: #0055a4 !important;
  font-size: 0.75em;
  cursor: pointer;
  background-color: #cfdfff;
  border: #0055a4;
  border-radius: 5px;
}
.locations-text-strike{
  margin-right: 0.5em;
  font-family: Barlows,'Noto Sans';
  color: #0055a4 !important;
  font-size: 0.75em;
  cursor: pointer;
  text-decoration: line-through;
  background-color: #cfdfff;
  opacity: 50%;
  border: #0055a4;
  border-radius: 5px;
}

.locations-text:hover {
  color: whitesmoke !important;
}
.locations-text-strike:hover {
  margin-right: 0.5em;
  font-family: Barlows,'Noto Sans';
  color: #01284d !important;
  font-size: 0.75em;
  cursor: pointer;
  background-color: #7aaefccb;
  border: #0055a4;
  border-radius: 5px;
}
.no-data-container {
    padding-top: 5%;
  }
.toggled {
  color: #fff;
}
.locations-container {
  display: flex;
}
.locations-text {
  margin-right: 0.5em;
  font-family: Barlows,'Noto Sans';
  color: #0055a4 !important;
  font-size: 0.75em;
  cursor: pointer;
  background-color: transparent;
  /* border: 1px solid #cfdfff;
  border-radius: 5px;
  padding: 5px; */
}
.locations-text-strike {
  margin-right: 0.5em;
  font-family: Barlows,'Noto Sans';
  color: #0055a4 !important;
  font-size: 0.75em;
  cursor: pointer;
  text-decoration: line-through;
  background-color: #cfdfff;
  opacity: 0.5 !important;
  border: #0055a4;
  border-radius: 5px;
  padding: 5px;
}

.locations-text:hover {
  color: #0055a4 !important;
  background-color: #cfdfff;
}

.locations-text-strike:hover {
  margin-right: 0.5em;
  font-family: Barlows,'Noto Sans';
  color: #01284d !important;
  font-size: 0.75em;
  cursor: pointer;
  background-color: #7aaefccb;
  border: #0055a4;
  border-radius: 5px;
  padding: 5px;
}

@media only screen and (min-width: 1500px) {
  .locations-text {
    font-size: 1.25em;
  }
  .locations-text-strike {
    font-size: 1.25em;
  }
}

.no-data-container {
  padding-top: 5%;
}

.locations-container{
    display: flex;
  }
  .locations-text{
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #0055a4 !important;
    font-size: 0.75em;
    cursor: pointer;
    background-color: transparent;
    /* border: 1px solid #cfdfff;
    border-radius: 5px;
    padding: 5px; */
  }
  .locations-text-strike{
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #0055a4 !important;
    font-size: 0.75em;
    cursor: pointer;
    text-decoration: line-through;
    background-color: #cfdfff;
    opacity: 50%;
    border: #0055a4;
    border-radius: 5px;
    padding: 5px;
  }
  
  .locations-text:hover {
    color: #0055a4 !important;
    background-color: #cfdfff;
  }

  .locations-text-strike:hover {
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #01284d !important;
    font-size: 0.75em;
    cursor: pointer;
    background-color: #7aaefccb;
    border: #0055a4;
    border-radius: 5px;
  }
  .normal-tread-depth{
    display: inline-block;
    margin-right: 0.5em;
    height: 10px;
    width: 25px;
    background-color: rgb(255 178 178);
  }
  .low-tread-depth{
    display: inline-block;
    margin-right: 0.5em;
    height: 10px;
    width: 25px;
    background-color: rgb(213 245 250);
  }
  .container-normal{
    float: left;
    font-size: 0.6em;
    padding-left: 15em
  }
  .container-low{
    float: right;
    font-size: 0.6em;
  }
  @media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .container-normal{
      float: left;
      font-size: 0.4em;
      padding-left: 15em
    }
    .container-low{
      float: right;
      font-size: 0.4em;
    }
}
  .chart-title-info{
    padding: 1em 1em;
    font-size: 1em;
    font-weight: 700;
    color: #000000;
    text-align: center;
  }
  
  .no-data-container {
    padding-top: 5%;
  }
.locations-container{
    display: flex;
  }
  .locations-text{
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #0055a4 !important;
    font-size: 0.75em;
    cursor: pointer;
    background-color: transparent;
    /* border: 1px solid #cfdfff;
    border-radius: 5px;
    padding: 5px; */
  }
  .locations-text-strike{
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #0055a4 !important;
    font-size: 0.75em;
    cursor: pointer;
    text-decoration: line-through;
    background-color: #cfdfff;
    opacity: 50%;
    border: #0055a4;
    border-radius: 5px;
    padding: 5px;
  }
  
  .locations-text:hover {
    color: #0055a4 !important;
    background-color: #cfdfff;
  }

  .locations-text-strike:hover {
    margin-right: 0.5em;
    font-family: Barlows,'Noto Sans';
    color: #01284d !important;
    font-size: 0.75em;
    cursor: pointer;
    background-color: #7aaefccb;
    border: #0055a4;
    border-radius: 5px;
    padding: 5px;
  }
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.scrap-summary-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .scrap-summary-container{
      margin-top: 10em
    }

}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .scrap-summary-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
.scrap-tires-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 150%; */
    position: absolute;
    width: 100%;
    /* Footer fix */
    height: 100%
}

.scrap-tires-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .scrap-button-navigation-style{
        display: none;
    }
    .scrap-tires-nav-container{
        overflow-x: auto!important;
    }
}
.scrap-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6.3em;
    margin-top:7px;
    height: 45px;
}
.scrap-button-navigation-style:active{
    border-color: none;
}
.scrap-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.scrap-tires-title{
    color: white;
    font-family: Barlow;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.scrap-button-scrap-tires-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.scrap-tires-nav-container{
    display: flex;
    overflow-x: hidden;
}
.scrap-tires-nav-card-container{
    margin-right: 1em;
}
.scrap-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.scrap-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}
.scrap-tires-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-scrap-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.scrap-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-scrap-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .scrap-tires-main-container{
        height: 110%;
    }
    .scrap-tires-nav-container{
        display: flex;
        overflow-x: hidden;
        margin: 0 0.5%;
    }
    .scrap-button-scrap-tires-nav-container{
        display: flex;
        margin: 0 2.5%;
        overflow-x: hidden;
    }
    .scrap-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-scrap-tires-nav-card-text{
        font-size:1.2em;
    }
    .scrap-tires-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .scrap-tires-main-container{
        height: 100%;
    }
    .scrap-tires-nav-container{
        overflow-x: scroll;
    }
    .scrap-button-scrap-tires-nav-container{
        overflow-x: scroll;
    }
    .scrap-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-scrap-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.removal-reason-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.graph-card{
    /* border-bottom: 1px solid #00000029; */
    padding: 2em;
}
#root .table-card{
    padding-top : 2em;
}


/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
    .removal-reason-container{
        margin-top: 10em;
    }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    .content-container{
        padding: 0 12.5%;
        width: 75%;
    }
   
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.by-compound-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-compound-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .by-compound-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.by-individual-tire-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-individual-tire-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .by-individual-tire-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */

/* #tire-history-table .MuiTableCell-head:first-child{
    background: yellow;
    position: fixed;
    padding: 7px 7px;
    padding-left: 0;
} */
.dropdown-content{
    z-index: 5!important;
}
.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: white;
}
.first-col {
    left: 0!important;
  }
.second-col{
    left: 6.5%!important;
}
.third-col{
   
    left: 12%!important;
}
.fourth-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 16%!important;
}

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.scrap-analysis-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.value-lost-dropdown
{
    /* max-width: 12em;
    float: right; */
    margin-left: 1.5em;
}

.table-filters-scrap-analysis{
    display: flex;
    width: 75%;
    margin-bottom: 10px;
}


/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .scrap-analysis-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .scrap-analysis-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */


.scrap-analysis-filter-text {
font-size: 1.3em;
}

.scrap-analysis-filter-icon-img {
    height: 1.1em;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.tire-installation-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .tire-installation-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .tire-installation-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
.tires-service-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 150%;
    position: absolute;
    width: 100%;
}

.tires-service-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .button-navigation-style{
        display: none;
    }
    .tires-service-nav-container{
        overflow-x: auto!important;
    }
}
.button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    /* opacity: 0.9; */
    width: 10em;
    margin-top:7px;
    height: 45px;
}
.button-navigation-style:active{
    border-color: none;
}
.button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.tires-service-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.tires-service-title{
    color: white;
    font-family: Barlow;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.button-tires-service-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.tires-service-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.tires-service-nav-card-container{
    margin-right: 1em;
}
.tires-service-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.tires-service-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}
.tires-service-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-tires-service-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.tires-service-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-tires-service-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .tires-service-main-container{
        height: 110%;
    }
    .tires-service-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .button-tires-service-nav-container{
        display: flex;
        margin: 0 3%;
        overflow-x: hidden;
    }
    .tires-service-nav-card-text{
        font-size:1.2em;
    }
    .active-tires-service-nav-card-text{
        font-size:1.2em;
    }
    .tires-service-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .tires-service-main-container{
        height: 150%;
    }
    .tires-service-nav-container{
        overflow-x: scroll;
    }
    .button-tires-service-nav-container{
        overflow-x: scroll;
    }
    .tires-service-nav-card-container{
        margin-right: 1em;
    }
   
    .tires-service-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-tires-service-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .tires-service-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-tires-service-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
.install-number-select
{
    padding-left: 0.4em !important;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.tire-history-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .tire-history-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .tire-history-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
.newLine{

    border-bottom: 1px solid #000 !important;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.vehicle-reports-upper-deck{
    position: absolute;
    width: 100%;
    height: 40%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* VEHICLE TIRE HEADER (BREADCRUMBS & TITLE) */

.vehicle-reports-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.vehicle-reports-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* VEHICLE TIRE NAVIGATION  */

.vehicle-time-nav-container{
    display: flex;
    padding: 0 2.5%;
}

.vehicle-reports-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}

.vehicle-reports-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-vehicle-reports-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}
.vehicle-reports-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
    white-space: nowrap;
}
.active-vehicle-reports-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
    white-space: nowrap;
}
.scrap-time-nav-container{
    display: flex;
    padding: 0 2.5%;
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .vehicle-time-nav-container{
        display: flex;
        padding: 0 7.5%;
    }
    .vehicle-reports-nav-card-text{
        font-size:1.2em;
    }
    .active-vehicle-reports-nav-card-text{
        font-size:1.2em;
    }
    .vehicle-reports-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .vehicle-time-nav-container{
        overflow-x: scroll
    }
    .vehicle-reports-nav-card-container{
    margin-right: 1em;
    }
    .vehicle-reports-upper-deck{
        height: 60%;
    }
    .vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
   
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .vehicle-reports-upper-deck{
        height: 60%;
    }
    .vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-vehicle-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }

}

/* @media only screen and (min-width: 1500px)
{
    .scrap-time-nav-container {
        display: flex;
        margin: 0 2.5%;
        overflow-x: auto;
    }
} */
.vehicle-container{
    padding-top: 0;
    padding-bottom: 2em;
    padding-left: 0;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 25px;
}
.grid-vehicle-container{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;
    padding: 1em;
    margin:0;
}
.vehicle-details-container {
    font-family: Barlows,'Noto Sans';
    font-size: 14px;
}
.grid-data-container{
    padding-left: 2em;
}
.padding-2em{
    padding-top: 2em
}
.padding-1em{
    padding-top: 1em
}
.heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.4em;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding: 0.7em 0;
    font-weight: 500;
}
.heading-column-data-info{
    font-weight: 300;
    font-size: 14px;
}

.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: white;
}
.first-fleet-col {
    left: 0!important;
  }
.second-fleet-col{
    left: 6.5%!important;
}
.third-fleet-col{
   
    left: 16%!important;
}
.fourth-fleet-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 21%!important;
}
.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}

.table-content-fleet-text {
    color: #404040 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
}
.axle-image{
    opacity: 1;
    border-left: 6px solid #353A94;
    height: 100px;
    margin-left: 31px;
}
.display-inline-block{
    margin-top: -0.8em;
    padding: 0.5em;
    display: inline-block;
}
.display-inline{
    display: inline-block
}
.tire-table{
    margin-top: 3em;
}
.tire-axle-number{
    margin-top: -0.8em;
    margin-bottom: -0.5em;
}
.empty-tire-fleet-survey{
    width: 64px;
}
.axle-0{
    padding-left: 1.2em;
}
.axle-1{
    padding-left: 1.7em;
}
.axle-2{
    padding-left: 1.9em;
}
.axle-3{
    padding-left: 2em; 
}
.axle-4{
    padding-left: 1.5em;
}
.axle-5{
    padding-left: 1.9em;
}
.axle-6{
    padding-left: 1.8em;
}
.axle-7{
    padding-left: 1.7em; 
}
.axle-8{
    padding-left: 1.8em;
}

.no-tire{
    margin-left: 4em
}
.display-block{
    display: block
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* Footer fix */
/* .fleet-data-container{
    min-height: 550em
} */

/* #menu-tire .MuiPopover-root{
    position: absolute !important
} */
.vehicle-container{
    background-color: #e2ecf841;
    margin-bottom: 25px;
    padding-left: 0;
    margin-top: 0;
    margin-left: 0;
}
.desktop-view-fleet-data{
    display: block;
    padding-left: 12px;
    padding-right: 12px;
}
.mobile-view-fleet-data{
    display: none;
}
.grid-vehicle-container{
    background: #c1ddfd98;
    border: #c8dffb 2px dotted;
    padding: 1em;
    font-family: Barlows,'Noto Sans';
    margin: 0;
}
.fleet-data-analysis-template{
    border-bottom: 1px solid #d3d3d338;
    padding: 2em 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 300;
    font-size: small;
}
.fleet-data-vehicle-template{
    border-bottom: 1px solid lightgrey;

    font-family: Barlows,'Noto Sans';
    
}
.left-spacing{
    margin-left: 2em;
}
.fleet-data-survey-template{
    margin-top: 0.5em;
}

.heading-column-data{
    color: #c8dffb;
    opacity: 1;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.heading-column-data-header {
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.heading-column-data-info {
    color: #333333;
    opacity: 1;
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.grid-vehicle-container-heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 0.4em;
    text-align: justify;
}
.xl-heading-column-data{
    color: #9dc7fa;
    opacity: 1;
    font-weight: 500;
    font-size: 1.5em;
}
.small-heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 0.9em;
}
.end-align{
    text-align: end;
}
.underline-style{
    opacity: 1;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding-top: 15px;
    font-weight: 500;
}

.outer-spacing{
    padding: 1.75em
}
.inner-spacing{
    padding: 0 0 0 1.75em;
}
.fleet-data-text{
    color : #202020;
    font-weight: 300;
    font-size: 13px;
    margin-left: 5px;
}

@media only screen and (max-width: 600px) {
    .desktop-view-fleet-data{
        display: none;
    }
    .mobile-view-fleet-data{
        display: block;
    }
    .left-spacing{
        margin-left: 0em;
    }
    .fleet-data-text{
        font-size: 0.9em;
        margin-left: 0em;
    }
  }

  @media only screen and (min-width: 1800px) {
    .vehicle-header-info{
        font-size: 1.5em;
    }
    .heading-column-data{
        font-size: 1.5em;
    }
    .xl-heading-column-data{
        color: #9dc7fa;
        font-size: 2em;
    }
    .small-heading-column-data{
        font-size: 1.25em;
    }
    .fleet-data-text{
        font-size: 1.1em;
    }
  }

.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.margin-top-2em{
    margin-top: 2em;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.exclude-used-tires-tire-forecast
{
    margin-bottom:1em;
    margin-top:1em
}

.display-columns-container-tire-forecast{
    width: 100%;
    max-width: 13em;
    margin-bottom: 1em;
}
.table-function-title-tire-forecast{
    width : 25%;
    text-align: end;
}

.table-filters-tire-forecast{
    display: flex;
    width : 75%;
}

.tire-forecast-update{
    margin-left:30%
}

.update-button{
    width: 89px;
    height: 36px;
    background: transparent linear-gradient(
90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: .8;
    font-size: 14px;
    font-family: Barlows,'Noto Sans';
    color: #ffffff;
    border: none;
    margin-top: 24px;
}
.update-button:hover {
    opacity: 1;
}
.vehicle-container{
    padding-top: 0.8em;
    padding-bottom: 2em
}
.forecast-vehicle-container{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;

}
.grid-data-container{
    padding-left: 2em;
}
.padding-2em{
    padding-top: 2em
}
.padding-1em{
    padding-top: 1em
}
.heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 1em;
    padding-bottom: 0.4em;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding: 0.7em 0;
    font-weight: 500;
}
.font-wt-500{
    font-weight: 500;
}

.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: white;
}
.first-tire-col {
    left: 0!important;
  }
.second-tire-col{
    left: 6%!important;
}
.third-tire-col{
   
    left: 12%!important;
}
.fourth-tire-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 19%!important;
}
.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
.table-content-fleet-text {
    color: #404040 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
}
.axle-image{
    opacity: 1;
    border-left: 6px solid #353A94;
    height: 100px;
    margin-left: 31px;
}
.display-inline-block{
    margin-top: -0.8em;
    padding: 0.5em;
    display: inline-block;
}
.display-inline{
    display: inline-block
}
.tire-table{
    margin-top: 3em;
}
.tire-axle-number{
    margin-top: -0.5em;
    margin-bottom: -0.7em;
}
.empty-tire{
    width: 64px;
}
.axle-0{
    padding-left: 1.2em;
}
.axle-1{
    padding-left: 1.7em;
}
.axle-2{
    padding-left: 1.9em;
}
.axle-3{
    padding-left: 2em; 
}

.axle-4{
    padding-left: 1.5em;
}
.axle-5{
    padding-left: 1.9em;
}
.axle-6{
    padding-left: 1.8em;
}
.axle-7{
    padding-left: 1.7em; 
}
.axle-8{
    padding-left: 1.8em;
}
.other-tire-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 150%;
    position: absolute;
    width: 100%;
}

.other-tire-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* TIRES IN SERVICE HEADER (BREADCRUMBS & TITLE) */

.other-tire-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.other-tire-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */

.other-tire-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: auto;
}

.other-tire-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.other-tire-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-other-tire-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.other-tire-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-other-tire-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .other-tire-main-container{
        height: 110%;
    }
    .other-tire-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: auto;
    }
    .other-tire-nav-card-text{
        font-size:1.2em;
    }
    .active-other-tire-nav-card-text{
        font-size:1.2em;
    }
    .other-tire-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .other-tire-main-container{
        height: 150%;
    }
    .other-tire-nav-container{
        overflow-x: scroll;
    }
    .other-tire-nav-card-container{
        margin-right: 1em;
    }
   
    .other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tire-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.in-service-tire-cost-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .in-service-tire-cost-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .in-service-tire-cost-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */

@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-wear-condition-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-wear-condition-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-wear-condition-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.other-tires-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 150%;
    position: absolute;
    width: 100%;
}

.other-tires-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* TIRES IN SERVICE HEADER (BREADCRUMBS & TITLE) */

.other-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.other-tires-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */

.other-tires-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.other-tires-nav-card-container{
    margin-right: 1em;
}
.other-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.other-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-other-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.other-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-other-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}
.other-button-other-tires-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}


.other-tires-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 4.3em;
    margin-top:7px;
    height: 45px;
}

.other-tires-button-navigation-style:active{
    border-color: none;
}
.other-tires-button-navigation-style:focus{
    border-color: none;
    outline: none;
}

/* LARGE SCREENS  */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .other-tires-button-navigation-style{
        display: none;
    }
    .other-tires-nav-container{
        overflow-x: auto!important;
    }
}
@media only screen and (min-width: 1500px) {
    .other-tires-main-container{
        height: 110%;
    }
    .other-tires-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .other-button-other-tires-nav-container{
        display: flex;
        margin: 0 7.5%;
        overflow-x: hidden;
    }

    .other-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-other-tires-nav-card-text{
        font-size:1.2em;
    }
    .other-tires-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .other-tires-main-container{
        height: 150%;
    }
    .other-tires-nav-container{
        overflow-x: scroll;
    }
    .other-button-other-tires-nav-container{
        overflow-x: scroll;
    }
    .other-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.tire-register-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .tire-register-container{
      margin-top: 10em
    }

}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .tire-register-container{
        padding: 0 7.5%;
        width: 85%;
    }
    
} */
.no-data-container {
    padding-top: 5%;
  }

.percent-worn-highest{
  background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.no-data-container {
    padding-top: 5%;
  }

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.no-data-container {
    padding-top: 5%;
  }

  
.percent-worn-highest{
  background-color: #ffc7c7 !important;
}
.percent-worn-high{
  background-color: #ffe0a9 !important;
}
.percent-worn-mid{
  background-color: #ffffaf !important;
}
.percent-worn-low{
  background-color: #ccf2db !important;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.update-button{
    width: 89px;
    height: 36px;
    background: transparent linear-gradient(
90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: .8;
    font-size: 14px;
    font-family: Barlows,'Noto Sans';
    color: #ffffff;
    border: none;
    margin-top: 24px;
}
.update-button:hover{
    opacity: 1;
}
.multi-select-width{
    width: 14em;
}
.search-table-container {
    width: 15em !important;
}
.display-columns-container {
    width: 100%;
    max-width: 15em;
    margin-left: 0.5em;
    margin-right: 1em;
}
.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-body {
    position: relative;
    padding: 15px;
}

.modal-content {
    position: fixed;
    top: 0;
    background-color: #fff ;
    width: 60%;
    left: 20%;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    float: right;
}


.update-button{
    width: 89px;
    height: 36px;
    background: transparent linear-gradient(
90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: .8;
    font-size: 14px;
    font-family: Barlows,'Noto Sans';
    color: #ffffff;
    border: none;
    margin-top: 24px;
}
.update-button:hover {
    opacity: 1;
}
.multi-select-width{
    width: 14em;
}
.search-table-container {
    width: 15em !important;
}
.display-columns-container {
    width: 100%;
    max-width: 15em;
    margin-left: 0.5em;
    margin-right: 1em;
}
.no-data-container {
    padding-top: 5%;
  }
.MuiDrawer-paperAnchorRight {
    width: 40%;
}
.filter-header{
    display: flex;
    padding:1em;
}
.filter-title{
    width: 50%;
    font-family: Barlows,'Noto Sans';
    font-size: 1.25em;
    color: #0055A4;
}
.filter-header-options{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.filter-reset{
    margin-right:1em;
    cursor: pointer;
    font-family: Barlows,'Noto Sans';
    padding: 0.5em;
    color: #0055A4;
}
.filter-apply{
    margin-right:1em;
    cursor: pointer;
    background: transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    font-family: Barlows,'Noto Sans';
    color: white;
    width: auto;
    padding: 0.5em;
    text-align: center;
    
}
.filter-cancel{
    cursor: pointer;
    padding: 0.25em;
}

@media only screen and (max-width: 1024px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
    #root .MuiTab-root {
        min-width: 4em;
    }
}

@media only screen and (max-width: 950px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
   
}
.MuiPaper-elevation4 {
    box-shadow: none !important;
}
.date-tab-container .MuiTabs-root{
    border: 1px solid #DEDFDF;
}
.MuiTabs-flexContainer .MuiButtonBase-root{
    background: #fff !important;
}
.MuiTab-textColorPrimary{
    color: #000000DE !important;
}
#filter-tabs .MuiAppBar-root{
    width: 90% !important;
    margin: 1em !important;
    border-radius: 0.5em !important;
}
#filter-tabs .MuiTab-root {
    min-width: 6em !important;
    border-left: 1px solid #DEDFDF !important;
    text-transform: capitalize !important;
    padding: 0em !important;
}
#filter-tabs .MuiTabs-root {
    border-radius: 0.5em !important;
}

#filter-tabs .MuiAppBar-colorDefault{
    background-color: #FFFFFF !important;
}
#filter-tabs .MuiTab-textColorPrimary.Mui-selected {
    background-color: #0055A4 !important;
    color: #ffffff !important;
}

.tab-header{
    display: flex;
    padding:1em;
}
.tab-title, .checkbox-title{
    width: 100%;
    font-family: Barlows,'Noto Sans';
    font-size: 1.0em;
    color: #000000;
}

.date-tab-container{
    padding: 0 1em;
}
.options-alignment{
    display: flex;
}
.padding-one  {
    padding: 0 1em 1em 0;
}
.checkbox-title{
    padding-bottom: 0.5em
}


.removed-tire-container {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

.removed-tire-table-toolbar {
  display: flex;
  margin-bottom: 1em;
}

.selection-limit-text {
  font-size: 11px;
  color: gray;
  /* margin-bottom: -0.8em; */
  margin-left: 0.5em;
}

.table-filters {
  display: flex;
  width: 60%;
  margin-bottom: 10px;
}
.search-table-container {
  width: 15em;
}
.tires-select-container {
  max-width: 15em;
  min-width: 15em;
  margin-left: 0.5em;
}

.filter-table-label {
  color: #000000 !important;
  font-family: Barlow, Noto sans !important;
  font-size: 1em !important;
  margin-bottom: 0.5em;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

.display-columns-style {
  width: 100% !important;
}

.multi-select-display-col {
  height: 0.9em !important;
  z-index: 4 !important;
}

#dashboard-display-column .multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 2.2em !important; /* Height */
}

.table-function-title {
  width: 40%;
  text-align: end;
}
.table-function-item {
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  font-size: 0.7em;
  cursor: pointer;
  background: white;
  border-radius: 0.5em;
  border: 1px solid white;
  height: 2.5em;
}
.table-function-item:hover {
  background: #cfdfff !important;
}
.filter-icon-img {
  height: 0.9em;
  margin-right: 0.2em;
}
.print-icon-img {
  height: 0.9em;
  margin-right: 0.2em;
}
.mail-icon-img {
  height: 0.8em;
  margin-right: 0.2em;
}
.keyword-search-icon-img {
  height: 1.25em;
}

/* EXPORT DROPDOWN */

.export-button {
  color: #0055a4 !important;
  font-family: Barlow, Noto sans !important;
  font-size: 0.7em !important;
  cursor: pointer !important;
  background: white !important;
  /* border: 1px solid #77acff !important; */
  /* border-radius: 0.5em !important; */
  height: 2.5em !important;
}
.export-dropdown {
  position: relative;
  display: flex;
}
.export-dropdown-content {
  position: absolute;
  background-color: white;
  width: 5em;
  z-index: 3;
  border-radius: 0.5em;
  border: 1px solid lightgray;
  margin-top: 1.8em;
}

.export-dropdown-content .export-option {
  color: #0055a4;
  padding: 0.5em;
  font-family: Barlows,'Noto Sans';
}

.export-option:hover {
  background: #b1d2ff;
  cursor: pointer;
}
.dropdown-icon-container {
  cursor: pointer;
  display: flex;
}
.export-down-arrow {
  padding-left: 0.25em;
}

/* MOBILE VIEW  */
@media only screen and (max-width: 600px) {
  .removed-tire-container {
    margin-top: 25em;
  }
  .removed-tire-table-toolbar {
    display: block;
  }
  .table-header {
    display: block;
  }
  .table-filters {
    display: block;
    width: 100%;
  }
  .search-table-container {
    width: 100%;
  }
  .display-columns-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .search-table-bar {
    width: 100% !important;
  }

  .table-title {
    margin-bottom: 1em;
    width: 100%;
  }
  .table-function-title {
    width: 100%;
    text-align: start;
  }
  .table-function-item {
    margin: 0;
    padding-right: 1.5em;
  }
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .removed-tire-container {
    margin-top: 10em;
  }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
  .removed-tire-container {
    padding: 0 7.5%;
    width: 85%;
  }
} */

.removed-tire-record-container{
    display: flex;
    margin: 2em 0;
}

.removed-tire-record-detail{
    background: #C1DDFD !important;
    border: #c8dffb 2px dotted !;
}

.parent-account-container{
    margin: 0 0.5em;
}

.account-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.account-text-record-details{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em;    
    color: rgba(0, 0, 0, 0.589) !important;
}
.record-no{
    padding-left: 2px !important;
}

.removed-tire-detail-container{
    display: flex;
    margin-bottom: 2em;
    margin-top: 0.8em;
}

.parent-account-container{
    margin: 0 0.5em;
}

.tire-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.tire-header-text{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em; 
    color: #666666; 
}
.wrap-container{
    overflow-wrap: break-word;
    width: 50%;

}
.removed-tire-detail-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}

.removed-tire-detail-container .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 13px !important;
}

.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-text{
    margin-left: 9px !important;
}
.removed-tire-image-container{
    display: flex;
    margin: 2em 0;
}
.image-container{
    width: 252px;
    height: 252px;
    border: 0.5px solid #707070;
    border-radius: 7px;
    opacity: 1;
    cursor: pointer;
}
.image-style{
    width: 100%;
    height:100%;
    border-radius: 7px;
}
.image-modal{
    width: 100%;
    height: 100%;
}

.image-modal-close{
    margin-top: 0.2em;
    margin-right: 0em;
}

.image-modal-body-container {
    display: flex;
    margin: 0.2em;
}

.image-modal-container {
    border-radius: 0.5em;
    width: 33%;
    height: 77%;
    background: white;
    overflow: auto;
    max-height: 100%;
}

.modal-previous-icon{
    height: 20px;
    width: 20px;
}

.modal-next-icon{
    height: 20px;
    width: 20px;
}

.image-previous-icon-container{
    width:20%;
    margin-left: 18%;
}

.image-modal-title{
    width: 30%;
    color: #000;
    font-family: Barlows,'Noto Sans';
    font-size: 1em;
}

.image-next-icon-container{
    margin-right: 20%;
}

.graph-close {
    margin-top: -12px!important;
}

.graph-title{
    margin: 1em;
}
.removed-tire-graph-container{
    display: flex;
    margin: 3em 0;
    cursor: pointer;
    margin-bottom: 6em;
}

.no-graph-removed-tire{
    margin: auto;
    width: 200px;
}
.graph-removed-tire-container{
    width: 225px;
    height: 229px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
    opacity: 1;
    cursor: pointer;
}

.removed-tire-graph-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}
.graph-container-height{
    height:100%!important
}
.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-graph{
    padding-left: 10px !important;
}

.no-data-container {
    padding-top: 5%;
  }



.modal-previous-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.modal-next-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.previous-icon-container{
    width:20%;
    margin-left: 20%;
}

.graph-modal-title{
    width: 43%;
    color: #000;
}

.next-icon-container{
    margin-right: 19%;
}

.graph-title{
    margin-top: 1.3em;
}

.graph-close{
    margin-top: -12px!important;
}

.graph-modal-body{
    margin: 1.4em 1.4em 1.4em 1.4em;
}


.MuiDrawer-paperAnchorRight {
    width: 40%;
}
.filter-header{
    display: flex;
    padding:1em;
}
.filter-title{
    width: 50%;
    font-family: Barlows,'Noto Sans';
    font-size: 1.25em;
    color: #0055A4;
}
.filter-header-options{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.filter-reset{
    margin-right:1em;
    cursor: pointer;
    font-family: Barlows,'Noto Sans';
    padding: 0.5em;
    color: #0055A4;
}
.filter-apply{
    margin-right:1em;
    cursor: pointer;
    background: transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    font-family: Barlows,'Noto Sans';
    color: white;
    width: auto;
    padding: 0.5em;
    text-align: center;
    
}

.filter-applied-disable {
    margin-right: 1em;
    cursor: pointer;
    background: transparent linear-gradient(90deg, #bebfc046 0%, #bebfc046 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 0.8;
    font-family: Barlows,'Noto Sans';
    color: white;
    width: auto;
    padding: 0.5em;
    text-align: center;
    pointer-events: none;
}

.filter-reset-message {
    margin-left: 15px;
    font-weight: bold;
}
.filter-cancel{
    cursor: pointer;
    padding: 0.25em;
}

@media only screen and (max-width: 1024px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
    #root .MuiTab-root {
        min-width: 4em;
    }
}

@media only screen and (max-width: 950px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
   
}
.MuiPaper-elevation4 {
    box-shadow: none !important;
}
.date-tab-container .MuiTabs-root{
    border: 1px solid #DEDFDF;
}
.MuiTabs-flexContainer .MuiButtonBase-root{
    background: #fff !important;
}
.MuiTab-textColorPrimary{
    color: #000000DE !important;
}
#filter-tabs .MuiAppBar-root{
    width: 90% !important;
    margin: 1em !important;
    border-radius: 0.5em !important;
}
#filter-tabs .MuiTab-root {
    min-width: 6em !important;
    border-left: 1px solid #DEDFDF !important;
    text-transform: capitalize !important;
    padding: 0em !important;
}
#filter-tabs .MuiTabs-root {
    border-radius: 0.5em !important;
}

#filter-tabs .MuiAppBar-colorDefault{
    background-color: #FFFFFF !important;
}
#filter-tabs .MuiTab-textColorPrimary.Mui-selected {
    background-color: #0055A4 !important;
    color: #ffffff !important;
}

.tab-header{
    display: flex;
    padding:1em;
}
.tab-title, .checkbox-title{
    width: 100%;
    font-family: Barlows,'Noto Sans';
    font-size: 1.0em;
    color: #000000;
}

.date-tab-container{
    padding: 0 1em;
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.no-data-container {
    padding-top: 5%;
  }
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-tire-type-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-tire-type-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-tire-type-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-tire-size-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-tire-size-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-tire-size-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-tire-size-container {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.table-filters-monthly-tire-life {
  display: flex;
  width: 80%;
}
.MuiInput-underline:before {
  width: 0;
  height: 0;
  border-bottom: 0px solid rgba(0, 0, 0, 0.42);
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .by-tire-size-container {
    margin-top: 10em;
  }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
  .by-tire-size-container {
    padding: 0 7.5%;
    width: 85%;
  }
} */

@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-size-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-size-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-size-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-tire-type-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-tire-type-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-tire-type-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-vehicle-model-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-vehicle-model-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-vehicle-model-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-vehicle-model-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-vehicle-model-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-vehicle-model-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.by-vehicle-id-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .by-vehicle-id-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .by-vehicle-id-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.tire-inflation-container {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

/* SELECT BOXES STYLING  */
.location-container {
  width: inherit;
}

.select-container {
  margin: 0.5em;
  /* display: inline-block;
    vertical-align: top; */
}
.align-right {
  float: right;
  min-width: 15%;
}
.select-input {
  width: 100% !important;
  padding: 1em;
}
.select-label {
  margin-bottom: 0.3em !important;
  font-size: 0.8em !important;
  color: #222222 !important;
}
.select-style {
  border: 1px solid lightgray !important;
  border-radius: 0.5em;
  padding: 0.2em;
  margin-top: 1.5em !important;
}

.custom-options-styling:hover {
  background: #77acff !important;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

#header-change-modal-multi-select .multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 3.5em !important; /* Height */
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .tire-inflation-container {
    margin-top: 10em;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  /* .tire-inflation-container {
    padding: 0 7.5%;
    width: 85%;
  } */

  .select-label {
    font-size: 1.25em !important;
  }
}

/* Text-Field CSS */
.text-container {
  margin: 2em;

  /* display: inline-block; */
}

.MuiDrawer-paperAnchorRight {
    width: 40%;
}
.filter-header{
    display: flex;
    padding:1em;
}
.filter-title{
    width: 50%;
    font-family: Barlows,'Noto Sans';
    font-size: 1.25em;
    color: #0055A4;
}
.filter-header-options{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.filter-reset{
    margin-right:1em;
    cursor: pointer;
    font-family: Barlows,'Noto Sans';
    padding: 0.5em;
    color: #0055A4;
}
.filter-apply{
    margin-right:1em;
    cursor: pointer;
    background: transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    font-family: Barlows,'Noto Sans';
    color: white;
    width: auto;
    padding: 0.5em;
    text-align: center;
    
}
.filter-cancel{
    cursor: pointer;
    padding: 0.25em;
}

@media only screen and (max-width: 1024px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
    #root .MuiTab-root {
        min-width: 4em;
    }
}

@media only screen and (max-width: 950px) {
    #root .MuiDrawer-paper{
        width: 100%;
    }
   
}
.MuiPaper-elevation4 {
    box-shadow: none !important;
}
.date-tab-container .MuiTabs-root{
    border: 1px solid #DEDFDF;
}
.MuiTabs-flexContainer .MuiButtonBase-root{
    background: #fff !important;
}
.MuiTab-textColorPrimary{
    color: #000000DE !important;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.scrap-cost-by-tire-size{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.display-col-width{
    width: 40%
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .scrap-cost-by-tire-size{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .scrap-cost-by-tire-size{
        padding: 0 7.5%;
        width: 85%;
    }
} */
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.survey-template-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .survey-template-container{
      margin-top: 10em
    }

}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .survey-template-container{
        padding: 0 7.5%;
        width: 85%;
    }
    
} */
.axle-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 150%; */
    position: absolute;
    width: 100%;
     /* Footer fix */
     height: 100%
}

.axle-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* SCRAP TIRE HEADER (BREADCRUMBS & TITLE) */

.axle-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.axle-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* SCRAP TIRE NAVIGATION  */

.axle-nav-container{
    display: flex;
    padding: 0 2.5%;
}

.axle-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}

.axle-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-axle-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}
/* .axle-nav-bottom-arrow {
    position:relative;
    background-color:#FFBE00;
}

.axle-nav-bottom-arrow::after {
    content:'' !important;
    position: absolute !important;
    top: 100% !important;
    left: 50% !important;
    margin-left: -50px !important;
    width: 0 !important;
    height: 0 !important;
    border-top: solid 50px #FFBE00 !important;
    border-left: solid 50px transparent !important;
    border-right: solid 50px transparent !important;
} */

.axle-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
    white-space: nowrap;
}
.active-axle-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
    white-space: nowrap;
}


/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .axle-main-container{
        height: 110%;
    }
    .axle-nav-container{
        display: flex;
        /* padding: 0 7.5%; */
    }
    .axle-nav-card-text{
        font-size:1.2em;
    }
    .active-axle-nav-card-text{
        font-size:1.2em;
    }
    .axle-title{
        font-size: 2em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .axle-main-container{
        /* height: 150%; */
         /* Footer fix */
        height: 100%
    }
    .axle-nav-container{
        overflow-x: scroll
    }
    .axle-nav-card-container{
    margin-right: 1em;
    }
    .axle-upper-deck{
        height: 60%;
    }
    .axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
   
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    .axle-upper-deck{
        height: 60%;
    }
    .axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-axle-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }

}

.survey-template-toolbar-multi{
    display : flex;
    margin-bottom: 1em;
}
.survey-template-toolbar-single{
    display : flex;
    margin-bottom: 1em;
    justify-content: flex-end;
}
.survey-template-location-selector-container{
    width: 60%;
}
.survey-template-location-selector{
    width: 40%;
}
.survey-template-export-items{
    width: 40%;
    text-align: end;
}

.survey-template-location-label-container{
    margin-bottom: 0.25em;
}
.survey-template-location-label{
    font-size: 1em;
    color : #0055A4;
    font-weight: 400;
    font-family : Barlows,'Noto Sans';
    margin-right: 0.25em;
}
.survey-template-location-label-text{
    font-size: 1em;
    font-weight: 500;
    color : #0055A4;
    font-family : Barlows,'Noto Sans';
}


.survey-template-function-item{
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    font-size: 0.8em; 
    margin: 0 0.5em;
    cursor: pointer;
    background: white;
    border-radius: 0.5em;
    border: 1px solid white;
    height: 2.5em;
}
.survey-template-function-item:hover{
    background: #C4DCFFAF !important;
}
.download-icon-img{
    height: 0.9em;
    margin-right:0.3em;
}
.print-icon-img{
    height: 0.8em;
    margin-right:0.2em;
}
.mail-icon-img{
    height: 0.7em;
    margin-right:0.2em;
}
.survey-template-toolbar-text{
    font-size: 1em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
}
.axle-config-template{
    text-align: center;
}
#iframeContainer{
    width: 100% ;
    height: 60em ;
    /* zoom: 2 ; */
}
.survey-template-desktop{
    display:block
}
.survey-template-mobile{
    display:none
}

/* MOBILE VIEW  */
@media only screen and (max-width: 768px) {
    .survey-template-toolbar-multi{
        display: block;
        margin-bottom: 0.5em;
    }
    .survey-template-toolbar-single{
        display: block;
        margin-bottom: 0.5em;
    }
    .survey-template-function-item{
        margin : 0;
        padding-right : 1.5em;
    }
    .survey-template-location-selector-container{
        width: 100%;
    }
    .survey-template-location-selector{
        width: 100%;
    }
    .survey-template-export-items{
        width: 100%;
    }
   
} 

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    .survey-template-toolbar-text{
        font-size: 1.3em;
    }
    .print-icon-img{
        height: 1.1em;
        margin-right:0.5em;
    }
    .mail-icon-img{
        height: 1em;
        margin-right:0.5em;
    }
    .download-icon-img{
        height: 1.1em;
        margin-right:0.5em;
    }
}

/* Portrait and Landscape tablet*/
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .survey-template-desktop{
        display:none
    }
    .survey-template-mobile{
        display:block
    }

}
.template-box{
    border: 1px solid #000000de;
    height: 2.1em;
}
.margin-top-1em{
    margin-top: 1em;
}
.axle-analysis-template{
    border-bottom: 1px solid lightgrey;
    padding: 2em 1em;
}
.axle-vehicle-template{
    border-bottom: 1px solid lightgrey;
}
.left-spaceing{
    margin-left: 2em;
}
.axle-survey-template{
    margin-top: 0.5em;
}
.button-container{
    /* margin-left: 3em; */
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.text-container{
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.table-toolbar-dailyJob
{
    /* margin-bottom: 1.5em; */
    margin-top: 3em;
}

.dailyjob-second-row-dropdown{
    margin-top: 1em;
}

.dailyjob-exclude-used-tires{
    margin-top: 2em;
}

.daily-job-reporting-periods{
    font-size: 0.75em;
    display: contents;
}

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
.vehicle-container{
    padding-top: 0.8em;
    padding-bottom: 2em
}

.removed-tire-record-detail{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;
}

.parent-account-container{
    margin: 0 0.5em;
}

.account-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
    
}

.account-header-text-by-tire{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em;    
}
.record-no{
    padding-left: 2px !important;
}
.grid-vehicle-container{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;

}
.grid-data-container{
    padding-left: 2em;
}
.padding-2em{
    padding-top: 2em
}
.padding-1em{
    padding-top: 1em
}
.heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 1em;
    padding-bottom: 0.4em;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding: 0.7em 0;
    font-weight: 500;
}
.font-wt-500{
    font-weight: 500;
}

.fixed-columns{
    z-index: 4!important;
    min-width: 120px;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: white;
}
.first-fleet-col {
    left: 0!important;
  }
.second-fleet-col{
    left: 6%!important;
}
.third-fleet-col{
   
    left: 20%!important;
}
.fourth-fleet-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 21%!important;
}

.removed-tire-detail-container{
    display: flex;
    margin-bottom: 2em;
    margin-top: 0.8em;
}

.tire-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.tire-header-text{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em; 
    color: #666666; 
}

.removed-tire-detail-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}

.removed-tire-detail-container .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 13px !important;
}

.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-text{
    margin-left: 9px !important;
}

.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.table-content-fleet-text {
    color: #e22626 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
}

.less-percentage-warning {
    background-color: #FFC7C7 !important;
}


@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.exclude-used-tires-tire-rotation
{
    margin-bottom:1em;
    margin-top:1em;
    margin-left:7em
}

.display-columns-container-tire-rotation{
    width: 100%;
    max-width: 15em;
    margin-bottom: 1em;
    margin-left: 4em
}
.table-function-title-tire-rotation{
    width : 25%;
    text-align: end;
}

.table-filters-tire-rotation{
    display: flex !important;
    width : 75% !important;
}

.rotation-value{
    margin-left:3em
}

.tire-rotation-update{
    margin-left: 1em;
    margin-top: 1em;
}
.vehicle-container {
  padding-top: 0.8em;
  padding-bottom: 2em;
}

.removed-tire-record-detail {
  background: #c1ddfd;
  border: #c8dffb 2px dotted;
}

.parent-account-container {
  margin: 0 0.5em;
}

.account-info-text {
  padding: 0.15em;
  font-family: Barlows,'Noto Sans';
  font-size: 0.9em;
  font-weight: 500;
}

.account-header-text-by-tire {
  padding: 0.1em;
  font-family: Barlows,'Noto Sans';
  font-size: 0.9em;
}
.record-no {
  padding-left: 2px !important;
}
.grid-vehicle-container {
  background: #c1ddfd;
  border: #c8dffb 2px dotted;
}
.grid-data-container {
  padding-left: 2em;
}
.padding-2em {
  padding-top: 2em;
}
.padding-1em {
  padding-top: 1em;
}
.heading-column-data {
  color: #333333;
  opacity: 1;
  font-weight: 500;
  font-size: 1em;
  padding-bottom: 0.4em;
}
.vehicle-header-info {
  color: #194cab;
  font-size: 1em;
  padding: 0.7em 0;
  font-weight: 500;
}
.font-wt-500 {
  font-weight: 500;
}

.fixed-columns {
  z-index: 4 !important;
}
.table-row:hover .fixed-column-body {
  background-color: #e4ecf7;
}
.fixed-column-body {
  top: 0;
  left: 0;
  z-index: 3 !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  background-color: white;
}
.first-rotation-col {
  left: 0 !important;
}
.second-rotation-col {
  left: 6% !important;
}
.third-rotation-col {
  left: 12% !important;
}
.fourth-rotation-col {
  box-shadow: 2px 0px #d1d1d1;
  left: 19% !important;
}

.removed-tire-detail-container {
  display: flex;
  margin-bottom: 2em;
  margin-top: 0.8em;
}

.tire-info-text {
  padding: 0.15em;
  font-family: Barlows,'Noto Sans';
  font-size: 0.9em;
  font-weight: 500;
}

.tire-header-text {
  padding: 0.1em;
  font-family: Barlows,'Noto Sans';
  font-size: 0.9em;
  color: #666666;
}

.removed-tire-detail-container .MuiGrid-grid-xs-3 {
  max-width: 20% !important;
}

.removed-tire-detail-container .MuiGrid-spacing-xs-5 > .MuiGrid-item {
  padding: 13px !important;
}

.heading-container {
  color: #194cab;
  font-size: 1em;
  font-weight: 500;
}

.heading-text {
  margin-left: 9px !important;
}

.pagination-vehicle-report ul {
  padding-left: 16em;
}
.pagination-vehicle-report {
  display: inline-block;
}
.record-data {
  display: inline-block;
  font-size: 0.8em;
  color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected {
  background-color: #0055a4;
  color: #fff;
}
.table-content-rotation-text {
  color: #404040 !important;
  font-size: 0.85em !important;
  font-family: Barlow, Noto sans !important;
  /* position: unset !important; */
  /* white-space: nowrap !important; */
}
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.exclude-used-tires-tireflipping
{
    margin-bottom:1em;
    margin-top:1em;
    margin-left:7em
}

.display-columns-container-tireflipping{
    width: 100%;
    max-width: 15em;
    margin-bottom: 1em;
    margin-left: 4em
}
.table-function-title-tireflipping{
    width : 25%;
    text-align: end;
}

.table-filters-tireflipping{
    display: flex !important;
    width : 75% !important;
}

.flipping-input{
    margin-right:4% !important;
}

.tire-flipping-update{
    margin-left: 1em;
    margin-top: 1em;
}

.vehicle-container{
    padding-top: 0.8em;
    padding-bottom: 2em
}

.removed-tire-record-detail{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;
}

.parent-account-container{
    margin: 0 0.5em;
}

.account-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.account-header-text-by-tire{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em;    
}
.record-no{
    padding-left: 2px !important;
}
.grid-vehicle-container{
    background: #C1DDFD;
    border: #c8dffb 2px dotted;

}
.grid-data-container{
    padding-left: 2em;
}
.padding-2em{
    padding-top: 2em
}
.padding-1em{
    padding-top: 1em
}
.heading-column-data{
    color: #333333;
    opacity: 1;
    font-weight: 500;
    font-size: 1em;
    padding-bottom: 0.4em;
}
.vehicle-header-info{
    color: #194CAB;
    font-size: 1em;
    padding: 0.7em 0;
    font-weight: 500;
}
.font-wt-500{
    font-weight: 500;
}

.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: white;
}
.first-fleet-col {
    left: 0!important;
  }
.second-fleet-col{
    left: 6.5%!important;
}
.third-fleet-col{
   
    left: 16%!important;
}
.fourth-fleet-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 21%!important;
}

.removed-tire-detail-container{
    display: flex;
    margin-bottom: 2em;
    margin-top: 0.8em;
}

.tire-info-text{
    padding:0.15em;    
    font-family: Barlows,'Noto Sans';        
    font-size: 0.9em;
    font-weight: 500;
}

.tire-header-text{
    padding:0.1em;
    font-family: Barlows,'Noto Sans';   
    font-size: 0.9em; 
    color: #666666; 
}

.removed-tire-detail-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}

.removed-tire-detail-container .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 13px !important;
}

.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-text{
    margin-left: 9px !important;
}

.pagination-vehicle-report ul {
    padding-left: 16em;
}
.pagination-vehicle-report{
    display: inline-block
}
.record-data{
    display: inline-block;
    font-size: 0.8em;
    color: #676767;
}
.pagination-vehicle-report ul li button.Mui-selected{
    background-color: #0055A4;
    color: #fff;
}
.table-content-fleet-text {
    color: #404040 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
    /* position: unset !important; */
    /* white-space: nowrap !important; */
}

@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.drop-down-forecast{
    max-width: 14.5em;
}

.filter-container{
    margin-top: 1em;
}


.rim-reports-upper-deck{
    position: absolute;
    width: 100%;
    height: 40%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* VEHICLE TIRE HEADER (BREADCRUMBS & TITLE) */

.rim-reports-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.rim-reports-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* VEHICLE TIRE NAVIGATION  */

.rim-time-nav-container{
    display: flex;
    padding: 0 2.5%;
}

.rim-reports-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}

.rim-reports-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-rim-reports-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
}
.rim-reports-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
    white-space: nowrap;
}
.active-rim-reports-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
    white-space: nowrap;
}
.scrap-time-nav-container{
    display: flex;
    padding: 0 2.5%;
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    /* .rim-time-nav-container{
        display: flex;
        padding: 0 7.5%;
    } */
    .rim-reports-nav-card-text{
        font-size:1.2em;
    }
    .active-rim-reports-nav-card-text{
        font-size:1.2em;
    }
    .rim-reports-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .rim-time-nav-container{
        overflow-x: scroll
    }
    .rim-reports-nav-card-container{
    margin-right: 1em;
    }
    .rim-reports-upper-deck{
        height: 60%;
    }
    .rim-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-rim-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
   
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .rim-reports-upper-deck{
        height: 60%;
    }
    .rim-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-rim-reports-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }

}

/* @media only screen and (min-width: 1500px)
{
    .scrap-time-nav-container {
        display: flex;
        margin: 0 5%;
        overflow-x: auto;
    }
} */
.no-data-container {
    padding-top: 5%;
  }
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
.no-data-container {
    padding-top: 5%;
  }
@media only screen and (min-width: 768px) {
    .row-container{
        display: flex;
        padding-bottom: 2em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.tire-register-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .tire-register-container{
      margin-top: 10em
    }

}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .tire-register-container{
        padding: 0 7.5%;
        width: 85%;
    }
    
} */
.no-data-container {
    padding-top: 5%;
  }
/* #tire-history-table .MuiTableCell-head:first-child{
    background: yellow;
    position: fixed;
    padding: 7px 7px;
    padding-left: 0;
} */
.dropdown-content{
    z-index: 5!important;
}
.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: white;
}
.first-col {
    left: 0!important;
  }
.second-col{
    left: 6.5%!important;
}
.third-col{
   
    left: 12%!important;
}
.fourth-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 16%!important;
}

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
.home-container {
  height: 125em;
  background-color: #f2f2f2;
  overflow-x: hidden;
  overflow-y: hidden;
}

.whats-new-container {
  background-color: #f2f2f2;
  width: 90%;
  border-radius: 0.5em;
  height: 95%;
}

@media only screen and (max-width: 1366px) {
  .home-container {
    height: 108em;
    background-color: #f2f2f2;
  }
}
.home-card-custom-style {
  background: rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  height: 4em;
  padding: 1em 0;
  border-radius: 1em !important;
  color: white !important;
}
.home-card-custom-style:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.4) !important;
}
.home-upper-deck {
  height: 100%;
  background-color: #f2f2f2;
}
.dashboard-header-home {
  padding-top: 2em;
  padding-bottom: 0em;
  background: #0d53b4;
  background-image: linear-gradient(to right, #0d53b4, #054196);
}
.print-icon-home {
  margin-top: -1em;
}

.no-data-container {
  padding-top: 5%;
}

.no-data-container-text {
  text-align: center;
  padding-bottom: 10%;
  font-size: 25px;
  color: #aaaaaa8c;
}
/* .MuiInput-root {
  

}
.add-new-delete-box {
    width: 11% !important;

} */
.broadcasting-card-padding {
  padding-top: 0em !important;
}
#height-css1 {
  height: auto !important;
  width: 100% !important;
}
.sizeDropCSS {
  position: absolute !important;
  margin-top: 7% !important;
  margin-left: 30% !important;
  width: 11% !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  font-weight: 600 !important;
}

.background-image-home-deck {
  height: 25%;
  background: transparent linear-gradient(161deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-image-home-dashboard {
  position: relative;
  background-image: url(/static/media/map.337e1012.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}
.grid-graph-home-dashboard-container {
  margin-top: 0em;
}
.home-location-header {
  padding: 0 2em;
}
.view-link-floating-card {
  width: 100%;
  margin-top: -20px;
  margin-bottom: 10px;
  float: right;
  text-align: end;
  vertical-align: bottom;
  align-items: baseline;
}
.floating-card-padding {
  padding-top: 2em !important;
}
.floating-card {
  margin: 1em 0.5em !important;
  justify-content: space-between;
}
.header-change-modal-confirm-btn1 {
  opacity: 0.8;
  width: 6em;
  padding: 0.75em;
  cursor: pointer;
  border-radius: 1.2em;
  color: white;
  background: transparent linear-gradient(90deg, #0055a4 0%, #0079e6 100%) 0% 0% no-repeat padding-box;
  /* background: transparent linear-gradient(90deg, #0079e6 0%, #0079e6 100%) 0% 0% no-repeat padding-box; */

  border: 0;
}
.header-change-modal-confirm-btn1:hover {
  opacity: 1;
}

.header-change-modal-title-container1 {
  display: flex;
  margin-left: 1em;
}
.print-icon {
  width: 100%;
  text-align: right;
}
.fab-class {
  background-color: rgba(255, 166, 0, 0.644) !important;
  float: right;
  margin-right: 1rem !important;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: 50%;
  left: 3%;
}
.fab-class:hover {
  background-color: orange !important;
}

.modal-button-group1 {
  display: flex;
  justify-content: center;
  /* margin-bottom: 0.5rem; */
}
.header-msg {
  font-size: 1.5em;
  font-family: Barlows,'Noto Sans';
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  /* background-image: linear-gradient(to right, #073475, #083475); */
  background-color: #083475;
  border-radius: 5px;
  color: #ffdc00;
  /* background: transparent linear-gradient(161deg, #073475 0%, #083475 100%) 0% 0% no-repeat padding-box; */
}
.broad-body {
  position: relative;
}
.footer-msg {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 1.5em;
  font-family: Barlows,'Noto Sans';
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 16.43px;
  position: relative;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.header-change-modal-title1 {
  font-size: 10px;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 0.3em;
}
.change-modal-select-label1 {
  margin-bottom: 0.3em !important;
  font-size: 8px !important;
  color: #222222 !important;
  margin-left: 1.6em !important;
  font-family: Barlows,'Noto Sans';
  /* max-height: 70%; */
  /* overflow-y: scroll; */
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .header-change-modal-title1 {
    font-size: 1.5em;
  }
  .change-modal-select-label1 {
    font-size: 1em !important;
  }
}
.print-dialog {
  background: transparent !important;
  color: #fff !important;
  border: none !important;
}
.print-dialog:hover {
  color:#ffdc00 !important;
  background-color: #0d53b400 !important;
}
/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
  .home-location-header {
    padding: 1em;
  }
}

/* MOBILE VIEW */
@media only screen and (max-width: 750px) {
  .home-location-header {
    padding: 0.5em;
  }
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
  .home-location-header {
    padding: 0.5em 7.5%;
  }
}

.export-btn-img {
  width: 0.8em;
}

.message {
  color: rgb(27, 27, 27);
  font-size: 8pt;
  /* font-weight:bold; */
  text-align: left;
}

.chatBubble {
  margin-top: 2%;
  margin-right: 5%;
  border: 1px solid #3180ca69;
  border-radius: 10px;
  background-color: rgb(250, 250, 250);
  padding: 10px;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #3180ca69;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  position: relative;
  float: left;
  left: -2.9%;
  bottom: -20%;
}

.edit-parent-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.edit-parent-upper-half {
  position: absolute;
  height: 30%;
  width: 100%;
  background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.edit-parent-breadcrumbs {
  text-align: left;
  margin: 2em;
}

.edit-parent-container {
  /* display: flex; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1;
}

.edit-parent-inner-container {
  margin: 20px;
  height: 100%;
  width: 100%;
  padding-top: 15px;
}

.background-map-image-edit-parent {
  position: absolute !important;
  background-image: url(/static/media/map.337e1012.svg) !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}

.edit-parent-account-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.account-details-grid {
  margin-bottom: 5em;
}

.edit-location-list-container {
    margin-top: 8em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.edit-location-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    margin-bottom: 120px;
}

.edit-location-upper-half {
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 100%;
}

.edit-location-breadcrumbs {
    text-align: left;
    margin: 1.5em;
    margin-left: 80px;
}

.standalone-new-header-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
}

.edit-location-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}


.edit-location-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.edit-location-inner-container {
    margin-left: 28px;
    margin-top: 30px;
    margin-bottom: 15px;
    height: 100%;
    width: 100%;
}

.background-map-image-edit-location {
    position: absolute !important;
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}


.sub-header {
    text-align: left;
    color: #194cab;
    opacity: 1;
    font-family: Barlow;
    font-size: 22px;
    margin-bottom: 10px;
}

.location-details-grid-edit {
    margin-bottom: 1em;
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .edit-location-list-container{
      margin-top: 0em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .edit-location-list-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */

.edit-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    width: 90%;
    height: 45px;
    align-self: center;
    opacity: 1;
}
.create-tire-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.create-tire-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
}

.create-tire-upper-half {
    display: flex;
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.background-map-image-create-tire {
    position: absolute !important;
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.create-tire-breadcrumbs {
    text-align: left;
    margin: 2em;
}

.create-tire-inner-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.step-indicator-container {
    /* margin: 0 20px 20px 20px; */
    border-radius: 14px;
    height: 20%;
    width: 100%;
    overflow: auto;
}

.step-indicator {
    display: flex;
    background: #E1EAF5 0% 0% no-repeat padding-box !important;
    border: 1px solid #D0D0D0 !important;
    opacity: 1;
}

.step-form-container {
    display: block !important;
    margin-right: 30%;
    margin-left: 30%;
    margin-bottom: 3em;
    /* text-align: center; */
}

.step-form-inner-container {
    width: 70% !important;
    margin-right: 15% !important;
    margin-left: 15% !important;
}

#abc .MuiAutocomplete-inputFocused {
    color: rgba(0, 0, 0, 0.18) !important;
}

.enter-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18) !important;
    border-radius: 4px !important;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px !important;
    color: black !important;
    height: inherit;
    width: 100%;
    align-self: center;
    opacity: 1;
}

/* .MuiNativeSelect-iconOutlined{
    src: url(../../../assets/pin-icon.svg);
} */

.upload-box-description {
    display: flex;
    border: 1px solid rgb(35, 85, 175, 0.22);
    border-radius: 5px;
    width: 100% !important;
    height: 5.5em;
    margin-top: 2em !important;
    margin-bottom: 8px !important;
    align-items: center;
}

.upload-Button {
    color: #2355AF !important;
    border: 1px solid #2355AF !important;
    border-radius: 25px !important;
    opacity: '1' !important;
    margin: 30px !important;
    margin-left: 13% !important;
}

.uploaded-image {
    padding-left: 10px;
    width: 80%;
    height: 80%;
}

.disabled-input {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: none !important;
}

.hr-divider-action {
    border: 1px solid #e3e3e3;
    margin-top: 20px;
}

.action-buttons {
    padding: 10px;
    text-align: right;
    margin-right: 2em;
}


.field-label-upload {
    font-family: Barlows,'Noto Sans';
    font-style: italic;
    text-align: left;
}

.helper-message {
    margin-right: 15px;
    margin-top: 20px;
    text-align: right;
    font: normal normal normal 12px/20px Barlows,'Noto Sans';
    letter-spacing: -0.29px;
    color: #333333;
}

.tirecosts-popper-desc{
    font: italic normal normal 11px/11px Barlows,'Noto Sans';
    color: #333333;
}

.tirecosts-popper-title{
    font: normal normal medium 10px/11px Barlows,'Noto Sans';
    color: #333333;
}

.MuiStepConnector-alternativeLabel {
    width: -webkit-max-content !important;
    width: max-content !important;
    left: calc(-50% + 190px) !important;
    right: calc(50% + 30px) !important;
}
.cancel-tire-modal-container {
  border-radius: 0.5em;
  width: 28%;
  background: white;
}

.cancel-modal-title-container {
  display: flex;
  margin-left: 2em;
}

.cancel-modal-title {
  font-size: 1.1em;
  color: #0055a4;
  font-family: Barlows,'Noto Sans';
  width: 100%;
  margin-top: 1.5em;
}

.cancel-modal-close-icon-container {
  text-align: end;
}

.modal-action-buttons {
  text-align: right;
  margin-right: 2em;
  margin-bottom: 2em;
}


.help-video-container{
    display: flex;
    margin: 3em 0;
    cursor: pointer;
    margin-bottom: 6em;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 200px;
    width: 97.5%;
}

.video-container{
    width: 9em;flex-wrap: nowrap;
    display: flex;
    box-sizing: border-box;
}

.item{
    margin-left: 30px;
    border-radius: 2px;
    box-sizing: border-box;
}

.no-graph-removed-tire{
    margin: auto;
    width: 200px;
}
.graph-removed-tire-container{
    width: 225px;
    height: 229px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
    opacity: 1;
    cursor: pointer;
}

.help-video-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}
.graph-container-height{
    height:100%!important
}
.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-graph{
    padding-left: 10px !important;
}
.help-sub-header > a{
    color:#1250B0;
    font-weight: bold;
}
.help-sub-header {
    font-size: 16px;
    margin-bottom: 14px;   
}

.help-details-message {
    font-weight: 500 !important;
    color: #1250B0;
    font-size: 20px;
}

.general_help{
    color:#464646
}
.general_help_secondary{
    color:#6b6b6b;
    font-size: 15px;
    margin-right: 5px;
}
.note_help_information{
   color: #393939;
   font-style: italic;
}
.help_videos_title{
    color:#686868;
    font-size: 18px;
    font-weight: bold;
}

.grid_margin_help{
    margin: 50px 0 10px 0;
}

.background-map-image-user-preferences {
  position: absolute !important;
  background-image: url(/static/media/map.337e1012.svg) !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}


.button-top-margin {
  margin-top: 2%;
  float: right;
}

.upload-box {
  border: 1px solid rgb(35, 85, 175, 0.22);
  border-radius: 5px;
  width: 70% !important;
  margin-top: 2.5em !important;
  margin-bottom: 8px !important;
  align-items: center;
}

.my-profile-footer-container {
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 1px solid #dedede;
  width: 100%;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-tire-list-container{
    margin-top: 14em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.manage-tire-search-table-container{
    width: 15em;
}
.manage-tire-action-dropdown{
    color: #0059AC!important;
    border: 1px solid #77ACFF;
    opacity: 1;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500!important;
}
.manage-tire-list-card{
    border-radius: 14px!important;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .manage-tire-list-container{
      margin-top: 10em
    }
}
/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    
    .manage-tire-list-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */
.action-tire-dropdown-content {
  position: absolute;
  background-color: white;
  width: 6em;
  z-index: 3;
  border-radius: 0.5em;
  border: 1px solid lightgray;
  margin-top: -20px;
  margin-left: -125px;
}

.action-tire-dropdown-content:hover {
  cursor: pointer;
}

.action-tire-dropdown-content .action-tire-option {
  color: #0055a4;
  padding: 0.5em;
  font-family: Barlows,'Noto Sans';
}
.tire-table-head-row {
  z-index: 2 !important;
  color: #2e2e2e !important;
  font-size: 12px !important;
  font-family: Barlow, Noto sans !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}
.sticky-col {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
}
.last-col {
  right: 0px;
  width: 100px;
  max-width: 100px;
  border-left: 1px solid #d1d1d1;
  min-width: 100px;
  /* box-shadow: 2px 0px #d1d1d1;
    -webkit-box-shadow: -1px 0px 0px 0px #d1d1d1; */
}
.last-row {
  box-shadow: 2px 0px #d1d1d1;
  -webkit-box-shadow: -1px 0px 0px 0px #d1d1d1;
}

.manage-tire-serial {
  color: #0055a4 !important;
  font-weight: 550 !important;
  cursor: pointer;
}

.manage-tire-serial:hover {
  font-weight: 700 !important;
}

.action-tire-option:hover {
  background-color: #b1d2ff;
}

.no-data-container {
    padding-top: 5%;
}

.tire-transfer-modal-container{
    border-radius: 0.5em;
    width: 35%;
    background: white;
}


.modal-close-icon{
    height: 0.75em;
    width: 0.75em;
    margin-top : 0.5em;
    margin-right : 0.75em; 
    cursor: pointer;
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
    .tire-transfer-modal-container{
        width: 85%;
    }
}

.delete-tire-title-container{
    display: flex;
    margin-left: 2em;
}

.delete-tire-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em; 
}


.delete-modal-description{
    color:#545151;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1em 2.5em 3em 2.5em; 
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

.disposition-popup-select{
    margin-bottom: 2em;
}
.manage-tires-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 150%; */
    position: absolute;
    width: 100%;
    /* Footer fix */
    height: 100%
}

.manage-tires-upper-deck{
    position: absolute;
    width: 100%;
    height: 64%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container-manage-tire{
    margin-right: 1em;
}
.background-map-image-manage-tire{
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important; 
    height: 20em !important;
    width: 100% !important;
}/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .manage-button-navigation-style{
        display: none;
    }
    .manage-tires-nav-container{
        overflow-x: auto!important;
    }
}
.manage-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6em
}
.manage-button-navigation-style:active{
    border-color: none;
}
.manage-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.manage-tires-title{
    color: white;
    font-family: Barlow;
    margin-top: 0.25em;
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.manage-button-manage-tires-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.manage-tires-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.manage-tires-nav-card-container{
    margin-right: 1em;
}
.manage-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.manage-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}
.manage-tires-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-manage-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .manage-tires-main-container{
        height: 110%;
    }
    .manage-tires-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-manage-tires-nav-container{
        display: flex;
        margin: 0 2.5%;
        overflow-x: hidden;
    }
    .manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .manage-tires-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .manage-tires-main-container{
        height: 100%;
    }
    .manage-tires-nav-container{
        overflow-x: scroll;
    }
    .manage-button-manage-tires-nav-container{
        overflow-x: scroll;
    }
    .manage-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
.delete-tire-modal-container {
    border-radius: 0.5em;
    width: 35%;
    background: white;
}


/* .modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
} */

@media only screen and (max-width: 640px) {
    .delete-tire-modal-container{
        width: 85%;
    }
}


.update-hour-parent-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.update-hour-parent-upper-half {
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.update-hour-parent-breadcrumbs {
    text-align: left;
    margin: 2em;
}


.update-hour-parent-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.update-hour-parent-inner-container {
    /* margin: 20px;  */
    height: 100%;
    width: 100%;
}

.header-inner-container {
    margin: 0px;
    height: 103px;
    border-radius: 14px 14px 0px 0px;
    width: 100%;
    background: #FAF9F2 0% 0% no-repeat padding-box;
    opacity: 1;
}

.inner-container {
    width: 80% !important;
    margin-right: 10% !important;
    height: 103px;
    margin-left: 10% !important;
    align-items: center;
    justify-content: space-between;
}

.background-map-image-update-hm {
    position: absolute !important;
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.update-hour-details-message {
    text-align: left;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    font-family: Barlow, Noto Sans;
    font-size: 15px;
    letter-spacing: 0.14px;
    margin-bottom: 20px;
}

.update-hour-parent-account-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.disable-field-label{
    text-align: left;
    color: #000;
    font-weight: 500;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
    opacity: 1;
}
.checkbox-field-label{
    text-align: left;
    color: #333;
    font-weight: 500;
    font-family: Barlow;
    font-size: 1em;
    margin-bottom: 10px;
    opacity: 1;
}

.update-hour-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px !important;
    color: black !important;
    width: 90%;
    height: 45px;
    align-self: center;
    opacity: 1;
}

.field-label-p {
    
    text-align: left;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}
.opcity-zero{
    opacity: 0;
}
.opcity-one{
    opacity: 1;
}

.helper-message {
    font-style: italic;
    margin-right: 20px;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.search-install-tire-list-container {
    /* position: absolute; */
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.field-label-bottem{
    text-align: left;
    color: #000;
    font-weight: 500;
    font-family: Barlow;
    font-size: 1.1em;
    margin-bottom: 10px;
    opacity: 1;
}

.search-install-tire-action-dropdown {
    color: #0059AC !important;
    /* background: #C4DCFF2E 0% 0% no-repeat padding-box; */
    border: 1px solid #77ACFF;
    opacity: 0.44;
    width: 70%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}

.search-install-tire-list-heading {
    font-size: 1.2em;
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
}

.search-table-container {
    width: 20em;
}

.bottem-inner-container {
    display: flex;
    justify-content: space-around;
    width: 24%;
    align-items: baseline;
}

.search-install-tire-list-card {
    border-radius: 14px !important;
}

.bottem-container {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px solid #E2E2E2;
    justify-content: center;
    height: 7em;
    align-items: center;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .search-install-tire-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {

    .search-install-tire-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.search-install-tires-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 90%;
    position: absolute;
    width: 100%;
}

.active{
    background: #d9d9e2 !important;
}
.search-install-action-buttons{
    text-align: right;
}

.no-data-container {
    padding-top: 5%;
  }
.axel-container-create-vehicle{
    display: inline-block !important;
    text-align: center !important;
    margin-right: auto !important;
    margin-left: auto !important;
    flex-wrap: nowrap !important;
    margin-top: 30px !important;
}

.axel-container-create-vehicle-align-0-1{
    margin-left: 160px !important;
    margin-right: 10px !important;
}
.axel-container-create-vehicle-align-1-1{
    margin-left: 0px !important;
}

.axel-container-create-vehicle-align-0-2{
    margin-left: 100px !important;
    margin-right: 0px !important;
}

.axel-container-create-vehicle-align-1-2{
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.axel-container-create-vehicle-align-0-3{
    margin-left: 50px !important;
    margin-right: 0px !important;
}
.axel-container-create-vehicle-align-1-3{
    margin-left: 10px !important;
    margin-right: 0px !important;
}
.axel-container-create-vehicle-align-0-4{
    margin-left: -5px !important;
    margin-right: 0px !important;
}
.axel-container-create-vehicle-align-1-4{
    margin-left: 10px !important;
    margin-right: 0px !important;
}

.double-tire{
    margin-left: 20px;
}

.axle-alignment{
    margin-left: 10px;
}

.create-vehicle-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
}

.create-vehicle-upper-half {
    display: flex;
    position: absolute;
    height: 30em;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.background-map-image-create-vehicle {
    position: absolute !important;
    /* background-image: url("../../../assets/map.svg"); */
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.create-vehicle-breadcrumbs {
    text-align: left;
    margin: 2em;
}

.create-vehicle-inner-container {
    display: flex;
    margin-bottom: 5em;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.step-indicator {
    border-radius: 4px;
    display: flex;
    background: #E1EAF5 0% 0% no-repeat padding-box !important;
    border: 1px solid #D0D0D0 !important;
    opacity: 1;
}

.step-forms-container {
    display: block !important;
    margin-right: 30%;
    margin-left: 30%;
    margin-bottom: 3em;
    /* text-align: center; */
}


#abc .MuiAutocomplete-inputFocused {
    color: rgba(0, 0, 0, 0.18) !important;
}


.vehicle-upload-box-description {
    display: inline-flex;
    border: 1px solid rgb(35, 85, 175, 0.22);
    border-radius: 5px;
    width: 100% !important;
    height: 5.5em;
    margin-top: 2.5em !important;
    margin-bottom: 8px !important;
    align-items: center;
}

.uploaded-images {
    display: flex;
    text-align: center;
    width: 110% !important;
    margin-bottom: 1em !important;
}


.field-label-vehicle {
    text-align: left;
    color: #333333;
    font-weight: 500;
    font-family: Barlow;
    font-size: 0.875em;
    margin-bottom: 10px;
    opacity: 1;
}

.field-label-pressure {
    text-align: left;
    color: #333333;
    font-weight: 500;
    font-family: Barlow;
    font-size: 0.875em;
    margin-bottom: 10px;
    opacity: 1;
}

.pressure-label {
    width: 100px;
    margin-top: -2%;
    margin-right: -10%;
    /* text-align: left; */
}

.axle {
    position: relative;
}

.axle .axleNumber-create {
    position: absolute;
    top: 23%;
    color: white;
    right: 44%;
}
.axleNumber-create-first {
    right: 41% !important;
}
.tire-create {
    width: 91px;
    /* flex-wrap:nowrap; */
}

/* .disabled-input {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: none;
} */

/* .tire-position{
    flex-wrap:nowrap;
} */
/* .axle-position{
    display: inline-flex;
    margin-top: 30px;
    margin-right: -30%;
    margin-left: -30%;
    flex-wrap: nowrap;
    text-align: center;
} */


.manage-vehicle-create-container{
    margin-top: 6.5em;
    padding: 0 2.5%;
    width: auto;
    position: relative;
    /* display: inline-block; */
    margin-bottom: 4em;
    margin-right: auto;
    margin-left: auto;
}

.vehicle-create-header{
    padding: 2em;
    display: flex;
    justify-content: space-between;
    /* margin-left: 25px; */
}

.MuiInputBase-input{
    padding-left: 10px !important;
}
@media only screen and (min-width: 1500px) {
    
    .vehicle-create-header{
        margin-left: 55px;
    }
    .vehicle-images{
        margin-left: 111px !important;
    }
    .create-vehicle-form{
        padding: 2em 7.5%;
    }
    .axel-container-create-vehicle-align-0-1{
        margin-left: 220px !important;
    }
    .axel-container-create-vehicle-align-0-2{
        margin-left: 183px!important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-1-2{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .axel-container-create-vehicle-align-0-3{
        margin-left: 125px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-4{
        margin-left: 80px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 950px) {
    .create-vehicle-form{
        padding: 1em;
    }
    .axel-container-create-vehicle-align-0-1{
        margin-left: 130px !important;
    }
    .axel-container-create-vehicle-align-0-2{
        margin-left: 30px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-3{
        margin-left: 30px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-4{
        margin-left: -39px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 750px) {
    .create-vehicle-form{
        padding: 0.5em;
        display: block;
    }
    .axel-container-create-vehicle-align-0-3{
        margin-left: -8px !important;
        margin-right: 0px !important;
    }
    .axel-container-create-vehicle-align-0-4{
        margin-left: 3px !important;
        margin-right: 0px !important;
    }
}

.left-10{
    margin-left: 10px;
}
.MuiStepConnector-alternativeLabel {
    width: -webkit-max-content !important;
    width: max-content !important;
    left: calc(-50% + 190px) !important;
    right: calc(50% + 30px) !important;
}
.standalone-header-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    /* position:absolute; */
}

.create-location-list-container {
    margin-top: 8em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.create-location-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.create-location-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
    /* margin-top: -30px; */
}

.create-location-inner-container {
    margin-left: 28px;
    margin-top: 30px;
    margin-bottom: 15px;
    height: 100%;
    width: 100%;
}

.background-map-image-create-location {
    position: absolute !important;
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.create-sub-header {
    text-align: left;
    color: #194cab;
    opacity: 1;
    font-family: Barlow;
    font-size: 22px;
    margin-bottom: 10px;
    /* margin-top: 25px; */
}
.edit-details-message{
    font-weight: 400 !important;
    font-style: italic;
    color: #b9b8b8 !important;
}

.create-location-details-grid {
    margin-bottom: 1em;
}

.parent-details-grid {
    margin-bottom: 1em;
}

.field-label {
    -webkit-margin-after: 1px;
            margin-block-end: 1px;
}


.edit-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    width: 90%;
    height: 45px;
    align-self: center;
    opacity: 1;
}

.comment-input-box {
    border-radius: 4px;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    height: 45px;
    align-self: center;
    opacity: 1;
    width: 95%;
}

.action-buttons-create-location {
    text-align: right !important;
}

.pressure-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    width: 95%;
    height: 45px;
    /* align-self: center; */
    opacity: 1;
}

/* .create-list-container {
    position: absolute;
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: -43em;
} */


.disabled-input-location-form .MuiInputBase-root.Mui-disabled {
    background: #F5F5F5;
    color: #000000 !important;
    border: none !important;
}

.hr-divider {
    border: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 2%;
}

@media only screen and (max-width: 950px) {
    .create-location-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */

/* @media only screen and (min-width: 1500px) {
    .create-location-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.location-mb{
    margin-bottom: -37px;
}

.MuiInput-underline:before{
    position: unset !important;
}

.parent-name-create-location{
    height: 38px !important;
    
}
#parent-name-create-location{
    font-size: 1rem;
}

.disable-input-location{
    background: #eaeaea 0% 0% no-repeat padding-box;
    border: none !important;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.quick-inspect-list-container-inspection {
    margin-top: 7.0em;
    padding: 0 3%!important;
    width: 25%!important;
    /* position: fixed; */
    max-height: 100vh;
    display: table-cell;
    margin-bottom: 4em;
    overflow-y: scroll;
    overflow-x: hidden;
    
}
/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .quick-inspect-list-container-inspection{
        padding: 0 2% 0 2.5%!important;
        width: 24%!important;
    }
}
.quick-inspect-list-container-vehicle-inspection {
  
    padding: 7.0em 2.5%;
    width: 76%;
    position: relative;
    display: table-cell;
    margin-bottom: 4em;
}

.quick-inspect-list-container-vehicle {
    /* position: absolute; */
    margin-top: -2.0em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.quick-inspect-list-card {
    border-radius: 14px !important;
}

.quick-inspect-upload-box-description {
    display: inline-flex;
    border: 1px solid rgb(35, 85, 175, 0.22);
    border-radius: 5px;
    width: 50% !important;
    height: 5.5em;
    margin-top: 2.5em !important;
    margin-bottom: 8px !important;
    align-items: center;
}

.upload-label {
    font: normal medium 18px/22px Barlows,'Noto Sans';
    font-weight: bold !important;
    text-align: center;
    letter-spacing: -0.43px;
    color: #333333;
    opacity: 1;
}


/* MID SCREENS */

@media only screen and (max-width: 950px) {
    .quick-inspect-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */

/* @media only screen and (min-width: 1500px) {
    .quick-inspect-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.quick-inspect-main-container {
        background: #f2f2f2 0% 0% no-repeat padding-box;
        opacity: 1;
        height: 90%;
        position: absolute;
        width: 100%;
}
.button-nav {
    color: #2355AF !important;
    border: 1px solid #2355AF !important;
    border-radius: 5px !important;
    opacity: '0.44' !important;
    background: #C4DCFF2E 0% 0% no-repeat padding-box;
    /* margin: 10px; */
    width: 100px;
}
.selected-row-vehicle-id{
    background:#2355AF;
    color:white!important;
}
.grid {
    margin-top: 30px;
}
.clickable-id-inspection-history{
    color: #0055A4 !important;
    font-weight: 500 !important;
    cursor: pointer;
  }
.search-table-container-quick-inspect {
    padding-bottom: 10px !important;
    /* padding-left: 9px; */
}

.search-table-container1 {
    width: 13.5em;
}

/* .field-label-inspect {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/40px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal medium 14px/40pxBarlow;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}
*/

.input-label-inspect {
    font-family: Barlow, Noto Sans !important;
    text-align: left;
    font-size: 16px!important;
    letter-spacing: 0px;
    color: #332F2E !important;
    margin-top: 50px;
    margin-left: 130px;
}

.input-label-span {
    font-family: Barlow, Noto Sans !important;
    text-align: center;
    text-decoration: underline;
    font-size: 16px!important;
    letter-spacing: 0px;
    color: #005EB4 !important;
    
}
.tireimage{
    margin-bottom: -15px;
}
.edit-comment-input-box {
    border: 1px solid rgba(141, 141, 141, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px!important;
    color: black !important;
    width: 450px;
    height: 120px;
    align-self: center;
    opacity: 1;
}

.no-data-container {
    padding-top: 5%;
}
.tireImg {
  width: 100%;
  height: 100%;
}
.rimOnlyImg {
  width: 90%;
  height: 100%;
}
.tire-container {
  position: relative;
  width: 7em;
  text-align: center;
  margin-right: 1px;
  border-radius: 12px;
  border: 2px solid rgb(214, 214, 214);
  height: 15.625em;
  color: white;
  opacity: 1;
}
/* .tire-container:hover {
  opacity: 1;
} */

.textArea {
  width: 100%;
  height: 100px;
  font-family: Barlow;
  font-size: 1em;
}
.tire-container-yellow {
  position: relative;
  background-color: #f0de00;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-red {
  position: relative;
  background-color: #ff0100;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-green {
  position: relative;
  background-color: #16d513;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-orange {
  position: relative;
  background-color: #ffa502;
  width: 205px;
  text-align: center;
  margin-right: 1px;
  border-radius: 9px;
  border: 2px solid #0b3eaf;
  height: 220px;
  color: white;
}

.tire-container-empty {
  position: relative;
  width: 7em;
  text-align: center;
  margin-right: 1px;
  border-radius: 12px;
  height: 15.625em;
  color: white;
  opacity: 0.9;
}

.tire-container-temporary {
  position: relative;
  width: 7em;
  text-align: center;
  margin-right: 1px;
  border-radius: 12px;
  border: 2px solid rgb(214, 214, 214);
  height: 15.625em;
  color: rgb(239, 239, 239);
  opacity: 0.9;
}

.switch-container {
  /* background-color: #77acff87; */
  width: 6em !important;
  border-radius: 15px;
  padding: 0 !important;
}
.switch-container-blue {
  background-color: #58cce8;
  opacity: 0.8;
}
.switch-container-blue:hover {
  opacity: 1;
}
.switch-container-red {
  background-color: #d14449;
  opacity: 0.8;
}
.switch-container-red:hover {
  opacity: 1;
}
.axleConfigBar {
  border: none;
}
.axle-inspect {
  position: relative;
  top: 15%;
}
.axle-inspect .axleNumber {
  position: absolute;
  top: 11%;
  color: white;
  right: 46%;
}

.img-background-tire {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.centered {
  position: absolute;
  top: 49%;
  width: 5.5em;
  text-align: center;
  left: 52%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.axleImg {
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.axle-position {
  align-items: center;
  display: inline-grid;
  margin-bottom: 50px;
  margin-top: 50px;
}

.width-images-quick-inspect-tire {
  width: 900px;
}
@media screen and (min-width: 1366px) {
  .width-images-quick-inspect-tire {
    width: 980px;
  }
}
@media screen and (min-width: 1920px) {
  .width-images-quick-inspect-tire {
    width: 1200px;
  }
}
@media screen and (min-width: 2000px) {
  .width-images-quick-inspect-tire {
    width: 1250px;
  }
}
.field-tire {
  text-align: center;
  font-family: Barlow;
  font-size: 0.75em;
  /* font-size: 0.79em; */
  display: block;
  font-weight: bold;
  margin: 3px;
  opacity: 1;
  /* width: 100%; */
}

.update-hour-inner-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tooltipicon {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
  align-self: center;
}

.tooltip-word {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
  align-self: center;
  color: #cfbe23bb;
}
.tooltip-word:hover {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
  align-self: center;
  color: #ebd512d5;
}

.info-msg {
  display: block;
  position: absolute;
  background: rgb(195 221 255);
  top: 20%;
  right: 15%;
  padding-bottom: 15px;
  height: 17%;
  border-radius: 15px;
  width: 40%;
  box-shadow: 3px 10px 18px #88888865;
}
.info-icon:hover ~ .info-msg {
  display: block;
}

.tireimage {
  height: 50%;
  width: 20%;
  padding-top: 10px;
}

.tire-link-label {
  font-weight: 600;
}

.edit-tire-btn {
  background: transparent;
  opacity: 0.6;
  font: Barlows,'Noto Sans';
  font-weight: 600;
  z-index: 0;
  box-shadow: none;

  margin-top: 5px;
  margin-bottom: 5px;
  color: #0d54b5;
  border: 1px solid #0d54b5;
  border-radius: 20px;
  word-wrap: break-word;
  padding: 2px;
  width: 6.6em;
}
.edit-tire-btn:hover {
  background-color: transparent;
  opacity: 1;
}

.view-tire-btn {
  background: transparent;
  opacity: 0.4;
  font: Barlows,'Noto Sans';
  font-weight: 600;
  z-index: 0;
  box-shadow: none;
  margin-bottom: 5px;
  color: #0d54b5;
  border: 1px solid #0d54b5;
  border-radius: 20px;
  word-wrap: break-word;
  padding: 2px;
  width: 6.6em;
}
.view-tire-btn:hover {
  background-color: transparent;
  opacity: 1;
}

.inspect_vehicle-hr-divider {
  margin-top: 10px;
  border: 1px solid #eee;
}

.onClick-link-tire-serial-number {
  margin-top: 3px;
  margin-bottom: 2px;
  /* color: #0d54b5; */
  word-wrap: break-word;
  padding: 1px;
  width: 11em;
  font-size: 10px;
  text-align: center;
}
/* .onClick-link-tire-serial-number:hover {
  font-weight: 500;
} */

.onClick-link-rim-serial-number {
  margin-bottom: 2px;
  color: #0d54b5;
  word-wrap: break-word;
  padding: 2px;
  width: 9em;
  font-size: 12px;
}
.onClick-link-rim-serial-number:hover {
  font-weight: 500;
}

.unClickable-link-rim-serial-number {
  margin-bottom: 2px;
  color: #000000;
  word-wrap: break-word;
  padding: 2px;
  width: 9em;
  font-size: 12px;
}
.onClick-link {
  margin-top: 3px;
  margin-bottom: 5px;
  margin-left: 5px;
  color: #0d54b5;
  word-wrap: break-word;
  padding: 2px;
  width: 6.6em;
}
.onClick-link:hover {
  font-weight: 500;
  /* border-Radius: 10px;
    background-color: #EAF3FB; */
  opacity: 1;
}

.plus{
    width: 30%;
    min-width: 30%;
    background-color: green ;
    color:aliceblue;
}
.minus{
    width: 30%;
    min-width: 30%;
    color:gray;
}
.digit{
    width: 30%;
    color:black;
    min-width: 30%;
}
.inspect-tire-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.background-map-image-inspect-tire {
  position: absolute !important;
  background-image: url(/static/media/map.337e1012.svg) !important;
  background-repeat: no-repeat, no-repeat !important;
  background-position: right !important;
  height: 100% !important;
  width: 100% !important;
}

.inspect-tire-container {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1;
}

.inspect-tire-inner-container {
  margin: 0px 0px 20px 0px;
  height: 100%;
  width: 100%;
}

.vehicle-detail-container {
  border-radius: 14px;
  height: 20%;
  width: 100%;
  background-color: #faf9f2;
  overflow: auto;
}

.vehicle-details-grid {
  margin: 30px;
}

.item-detail-label {
  font: normal normal bold 0.9em/2.5em Barlows,'Noto Sans';
  margin-bottom: -0.3em;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.item-detail-content {
  font: normal normal normal 0.9em/2.5em Barlows,'Noto Sans';
  margin-top: -0.3em;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.inspect-tire-form-container {
  display: block !important;
  margin-right: 30%;
  margin-left: 30%;
  margin-bottom: 3em;
  text-align: center;
}

.form-inner-container {
  width: 70% !important;
  margin-right: 15% !important;
  margin-left: 15% !important;
}

.enter-detail-input-box input {
  font-weight: 500 !important;
  color: black !important;
}

.enter-detail-input-box select {
  font-weight: 500 !important;
  color: black;
}

.enter-detail-input-box select:not(:valid) {
  color: rgba(0, 0, 0, 0.5);
}
.enter-detail-input-box select .list-color-change {
  color: black;
}

.buttoned-input {
  text-align: center !important;
}

.upload-box-description-inspect-tire {
  display: flex;
  border: 1px solid rgb(35, 85, 175, 0.22);
  border-radius: 5px;
  width: 100% !important;
  height: 5.5em;
  margin-top: 1.5em !important;
  margin-bottom: 0px !important;
  align-items: center;
}

.remove-box-description {
  display: flex;
  border: 1px solid rgb(35, 85, 175, 0.22);
  border-radius: 5px;
  width: 100% !important;
  height: 5.5em;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  align-items: center;
}

.remove-tire-label-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #393939;
}

.tooltip-icon-remove-tire {
  width: 1.1em;
  vertical-align: middle;
  margin: 0px 10px;
}

/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.admin-list-container {
  /* position: absolute; */
  margin-top: 14em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 2%;
}
.text-area1 {
  width: 30% !important;
  height: 40px;
}
.css-disable {
  background-color: #f5f5f5 !important;
}
.padding-css {
  padding-top: 15px !important;
}
.tab-request-container {
  height: 3em;
  margin: 1em;
  font-size: 1em;
  font-family: Barlows,'Noto Sans';
  border-bottom: 2px solid #dedede;
  width: auto !important;
  margin-left: 0em !important;
  margin-right: 0em !important;
}
.clicked-active-tab-request {
  /* border-bottom: 2px solid #27539b !important; */
  font-weight: 600;
  color: #0059ac !important;
}
.tab-request-label {
  text-align: center;
  border-right: 2px solid #dedede;
  padding-top: 0.75%;
  cursor: pointer;
}
.tire-search-table-container {
  width: 15em;
  margin-left: 10px;
}
.grid-add-delete-container {
  margin: 0.7em 2em !important;
}
.grid-add-delete-input-container {
  margin-top: 2em !important;
}
.mandatory-sign-add-new {
  color: red;
}
.add-new-delete-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  width: 30% !important;
  font-weight: 600 !important;
}
.search-admin {
  width: 100%;
}
.admin-updateAccount-details {
  color: #0059ac !important;
  border: 1px solid #77acff;
  /* opacity: 0.44; */
  /* width: 30%; */
  height: 3em;
  padding: 5px;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 500 !important;
}
.admin-action-dropdown {
  color: #0059ac !important;
  /* background: #C4DCFF2E 0% 0% no-repeat padding-box; */
  border: 1px solid #77acff !important;
  opacity: 0.44;
  width: 100%;
  box-sizing: unset;
  border-radius: 4px;
  font-weight: 500 !important;
}
.admin-list-card {
  border-radius: 14px !important;
}
.admin-list-card1 {
  border-radius: 14px !important;
  height: 700px;
}

/* to limit the size on image of quill editor */
.ql-editor img {
  height: 50%;
  width: 50%;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .admin-list-container {
    margin-top: 10em;
  }
  .active-other-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  .other-service-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  .button-other-nav-container {
    overflow-x: scroll;
  }
  .other-service-nav-container {
    overflow-x: scroll;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  /* .admin-list-container{
        padding: 0 7.5%;
        width: 85%;
    } */
  .button-other-nav-container {
    display: flex;
    overflow-x: hidden;
  }
  .other-service-nav-container {
    display: flex;
    /* margin: 0 7.5%; */
    overflow-x: hidden;
  }
  .button-navigation-others {
    width: 35px;
    height: 35px;
  }
}

.badge-style {
  background-color: #b52a2a !important;
}

.active-other-nav-card {
  vertical-align: super;
  /* background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important; */
  height: 2.5em;
  /* border-radius: 0.5em !important; */
  color: white !important;
  margin-right: 1em;
  /* border-bottom: 2px solid #27539b; */
  cursor: pointer !important;
  white-space: nowrap !important;
  /* border-right: 2px solid #dedede; */
  font-weight: 600;
}

/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
  .active-other-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
  .other-service-nav-card {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .other-service-nav-container {
    overflow-x: auto !important;
  }
}
.button-navigation-others {
  border-radius: 50%;
  opacity: 0.9;
  border: 1px solid #77acff;
  margin-top: 1.1em;
  width: 30px;
  height: 30px;
}
.button-navigation-others:active {
  border-color: none;
}
.button-navigation-others:focus {
  border-color: none;
  outline: none;
}

.other-service-nav-card {
  height: 2.5em;
  color: white !important;
  margin-right: 1em;
  cursor: pointer !important;
  white-space: nowrap !important;
  /* border-right: 2px solid #dedede;
    border-bottom: 2px solid #dedede; */
}

.others-card-text {
  vertical-align: super;
  font-size: 1em;
  color: #24242480;
  font-family: Barlows,'Noto Sans';
}

.button-other-nav-container {
  display: flex;
  overflow-x: hidden;
}
.other-service-nav-container {
  display: flex;
  /* margin: 0 2.5%; */
  overflow-x: hidden;
  width: 100%;
}
.other-service-nav-container::-webkit-scrollbar {
  display: none;
}

.action-tab {
  width: 100%;
}

.text-area {
  width: 40% !important;
}

.inside-fields {
  margin-top: 1.5em !important;
}
.enter-detail-input-box textarea {
  font-weight: 600 !important;
  color: black !important;
}
.table-content-rich-text-editor {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

@media only screen and (min-width: 1501px) and (max-width: 2648px) {
  .admin-container-height {
    height: 62rem;
  }
}


.no-data-container {
    padding-top: 5%;
}
.admin-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* height: 150%; */
    position: absolute;
    width: 100%;
    /* Footer fix */
    height: 100%
}

.admin-upper-deck{
    position: absolute;
    width: 100%;
    height: 64%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container-admin-view{
    margin-right: 1em;
}
.background-map-image-admin-view{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .manage-button-navigation-style{
        display: none;
    }
    .admin-nav-container{
        overflow-x: auto!important;
    }
}
.manage-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6em
}
.manage-button-navigation-style:active{
    border-color: none;
}
.manage-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.admin-title{
    color: white;
    font-family: Barlow;
    margin-top: 0.25em;
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.manage-button-admin-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.admin-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.admin-nav-card-container{
    margin-right: 1em;
}
.admin-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.admin-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}
.admin-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-admin-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.admin-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-admin-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .admin-main-container{
        height: 110%;
    }
    .admin-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-admin-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .admin-nav-card-text{
        font-size:1.2em;
    }
    .active-admin-nav-card-text{
        font-size:1.2em;
    }
    .admin-title{
        font-size: 1.4em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .admin-main-container{
        height: 100%;
    }
    .admin-nav-container{
        overflow-x: scroll;
    }
    .manage-button-admin-nav-container{
        overflow-x: scroll;
    }
    .admin-nav-card-container{
        margin-right: 1em;
    }
   
    .admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}



/* SUB CARD */
.manage-button-admin-nav-sub-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.admin-nav-sub-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.admin-nav-card-sub-container{
    margin-right: 1em;
}
.admin-nav-sub-card{
    /* background: rgba(0, 0, 0, 0.2) !important;  */
    /* box-shadow: 0px 3px 6px #00000029 !important; */
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.admin-nav-sub-card-text:hover{
    /* background: rgba(0, 0, 0, 0.4) !important;  */
    color:#ffdc00;
}
.admin-nav-sub-container::-webkit-scrollbar {
    display: none;
  }
.active-admin-nav-sub-card{
    vertical-align: super;
    /* background: rgba(48, 148, 206, 0.459) !important;  */
    /* box-shadow: 0px 3px 6px #00000029 !important; */
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.admin-nav-sub-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:rgba(255, 255, 255, 0.411);
}
.active-admin-nav-sub-card-text{
    vertical-align: super;
    font-size:1em;
    color:#ffdc00;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .admin-main-container{
        height: 110%;
    }
    .admin-nav-sub-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-admin-nav-sub-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .admin-nav-sub-card-text{
        font-size:1.2em;
    }
    .active-admin-nav-sub-card-text{
        font-size:1.2em;
    }
    .admin-title{
        font-size: 1.4em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .admin-main-container{
        height: 100%;
    }
    .admin-nav-sub-container{
        overflow-x: scroll;
    }
    .manage-button-admin-nav-sub-container{
        overflow-x: scroll;
    }
    .admin-nav-sub-card-sub-container{
        margin-right: 1em;
    }
   
    .admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-admin-nav-sub-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}


.admin-list-card {
    min-height: 350px;
}

.action-buttons {
    padding: 10px;
    text-align: right;
    margin-right: 2em;
}

.hr-divider-action {
    border: 1px solid #e3e3e391;
    margin-top: 20px;
}
#height-css {
  height: 600px;
}
#header-change-modal-multi-selectV {
  width: 30%;
}
.multi-report-inner-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: -moz-available;
}
#select-report {
  color: black !important;
}

.header-change-modal-confirm-btn {
  margin: 7px;
}
.remove-tire-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.background-map-image {
    position: absolute;
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
}

.remove-tire-container{
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.remove-tire-inner-container {
    margin: 0px 0px 20px 0px;
    height: 100%;
    width: 100%;
}



/* .required-mark {
    color: red;
} */


/* .buttoned-input{
    text-align: center !important;
} */

/* .disabled-input {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: none !important;
} */
.remove-tire-main-page-container {
  opacity: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.background-map-image {
  position: absolute;
  background-image: url(/static/media/map.337e1012.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}

.remove-tire-container {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 14px;
  height: auto;
  z-index: 1;
}

.remove-tire-inner-container {
  margin: 0px 0px 20px 0px;
  height: 100%;
  width: 100%;
}

.all-tire-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-line {
  margin: 0 23px;
}
/* .required-mark {
    color: red;
} */

/* .buttoned-input{
    text-align: center !important;
} */

/* .disabled-input {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: none !important;
} */

.image-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .vehicle-diagram-div {
  transform: rotate(90deg);
} */

.axle-image {
  opacity: 1;
  border-left: 6px solid #353a94;
  height: 100px;
  margin-left: 31px;
}
.display-inline-block {
  margin-top: -0.8em;
  padding: 0.8em;
  display: inline-block;
}
.display-inline {
  display: inline-block;
}
.tire-table {
  margin-top: 1em;
}
.tire-axle-number {
  margin-top: -0.5em;
  margin-bottom: -0.7em;
}
.remove-tire-empty-tire {
  width: 66px;
}

.worn-card-image {
  margin-left: 5%;
  margin-right: 5%;
}

.axle-0 {
  padding-left: 1.2em;
}
.axle-1 {
  padding-left: 1.7em;
}
.axle-2 {
  padding-left: 1.9em;
}
.axle-3 {
  padding-left: 2em;
}

.axle-4 {
  padding-left: 1.5em;
}
.axle-5 {
  padding-left: 1.9em;
}
.axle-6 {
  padding-left: 1.8em;
}
.axle-7 {
  padding-left: 1.7em;
}
.axle-8 {
  padding-left: 1.8em;
}

.remove-all-tire-form-selected-tire {
  opacity: 1;
}

.remove-all-tire-form-not-selected {
  opacity: 0.4;
}

.rotate-vehicle-diagram {
  margin-bottom: 1em;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-vehicle-installed-tires-veiw {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.view-vehicle-vehicle-history {
  /* position: absolute; */
  margin-top: -1em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

.quick-inspect-list-card-vehicle {
  border-radius: 14px !important;
}
.axle {
  position: relative;
}
.axle .axleNumber {
  position: absolute;
  top: 20%;
  color: white;
  right: 41%;
}
.tire {
  width: 60px;
  /* flex-wrap:nowrap; */
}

.location-details-grid-view-vehicle {
  margin: 0 2em 2em 1.5em;
  margin-bottom: 2em;
}

/* MID SCREENS */

@media only screen and (max-width: 950px) {
  .view-vehicle-installed-tires-veiw {
    margin-top: 10em;
    width: 95%;
  }

  .view-vehicle-form {
    padding: 2em;
  }
}

/* LARGE SCREENS */

@media only screen and (min-width: 1500px) {
  .view-vehicle-form {
    padding: 2em 7.5%;
  }
  .view-vehicle-installed-tires-veiw {
    width: 85%;
  }
  .view-vehicle-vehicle-history {
    width: 85%;
  }
}

.table-toolbar-label-text-view-vehicle {
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-family: Barlow, Noto Sans !important;
  text-align: left;
  margin: 10px;
  margin-left: 20px;
  font-size: 18px !important;
}

@media only screen and (max-width: 750px) {
  .view-vehicle-form {
    padding: 0.5em;
    display: block;
  }
}
.axleNumber-create-first-view {
  right: 45% !important;
}

.vehicle-img {
  border: 1px solid #707070;
  border-radius: 7px;
  padding: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mar-left {
  margin-left: 20px;
}

.axcel-top-mar {
  margin-top: -20px !important;
}
.field-label-view-vehicle {
  font-weight: 400;
  text-align: left;
  color: #333333;
  font-family: Barlow;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 10px;
  opacity: 1;
}


.search-table-container {
    padding-bottom: 10px;
    /* padding-left: 9px; */
}


/* .field-label-inspect {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/40px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal medium 14px/40pxBarlow;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}
*/

.input-label-span {
    font-family: Barlow, Noto Sans !important;
    text-align: center;
    text-decoration: underline;
    font-size: 16px!important;
    letter-spacing: 0px;
    color: #005EB4 !important;
    
}

.no-data-container {
    padding-top: 5%;
  }
.vehicle-view-header{
    padding: 2em;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
}

.view-vehicle-upper-deck{
    position: absolute;
    width: 100%;
    height: 26em;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}

.background-map-image-view-vehicle{
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .manage-button-navigation-style{
        display: none;
    }
    .manage-tires-nav-container{
        overflow-x: auto!important;
    }
}
.manage-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6em
}
.manage-button-navigation-style:active{
    border-color: none;
}
.manage-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.manage-tires-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    margin-top: 0.25em;
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.manage-button-manage-vehicle-veiw-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.manage-tires-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.manage-tires-nav-card-container{
    margin-right: 1em;
}
.manage-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.manage-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important;
}
.manage-tires-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-manage-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .manage-tires-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-manage-vehicle-veiw-nav-container{
        display: flex;
        margin: 0 7.5%;
        overflow-x: hidden;
    }
    .manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .manage-tires-title{
        font-size: 1.4em;
    }
    .vehicle-view-header{
        margin-left: 60px;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .manage-tires-nav-container{
        overflow-x: scroll;
    }
    .manage-button-manage-vehicle-veiw-nav-container{
        overflow-x: scroll;
    }
    .manage-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-parent-account-list-container {
    margin-top: 8.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.view-parent-account-action-dropdown {
    color: #0059AC !important;
    border: 1px solid #77ACFF;
    opacity: 0.44;
    width: 70%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
}


    .view-parent-account-location-list-heading {
        font-size: 1.2em;
        font-family: Barlows,'Noto Sans';
        font-weight: 500;
    }



.view-parent-account-list-card {
    border-radius: 14px !important;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .view-parent-account-list-container {
        margin-top: 0em
    }
}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {

    .view-parent-account-list-container {
        padding: 0 7.5%;
        width: 85%;
    }
} */

.view-parent-acct-list-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 90%;
    position: absolute;
    width: 100%;
  }
.no-data-container {
    padding-top: 5%;
  }
  .background-map-image {
    position: absolute;
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
  }

  .view-parent-list-container{
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom: 4em;
  }
  .success-message-pref {
    background-image: url(/static/media/profile_success.9ddfda34.svg);
    background-color: rgba(157, 255, 161, 0.24);
    background-repeat: no-repeat;
    background-size: 20px 45px;
    border-radius: 28px;
    width: 18%;
    height: 45px;
    color: #048809;
    font-family: Barlow,light, Noto Sans;
    font-size: 14px;
    line-height: 45px;
    text-align: center;
    background-origin: padding-box;
  }

  .sub-edit-textbox {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    height: inherit;
    padding: 0;
    margin: 0;
    font-family:Barlow,light, Sans-serif !important;
    font-size: 14px!important;
  }

  .inputClass{
    display: none;
  }

  .selectedImg{
    margin-right: 10em;
  }

  .view-parent-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.view-parent-upper-half {
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.view-parent-breadcrumbs {
    text-align: left;
    margin: 2em;
}


.view-parent-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.view-parent-inner-container {
    margin: 20px;
    height: 100%;
    width: 100%;
}


.view-parent-account-main-page-container{
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.view-parent-account-account-details-grid{
    margin-bottom: 3em;
}

.view-parent-account-action-buttons {
    text-align: left;
    margin-right: 2em;
}

  
  .background-map-image-view-location {
    position: absolute !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
  }

  .view-location-container{
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom: 4em;
  }

.edit-details-message {
    text-align: left;
    color: #b9b8b8;
    opacity: 1;
    font-weight: 400;
    font-family: Barlow, Noto Sans;
    font-size: 15px;
    letter-spacing: 0.14px;
    margin-bottom: 20px;
    font-style: italic;
}

.account-detail-grid{
    margin-bottom: 1em;
}

.field-title{
  text-align: left;
    color: #194CAB;
    font-weight: 500;
    font-family: Barlow;
    font-size: 16px;
    margin-bottom: 10px;
    opacity: 1;
}

.view-location-action-buttons {
    text-align: left;
    margin-top: 2em;
}

.view-location-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 90%;
  position: absolute;
  width: 100%;
}
.rim-container{
    height:130em;
    background-color: #f2f2f2;
}
.create-rim-main-page-container{
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}
.rim-upper-deck{
    height:100%;
    background-color: #f2f2f2;
}

.create-rim-upper-half {
    display: flex;
    position: absolute;
    height: 18em;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.background-image-rim-deck{
    height:20%;
    background: transparent linear-gradient(161deg, #0D54B5  0%, #003584 100%) 0% 0% no-repeat padding-box;
   }

.background-image-rim-dashboard{
    position: absolute;
  background-image: url(/static/media/map.337e1012.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}


/* .rim-create-header{
    padding: 2em;
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
}

@media only screen and (min-width: 1500px) {
    
    .rim-create-header{
        margin-left: 55px;
    }
} */

.MuiStepper-root{
    cursor: pointer
}
.MuiStepConnector-alternativeLabel {
    width: -webkit-max-content !important;
    width: max-content !important;
    left: calc(-50% + 190px) !important;
    right: calc(50% + 30px) !important;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.manage-rim-list-container{
    margin-top: 7.5em;
    padding: 0 2.5%;
    width: 95%;
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.manage-rim-action-dropdown{
    color: #0059AC!important;
    border: 1px solid #77ACFF;
    opacity: 0.44;
    width: 70%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500!important;
}
.manage-rim-list-card{
    border-radius: 14px!important;
}
/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .manage-rim-list-container{
      margin-top: 6em
    }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    
    /* .manage-rim-list-container{
        padding: 2em 7.5%;
        width: 85%;
    } */
    .rim-list-header{
        margin-left: 60px !important;
    }
}

.manage-rim-main-page-container{
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.manage-rim-upper-half {
    display: flex;
    position: absolute;
    height: 18em;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.rim-list-header{
    padding: 2em;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
}

.enable-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
  }
  .disabled-table-toolbar-label-text {
    font-family: Barlows,'Noto Sans';
    font-weight: 500;
    color: #dfe0e0;
  }
  .enable-manage-vehicle-action-dropdown {
    color: #0059ac !important;
    border: 1px solid #77acff;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 500 !important;
  }
  
  .disabled-manage-vehicle-action-dropdown {
    color: #dfe0e0 !important;
    border: 1px solid #dfe0e0;
    width: 60%;
    box-sizing: unset;
    border-radius: 4px;
    font-weight: 400 !important;
  }
  
.action-option:hover {
    background-color: #b1d2ff;
  }
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-rim-main {
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.view-vehicle-vehicle-history {
    margin-top: -1.0em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

.view-rim-list-card {
    border-radius: 14px!important;
}
.axle{
    position:relative;
}
.axle .axleNumber{
    position: absolute;
    top: 20%;
    color: white;
    right: 41%;
}
.tire{
    width:60px;
}

.location-details-grid-view-rim{
    margin: 0 2em 2em 1.5em !important;        
    margin-bottom: 2em !important;
}

/* MID SCREENS */

@media only screen and (max-width: 950px) {
    .view-rim-main {
        margin-top: 10em;
        width: 95%;
    }

    .view-vehicle-form{
        padding: 2em;
    }
}

/* LARGE SCREENS */

@media only screen and (min-width: 1500px) {
    .view-vehicle-form{
        padding: 2em 7.5%;
    }
    .view-rim-main {
        width: 85%;
    }
    .view-vehicle-vehicle-history{
        width: 85%;
    }
}


@media only screen and (max-width: 750px) {
    .view-vehicle-form{
        padding: 0.5em;
        display: block;
    }
}

.background-img-container-view-rim{
    margin-right: 1em;
}
.background-map-image-view-rim{
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important; 
    height: 20em !important;
    width: 100% !important;
}/* LARGE SCREENS */

.manage-vehicle-upper-deck{
    position: absolute;
    width: 100%;
    height: 25em;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container-view-tire{
    margin-right: 1em;
}
.background-map-image-view-tire{
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important; 
    height: 20em !important;
    width: 100% !important;
}/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .manage-button-navigation-style{
        display: none;
    }
    .manage-tires-nav-container{
        overflow-x: auto!important;
    }
}
.manage-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6em
}
.manage-button-navigation-style:active{
    border-color: none;
}
.manage-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.manage-tires-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    margin-top: 0.25em;
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.manage-button-manage-tires-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.manage-tires-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.manage-tires-nav-card-container{
    margin-right: 1em;
}
.manage-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.manage-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important;
}
.manage-tires-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-manage-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .manage-tires-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-manage-tires-nav-container{
        display: flex;
        margin: 0 2.5%;
        overflow-x: hidden;
    }
    .manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .manage-tires-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .manage-tires-nav-container{
        overflow-x: scroll;
    }
    .manage-button-manage-tires-nav-container{
        overflow-x: scroll;
    }
    .manage-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.view-tire-installed-tires {
  margin-top: 14em;
  padding: 0 2.5%;
  width: 95%;
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.view-tire-history-table {
  margin-top: -1em;
  padding: 0 2.5%;
  width: 95%;
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}
.table-function-title-view-tire {
  width: 100%;
  text-align: end;
  margin-bottom: 10px;
}
.quick-inspect-list-card-tire {
  border-radius: 14px !important;
}

.tire {
  width: 60px;
}

.location-details-grid {
  margin: 0 2em 2em 2em;
}

.uploaded-images-view {
  text-align: center;
  margin-bottom: 1em !important;
}

.view-tire-history-label {
  font-size: 20px !important;
}

.view-tire-graph-container {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  align-items: center;
  flex-direction: column;
  display: inline-flex;
}

.label-tire-details {
  font-weight: 600 !important;
}

/* MID SCREENS */

@media only screen and (max-width: 950px) {
  .quick-inspect-list-container {
    margin-top: 0em;
  }
}

/* LARGE SCREENS */

/* @media only screen and (min-width: 1500px) {
  .quick-inspect-list-container {
    padding: 0 7.5%;
    width: 85%;
  }
  .view-tire-installed-tires {
    padding: 0 7.5%;
    width: 85%;
  }
  .view-tire-history-table {
    padding: 0 7.5%;
    width: 85%;
  }
} */


.search-table-container {
    padding-bottom: 10px;
}

.input-label-span {
    font-family: Barlow, Noto Sans !important;
    text-align: center;
    text-decoration: underline;
    font-size: 16px!important;
    letter-spacing: 0px;
    color: #005EB4 !important;
    
}


.table-toolbar-label-text-view-tire{
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-family: Barlow, Noto Sans !important;
    text-align: left;
}

.no-history-found{
    padding-left: 25%;
}

.table-content-text {
    color: #404040 !important;
    font-size: 0.85em !important;
    font-family: Barlow, Noto sans !important;
}

.no-data-container {
    padding-top: 5%;
  }
.edit-history-modal-container {
    border-radius: 0.5em;
    width: 60%;
    background: white;
}


.tire-history-title-container {
    display: flex;
    margin-left: 2em;
}

.edit-history-static-modal-description {
    padding: 2em;
    border-radius: 0.5em;
    background: #FAF9F2;
    color: #545151;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1.5em 2.5em 0.5em 2.5em;
}

.item-detail-label-edit-history-modal {
    font: normal normal bold 1.1em/2.5em Barlows,'Noto Sans';
    margin-bottom: -0.3em;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.item-detail-content-edit-history-modal {
    font: normal normal normal 1.1em/2.5em Barlows,'Noto Sans';
    margin-top: -0.3em;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}

.edit-history-inner-form-container{
    margin: 0 2.5em 0.5em 2.5em;
    padding: 1em;
}

.field-label-history-modal{
    font-weight: 500;
    text-align: left;
    color: #333333;
    font-family: Barlow;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 2px;
    opacity: 1;
}

.history-modal-action-buttons {
    display: inline-flex;
    justify-content: right;
    margin-left: 75%;
    margin-right: 2em;
    margin-bottom: 2em;
}

.edit-history-helper-message {
    margin-right: 2.5em;
    margin-top: 5px;
    text-align: right;
    font: normal normal normal 12px/20px Barlows,'Noto Sans';
    letter-spacing: -0.29px;
    color: #333333;
}

.central-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);  
}

.tire-graph-modal-container{
    border-radius: 1em;
    height: -webkit-max-content;
    height: max-content;
    width: 50%;
    background: white;
    box-shadow: 0px 3px 6px #00000029;
}

.graph-modal-header{
    display: flex;
}

.graph-modal-title-container{
    display: flex;
    margin-left: 2em;
    width: 100%;
    margin-top : 1.5em;
    flex-direction: column;
    align-items: center;
}

.graph-modal-title{
    width: -webkit-max-content;
    width: max-content;
    font-size: 1.1em;
    font-weight: 600;
    color:#292929;
    font-family: Barlows,'Noto Sans';
    margin: 5px 15px 0px 15px;
}

.graph-modal-action-buttons{
    display: inline-flex;
    justify-content: center;
}

.graph-modal-graph-container{
    font-family: Barlows,'Noto Sans';
    height: 350px;
    margin: 2.5em;
    padding: 1em;
}

.modal-close-icon{
    height: 0.75em;
    width: 0.75em;
    margin-top : 0.75em;
    margin-right : 0.75em;
    cursor: pointer;
}
.return-inv-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.background-map-image-return-to-inv {
    position: absolute;
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
}

/* .remove-tire-container{
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
} */

/* .remove-tire-inner-container {
    margin: 0px 0px 20px 0px;
    height: 100%;
    width: 100%;
} */




.required-mark {
    color: red;
}

.field-label{
    font-weight: 500;
    text-align: left;
    color: #333333;
    font-family: Barlow;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 10px;
    opacity: 1;
}

.additional1{
    float: left;
}

.additional2{
    float: right;
}

.enter-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18) !important;
    border-radius: 4px !important;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans!important;
    font-size: 14px!important;
    color: black !important;
    height: inherit;
    width: 100%;
    align-self: center;
    opacity: 1;
}

.buttoned-input{
    text-align: center !important;
}


.step-costs-inner-container{
    margin-bottom: 1em !important;
}
.delete-tire-modal-container {
    border-radius: 0.5em;
    width: 35%;
    background: white;
}

@media only screen and (max-width: 640px) {
    .delete-tire-modal-container{
        width: 85%;
    }
}


.update-hour-parent-main-container {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.update-hour-parent-upper-half {
    position: absolute;
    height: 30%;
    width: 100%;
    background: transparent linear-gradient(148deg, #0d54b5 0%, #003584 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.update-hour-parent-breadcrumbs {
    text-align: left;
    margin: 2em;
}


.update-hour-parent-container {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 14px;
    height: auto;
    z-index: 1;
}

.update-hour-parent-inner-container {
    height: 100%;
    width: 100%;
}

.header-inner-container {
    margin: 0px;
    height: 103px;
    border-radius: 14px 14px 0px 0px;
    width: 100%;
    background: #FAF9F2 0% 0% no-repeat padding-box;
    opacity: 1;
}

.inner-container {
    width: 80% !important;
    margin-right: 10% !important;
    height: 103px;
    margin-left: 10% !important;
    align-items: center;
    justify-content: space-between;
}

.background-map-image-update-hm {
    position: absolute !important;
    background-image: url(/static/media/map.337e1012.svg) !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
}

.update-hour-details-message {
    text-align: left;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    font-family: Barlow, Noto Sans;
    font-size: 15px;
    letter-spacing: 0.14px;
    margin-bottom: 20px;
}

.update-hour-parent-account-main-page-container {
    opacity: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.disable-field-label{
    text-align: left;
    color: #000;
    font-weight: 500;
    font-family: Barlow;
    font-size: 14px;
    margin-bottom: 10px;
    opacity: 1;
}
.checkbox-field-label{
    text-align: left;
    color: #333;
    font-weight: 500;
    font-family: Barlow;
    font-size: 1em;
    margin-bottom: 10px;
    opacity: 1;
}

.update-hour-detail-input-box {
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: Barlow, Noto Sans !important;
    font-size: 14px !important;
    color: black !important;
    width: 90%;
    height: 45px;
    align-self: center;
    opacity: 1;
}

.field-label-p {
    
    text-align: left;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}
.opcity-zero{
    opacity: 0;
}
.opcity-one{
    opacity: 1;
}
.message {
    color: rgb(27, 27, 27);
    font-size:12pt;
    /* font-weight:bold; */
    text-align:left;
  }
  
  .errorBubble {
    margin-top: 5%;
    margin-right: 20%;
    margin-left: 20%;
    border: 3px solid #3180ca69;
    border-radius:30px;
    background-color:rgb(250, 250, 250);
    padding:15px;
  }
  .background-image-login-grad {
    background: #00000085 linear-gradient(100deg, #164d97 0%, #062149 100%) 0% 0% no-repeat padding-box;
  }
  .background-image-login {
    position: relative;
    background-image: url(/static/media/map.337e1012.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: right;
    height: 100%;
    width: 100%;
    background-size: cover;
  }
  .header-change-modal-title-error {
    font-size: 1.5em;
    color: #0055a4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.change-modal-select-label-error {
    margin-bottom: 0.5em !important;
    font-size: 1.2em !important;
    color: #222222 !important;
    margin-left: 1.6em !important;
    font-family: Barlows,'Noto Sans';

  }

  .em-track-home-page {
    color: #0055a4;
    font-weight: 600;
    padding: 5px;
  }

  .em-track-home-page:hover {
    /* color: red; */
    background-color: #e9f0f7b6;
    padding: 5px;
  }
/* .app-content-container{
    min-height: calc(100vh - 0.3em);
    overflow: auto;
} */


body {
  font-family: Barlows,'Noto Sans';
}


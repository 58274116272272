
.manage-vehicle-upper-deck{
    position: absolute;
    width: 100%;
    height: 25em;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container-view-tire{
    margin-right: 1em;
}
.background-map-image-view-tire{
    background-image: url("../../../assets/map.svg") !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important; 
    height: 20em !important;
    width: 100% !important;
}/* LARGE SCREENS */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .manage-button-navigation-style{
        display: none;
    }
    .manage-tires-nav-container{
        overflow-x: auto!important;
    }
}
.manage-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 6em
}
.manage-button-navigation-style:active{
    border-color: none;
}
.manage-button-navigation-style:focus{
    border-color: none;
    outline: none;
}
.scrap-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.manage-tires-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    margin-top: 0.25em;
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */
.manage-button-manage-tires-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}
.manage-tires-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.manage-tires-nav-card-container{
    margin-right: 1em;
}
.manage-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.manage-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important;
}
.manage-tires-nav-container::-webkit-scrollbar {
    display: none;
  }
.active-manage-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-manage-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}

/* LARGE SCREENS  */
@media only screen and (min-width: 1500px) {
    .manage-tires-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .manage-button-manage-tires-nav-container{
        display: flex;
        margin: 0 2.5%;
        overflow-x: hidden;
    }
    .manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-manage-tires-nav-card-text{
        font-size:1.2em;
    }
    .manage-tires-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .manage-tires-nav-container{
        overflow-x: scroll;
    }
    .manage-button-manage-tires-nav-container{
        overflow-x: scroll;
    }
    .manage-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-manage-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
.center-modal-vehicle-transfer{
    outline: none !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.vehicle-transfer-modal-container{
    border-radius: 0.5em;
    width: 35%;
    background: white;
    overflow: auto;
    max-height: 100%;
}

.transfer-multiple-selected{
    width: 45% !important;
}
.vehicle-transfer-note{
    font: italic normal normal 12px Barlows,'Noto Sans';
    margin-left: 2.3em;
    color: #747474;
}

.location-cl{
    color: black !important;
}

.change-modal-select-label-alert{
    margin-top: 1 rem !important;
    color: #ff4444 !important;

}
.transfer-modal-title-container{
    display: flex;
    margin-left: 1.8em;
}

.transfer-modal-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em;  
}

.transfer-modal-description{
    color:#060606 !important;
    font-family: Barlow, Noto sans !important;
    font-size: 0.75em;
    margin: 1em 2.5em 1em 2.5em; 
    letter-spacing: 0.13px !important;
    opacity: 1;
}

.modal-select-container{
    margin: 2em;
}

.location-select-input{
    width: 100% !important;
    margin-top: .5em;
    margin-bottom: 1em;
    font-weight: 500 !important;
}


.modal-close-icon-transfer{
    height: 0.75em !important;
    width: 0.75em !important;
    margin-top : 0.5em !important;
    margin-right : 0.75em !important; 
    cursor: pointer;
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

.selected-vehicles{
    word-break: break-all;
}
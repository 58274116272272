.removal-reason-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}
.graph-card{
    /* border-bottom: 1px solid #00000029; */
    padding: 2em;
}
#root .table-card{
    padding-top : 2em;
}


/* SMALL SCREEN VIEW */
@media only screen and (max-width: 950px) {
    .removal-reason-container{
        margin-top: 10em;
    }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
    .content-container{
        padding: 0 12.5%;
        width: 75%;
    }
   
}
.axle-image {
  opacity: 1;
  border-left: 6px solid #353a94;
  height: 100px;
  margin-left: 31px;
}
.display-inline-block {
  margin-top: -0.8em;
  padding: 0.8em;
  display: inline-block;
}
.display-inline {
  display: inline-block;
}
.tire-table {
  margin-top: 1em;
}
.tire-axle-number {
  margin-top: -0.5em;
  margin-bottom: -0.7em;
}
.remove-tire-empty-tire {
  width: 66px;
}

.worn-card-image {
  margin-left: 5%;
  margin-right: 5%;
}

.axle-0 {
  padding-left: 1.2em;
}
.axle-1 {
  padding-left: 1.7em;
}
.axle-2 {
  padding-left: 1.9em;
}
.axle-3 {
  padding-left: 2em;
}

.axle-4 {
  padding-left: 1.5em;
}
.axle-5 {
  padding-left: 1.9em;
}
.axle-6 {
  padding-left: 1.8em;
}
.axle-7 {
  padding-left: 1.7em;
}
.axle-8 {
  padding-left: 1.8em;
}

.remove-all-tire-form-selected-tire {
  opacity: 1;
}

.remove-all-tire-form-not-selected {
  opacity: 0.4;
}

.rotate-vehicle-diagram {
  margin-bottom: 1em;
  transform: rotate(90deg);
}

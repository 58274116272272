.action-tire-dropdown-content {
  position: absolute;
  background-color: white;
  width: 6em;
  z-index: 3;
  border-radius: 0.5em;
  border: 1px solid lightgray;
  margin-top: -20px;
  margin-left: -125px;
}

.action-tire-dropdown-content:hover {
  cursor: pointer;
}

.action-tire-dropdown-content .action-tire-option {
  color: #0055a4;
  padding: 0.5em;
  font-family: Barlows,'Noto Sans';
}
.tire-table-head-row {
  z-index: 2 !important;
  color: #2e2e2e !important;
  font-size: 12px !important;
  font-family: Barlow, Noto sans !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}
.sticky-col {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
}
.last-col {
  right: 0px;
  width: 100px;
  max-width: 100px;
  border-left: 1px solid #d1d1d1;
  min-width: 100px;
  /* box-shadow: 2px 0px #d1d1d1;
    -webkit-box-shadow: -1px 0px 0px 0px #d1d1d1; */
}
.last-row {
  box-shadow: 2px 0px #d1d1d1;
  -webkit-box-shadow: -1px 0px 0px 0px #d1d1d1;
}

.manage-tire-serial {
  color: #0055a4 !important;
  font-weight: 550 !important;
  cursor: pointer;
}

.manage-tire-serial:hover {
  font-weight: 700 !important;
}

.action-tire-option:hover {
  background-color: #b1d2ff;
}

.no-data-container {
    padding-top: 5%;
}
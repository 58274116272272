


.modal-previous-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.modal-next-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.previous-icon-container{
    width:20%;
    margin-left: 20%;
}

.graph-modal-title{
    width: 43%;
    color: #000;
}

.next-icon-container{
    margin-right: 19%;
}

.graph-title{
    margin-top: 1.3em;
}

.graph-close{
    margin-top: -12px!important;
}

.graph-modal-body{
    margin: 1.4em 1.4em 1.4em 1.4em;
}


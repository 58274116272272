.other-tires-main-container{
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 150%;
    position: absolute;
    width: 100%;
}

.other-tires-upper-deck{
    position: absolute;
    width: 100%;
    height: 30%;
    background: transparent linear-gradient(161deg, #0D54B5 0%, #003584 100%) 0% 0% no-repeat padding-box;
}
.background-img-container{
    margin-right: 1em;
}
.background-map-image{
    background-image: url("../../../../../assets/map.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: right; 
    height: 20em;
    width: 100%;
}

/* TIRES IN SERVICE HEADER (BREADCRUMBS & TITLE) */

.other-tires-header{
    padding: 2em;
    display: flex;
    justify-content: space-between
}
.other-tires-title{
    color: white;
    font-family: Barlow,light, Noto Sans;
    /* margin-top: 0.25em; */
    font-size: 1.25em;
}

/* TIRES IN SERVICE NAVIGATION  */

.other-tires-nav-container{
    display: flex;
    /* margin: 0 2.5%; */
    overflow-x: hidden;
}
.other-tires-nav-card-container{
    margin-right: 1em;
}
.other-tires-nav-card{
    background: rgba(0, 0, 0, 0.2) !important; 
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.5em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}

.other-tires-nav-card:hover{
    background: rgba(0, 0, 0, 0.4) !important; 
}

.active-other-tires-nav-card{
    vertical-align: super;
    background: transparent linear-gradient(288deg, #FFA700 0%, #FFDC00 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    height: 2.3em;
    border-radius: 0.5em !important;
    color: white !important;
    margin-right: 1em;
    /* border: 1px solid #FFBE00 !important; */
    cursor: pointer !important;
    white-space:nowrap !important; 
}
.other-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    font-family: Barlows,'Noto Sans';
    color:#FFFFFF;
}
.active-other-tires-nav-card-text{
    vertical-align: super;
    font-size:1em;
    color:#242424;
    font-family: Barlows,'Noto Sans';
}
.other-button-other-tires-nav-container{
    display: flex;
    margin: 0 2.5%;
    overflow-x: hidden;
}


.other-tires-button-navigation-style{
 
    border-radius: 50%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.9;
    width: 4.3em;
    margin-top:7px;
    height: 45px;
}

.other-tires-button-navigation-style:active{
    border-color: none;
}
.other-tires-button-navigation-style:focus{
    border-color: none;
    outline: none;
}

/* LARGE SCREENS  */
@media only screen  and (max-width: 1100px) and (min-width: 768px) {
    .other-tires-button-navigation-style{
        display: none;
    }
    .other-tires-nav-container{
        overflow-x: auto!important;
    }
}
@media only screen and (min-width: 1500px) {
    .other-tires-main-container{
        height: 110%;
    }
    .other-tires-nav-container{
        display: flex;
        /* margin: 0 7.5%; */
        overflow-x: hidden;
    }
    .other-button-other-tires-nav-container{
        display: flex;
        margin: 0 7.5%;
        overflow-x: hidden;
    }

    .other-tires-nav-card-text{
        font-size:1.2em;
    }
    .active-other-tires-nav-card-text{
        font-size:1.2em;
    }
    .other-tires-title{
        font-size: 2.1em;
    }
}

/* MID SCREENS  */
@media only screen and (max-width: 950px) {
    .other-tires-main-container{
        height: 150%;
    }
    .other-tires-nav-container{
        overflow-x: scroll;
    }
    .other-button-other-tires-nav-container{
        overflow-x: scroll;
    }
    .other-tires-nav-card-container{
        margin-right: 1em;
    }
   
    .other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
/* SMALL SCREENS  */
@media only screen and (max-width: 600px) {
    
    .other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
    .active-other-tires-nav-card{
        margin-right: 0;
        margin-bottom: 0.5em;
    }
}
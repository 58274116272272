/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */

.scrap-summary-container{
    /* position: absolute; */
    margin-top: 12.5em;
    padding: 0 2.5%;
    width: 95%;
    /* Footer fix */
    position: relative;
    display: inline-block;
    margin-bottom: 4em;
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
    .scrap-summary-container{
      margin-top: 10em
    }

}

/* LARGE SCREENS */
/* @media only screen and (min-width: 1500px) {
    .scrap-summary-container{
        padding: 0 7.5%;
        width: 85%;
    }
} */

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
.table-head-row {
    color: #2E2E2E !important;
    font-size: 13px !important;
    font-family: Barlow, Noto sans !important;
    font-weight: 500 !important;
    /* white-space: nowrap !important; */
    margin-top: 20px !important;
}

.table-content-text {
    color: #404040 !important;
    font-size: 12px !important;
    font-family: Barlow, Noto sans !important;
    position:unset;
    /* white-space: nowrap !important; */
}
.selected-table-content-text{
    color: white !important;
    font-family: Barlow, Noto sans !important;
}
.table-row-alternate {
    background: #FAFAFB !important
}

.table-row-alternate:hover {
    background: #E4ECF7 !important;
}

.table-bottom-line {
    width: max-content;
    height: 3px;
    background: #0055A4 !important;
}

.table-row:hover {
    background: #E4ECF7 !important;
}

.MuiSelect-select.MuiSelect-select {
    padding-right: 24px !important;
}

.no-match-found {
    text-align: center !important;
    font-family: Barlow, Noto sans !important;
    font-weight: 500 !important;
}

.MuiTableCell-stickyHeader {
    z-index: 0 !important;
}

.hide-column {
    display: none !important;
}

.total-avg-row {
    border-top: 1px solid #0055A4 !important;
    background-color: #E4ECF7 !important;
}

.total-avg-text {
    color: #222222 !important;
    font-family: Barlow, Noto sans !important;
    /* font-weight: bold !important; */
}

/* LARGE SCREENS */
@media only screen and (min-width: 1600px) {
    .table-head-row {
        font-size: 15px !important;
    }

    /* .table-content-text {
        font-size: 1.1em !important;
    } */
}

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}

.pressure-range-safe{
    background: #ccf2db !important; 
}
.pressure-range-light-warning{
    background: #ffffaf !important;
}
.pressure-range-mild-warning{
    background: #ffe0a9 !important;
}
.pressure-range-warning{
    background: #f3b04a4d !important;
}
.pressure-range-heavy-warning{
    background:  #ffc7c7 !important;
}
.pressure-range-danger{
    background: #ff9488 !important; 
}

.data-summary-table {
    margin-top: 2%;
}
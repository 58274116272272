/* #tire-history-table .MuiTableCell-head:first-child{
    background: yellow;
    position: fixed;
    padding: 7px 7px;
    padding-left: 0;
} */
.dropdown-content{
    z-index: 5!important;
}
.fixed-columns{
    z-index: 4!important;
}
.table-row:hover .fixed-column-body {
    background-color: #E4ECF7;
}
.fixed-column-body{
    top: 0;
    left: 0;
    z-index: 3!important;
    position: sticky !important;
    background-color: white;
}
.first-col {
    left: 0!important;
  }
.second-col{
    left: 6.5%!important;
}
.third-col{
   
    left: 12%!important;
}
.fourth-col{
    box-shadow: 2px 0px #d1d1d1;
    left: 16%!important;
}

.percent-worn-highest{
    background-color: #ffc7c7 !important;
}
.percent-worn-high{
    background-color: #ffe0a9 !important;
}
.percent-worn-mid{
    background-color: #ffffaf !important;
}
.percent-worn-low{
    background-color: #ccf2db !important;
}
/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.tire-inflation-container {
  /* position: absolute; */
  margin-top: 12.5em;
  padding: 0 2.5%;
  width: 95%;
  /* Footer fix */
  position: relative;
  display: inline-block;
  margin-bottom: 4em;
}

/* SELECT BOXES STYLING  */
.location-container {
  width: inherit;
}

.select-container {
  margin: 0.5em;
  /* display: inline-block;
    vertical-align: top; */
}
.align-right {
  float: right;
  min-width: 15%;
}
.select-input {
  width: 100% !important;
  padding: 1em;
}
.select-label {
  margin-bottom: 0.3em !important;
  font-size: 0.8em !important;
  color: #222222 !important;
}
.select-style {
  border: 1px solid lightgray !important;
  border-radius: 0.5em;
  padding: 0.2em;
  margin-top: 1.5em !important;
}

.custom-options-styling:hover {
  background: #77acff !important;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

#header-change-modal-multi-select .multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 3.5em !important; /* Height */
}

/* MID SCREENS */
@media only screen and (max-width: 950px) {
  .tire-inflation-container {
    margin-top: 10em;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  /* .tire-inflation-container {
    padding: 0 7.5%;
    width: 85%;
  } */

  .select-label {
    font-size: 1.25em !important;
  }
}

/* Text-Field CSS */
.text-container {
  margin: 2em;

  /* display: inline-block; */
}

.removed-tire-graph-container{
    display: flex;
    margin: 3em 0;
    cursor: pointer;
    margin-bottom: 6em;
}

.no-graph-removed-tire{
    margin: auto;
    width: 200px;
}
.graph-removed-tire-container{
    width: 225px;
    height: 229px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
    opacity: 1;
    cursor: pointer;
}

.removed-tire-graph-container .MuiGrid-grid-xs-3 {
    max-width: 20% !important;
}
.graph-container-height{
    height:100%!important
}
.heading-container{
    color: #194CAB;
    font-size: 1em; 
    font-weight: 500;
}

.heading-graph{
    padding-left: 10px !important;
}

.no-data-container {
    padding-top: 5%;
  }
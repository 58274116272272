.table-toolbar {
  display: flex;
  margin-bottom: 1em;
}
.table-filters {
  display: flex;
  width: 60%;
  margin-bottom: 10px;
}
.search-table-container {
  width: 15em;
}
.display-columns-container {
  width: 100%;
  max-width: 15em;
  margin-left: 0.5em;
}
.select-drop-down-container {
  width: 100%;
  max-width: 15em;
  margin-left: 1.25em;
}

.tires-select-container {
  width: 100%;
  max-width: 15em;
  margin-left: 2em;
}

.select-drop-down {
  width: -webkit-fill-available;
}

.filter-table-label {
  color: #000000 !important;
  font-family: Barlow, Noto sans !important;
  font-size: 1em !important;
  margin-bottom: 0.5em;
}

/* MULTI SELECT STYLING (add !important to reflect changes) */

.display-columns-style {
  width: 100% !important;
}

.multi-select-display-col {
  height: 0.9em !important;
  z-index: 4 !important;
}

.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #e2e6ea !important;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 2.2em !important; /* Height */
}


.filter-icon-img {
  height: 0.8em;
  margin-right: 0.2em;
}
.filter-applied-icon-img {
  height: 1em;
  margin-right: 0.2em;
}
.print-icon-img {
  height: 0.8em;
  margin-right: 0.2em;
}
.mail-icon-img {
  height: 0.7em;
  margin-right: 0.2em;
}
.keyword-search-icon-img {
  height: 1.1em;
}

.table-toolbar-label-text {
  font-size: 0.85em;
  font-family: Barlows,'Noto Sans';
  font-weight: 500;
}

.disabled-text {
  color: #c9c9c9;
}


.tire-scrap-type {
  max-width: 14.5em;
}

.exclude-used-tires {
  margin-top: 1.2em;
  margin-left: 1em;
}

@media only screen and (max-width: 1100px) {
  .display-columns-container {
    width: 100%;
    max-width: 10em;
    margin-left: 0.5em;
  }
  .select-drop-down-container {
    width: 100%;
    max-width: 100%;
    margin-left: 1.25em;
  }
  .tires-select-container {
    width: 100%;
    max-width: 10em;
    margin-left: 2em;
  }
}



/* MOBILE VIEW  */
@media only screen and (max-width: 600px) {
  .table-toolbar {
    display: block;
  }
  .table-filters {
    display: block;
    width: 100%;
  }
  .search-table-container {
    width: 100%;
  }
  .display-columns-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .select-drop-down-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .tires-select-container {
    width: 100%;
    max-width: 100%;
    margin: 1em 0;
  }
  .search-table-bar {
    width: 100% !important;
  }
  .table-title {
    margin-bottom: 1em;
    width: 100%;
  }
  .table-function-title {
    width: 100%;
    text-align: start;
    margin-top: 1em;
  }
  .table-function-item {
    margin: 0;
    padding-right: 1.5em;
  }
}

/* LARGE SCREENS */
@media only screen and (min-width: 1500px) {
  .table-toolbar-label-text {
    font-size: 1em;
  }
  .table-toolbar-text {
    font-size: 1.3em;
  }
  .print-icon-img {
    height: 1.1em;
    margin-right: 0.5em;
  }
  .mail-icon-img {
    height: 1em;
    margin-right: 0.5em;
  }
  .filter-icon-img {
    height: 1.1em;
    margin-right: 0.5em;
  }
  .filter-applied-icon-img {
    height: 1.2em;
    margin-right: 0.5em;
  }
}

.button-container{
    /* margin-left: 3em; */
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.text-container{
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.table-toolbar-dailyJob
{
    /* margin-bottom: 1.5em; */
    margin-top: 3em;
}

.dailyjob-second-row-dropdown{
    margin-top: 1em;
}

.dailyjob-exclude-used-tires{
    margin-top: 2em;
}


.delete-tire-title-container{
    display: flex;
    margin-left: 2em;
}

.delete-tire-title{
    font-size: 1.1em;
    color:#0055A4;
    font-family: Barlows,'Noto Sans';
    width: 100%;
    margin-top : 1.5em; 
}


.delete-modal-description{
    color:#545151;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1em 2.5em 3em 2.5em; 
}

.modal-action-buttons {
    text-align: right;
    margin-right: 2em;
    margin-bottom: 2em;
}

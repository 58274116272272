/* STYLES IMPORTED FROM COMMON STYLES : TABLE_FUNCTION_OPTIONS */
.exclude-used-tires-tire-forecast
{
    margin-bottom:1em;
    margin-top:1em
}

.display-columns-container-tire-forecast{
    width: 100%;
    max-width: 13em;
    margin-bottom: 1em;
}
.table-function-title-tire-forecast{
    width : 25%;
    text-align: end;
}

.table-filters-tire-forecast{
    display: flex;
    width : 75%;
}

.tire-forecast-update{
    margin-left:30%
}

.update-button{
    width: 89px;
    height: 36px;
    background: transparent linear-gradient(
90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: .8;
    font-size: 14px;
    font-family: Barlows,'Noto Sans';
    color: #ffffff;
    border: none;
    margin-top: 24px;
}
.update-button:hover {
    opacity: 1;
}
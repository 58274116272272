  .background-map-image-view-location {
    position: absolute !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: right !important;
    height: 100% !important;
    width: 100% !important;
  }

  .view-location-container{
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom: 4em;
  }

.edit-details-message {
    text-align: left;
    color: #b9b8b8;
    opacity: 1;
    font-weight: 400;
    font-family: Barlow, Noto Sans;
    font-size: 15px;
    letter-spacing: 0.14px;
    margin-bottom: 20px;
    font-style: italic;
}

.account-detail-grid{
    margin-bottom: 1em;
}

.field-title{
  text-align: left;
    color: #194CAB;
    font-weight: 500;
    font-family: Barlow;
    font-size: 16px;
    margin-bottom: 10px;
    opacity: 1;
}

.view-location-action-buttons {
    text-align: left;
    margin-top: 2em;
}

.view-location-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 90%;
  position: absolute;
  width: 100%;
}
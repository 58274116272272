.edit-history-modal-container {
    border-radius: 0.5em;
    width: 60%;
    background: white;
}


.tire-history-title-container {
    display: flex;
    margin-left: 2em;
}

.edit-history-static-modal-description {
    padding: 2em;
    border-radius: 0.5em;
    background: #FAF9F2;
    color: #545151;
    font-family: Barlows,'Noto Sans';
    font-size: 0.75em;
    margin: 1.5em 2.5em 0.5em 2.5em;
}

.item-detail-label-edit-history-modal {
    font: normal normal bold 1.1em/2.5em Barlows,'Noto Sans';
    margin-bottom: -0.3em;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.item-detail-content-edit-history-modal {
    font: normal normal normal 1.1em/2.5em Barlows,'Noto Sans';
    margin-top: -0.3em;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
}

.edit-history-inner-form-container{
    margin: 0 2.5em 0.5em 2.5em;
    padding: 1em;
}

.field-label-history-modal{
    font-weight: 500;
    text-align: left;
    color: #333333;
    font-family: Barlow;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 2px;
    opacity: 1;
}

.history-modal-action-buttons {
    display: inline-flex;
    justify-content: right;
    margin-left: 75%;
    margin-right: 2em;
    margin-bottom: 2em;
}

.edit-history-helper-message {
    margin-right: 2.5em;
    margin-top: 5px;
    text-align: right;
    font: normal normal normal 12px/20px Barlows,'Noto Sans';
    letter-spacing: -0.29px;
    color: #333333;
}
